import React, { useState } from 'react';

const EnquireForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    batch: '',
    feedback: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form validation here
    const errors = validateForm();
    setErrors(errors);

    // If there are no errors, handle form submission
    if (Object.keys(errors).length === 0) {
      console.log(formData); // Handle form data submission here (e.g., send data to the server)
    }
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.name.trim()) {
      errors.name = 'Name is required';
    }
    if (!formData.phone.trim()) {
      errors.phone = 'Phone number is required';
    }
    if (!formData.batch.trim()) {
      errors.batch = 'Batch is required';
    }
    if (!formData.feedback.trim()) {
      errors.feedback = 'Feedback/Complaint is required';
    }
    return errors;
  };

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <label htmlFor="name">Name:</label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
        {errors.name && <span>{errors.name}</span>}
      </div>

      <div>
        <label htmlFor="phone">Phone Number:</label>
        <input
          type="text"
          id="phone"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
        />
        {errors.phone && <span>{errors.phone}</span>}
      </div>

      <div>
        <label htmlFor="batch">Batch:</label>
        <input
          type="text"
          id="batch"
          name="batch"
          value={formData.batch}
          onChange={handleChange}
        />
        {errors.batch && <span>{errors.batch}</span>}
      </div>

      <div>
        <label htmlFor="feedback">Feedback/Complaint:</label>
        <textarea
          id="feedback"
          name="feedback"
          value={formData.feedback}
          onChange={handleChange}
          rows="4"
        />
        {errors.feedback && <span>{errors.feedback}</span>}
      </div>

      <button type="submit">Submit</button>
    </form>
  );
};

export default EnquireForm;
