import React, { useState } from "react";
import TrailBtn from "../TrailButton/TrailBtn";
import ParentReviewCard from "./ParentReviewCard/ParentReviewCard";
import "./ParentsReview.scss";

const ParentsReview = () => {
  const [reviewClass, setReviewClass] = useState("review_cards");
  const [dotClassOne, setDotClassOne] = useState("dot");
  const [dotClassTwo, setDotClassTwo] = useState("dot dot_active");
  const [dotClassThree, setDotClassThree] = useState("dot");
  const [dotClassFour, setDotClassFour] = useState("dot");
  const [swipeStartPos, setSwipeStartPos] = useState(0);
  var [slideCard, setSlideCard] = useState(2);

  const onSwipeStop = (e) => {
    var swipeStopPos = e.clientX || e.changedTouches[0].clientX;
    
    if (swipeStartPos > swipeStopPos) {
        if (slideCard !== 4) {
            slideCard++;
            // console.log(slideCard)
          setSlideCard(slideCard);
      }
      handleSlide(slideCard);

    } else if (swipeStartPos < swipeStopPos) {

        if (slideCard !== 1) {
          slideCard--;
        setSlideCard(slideCard);
      }
      handleSlide(slideCard);
    }
  };

  const handleSlide = (card) => {
    setReviewClass("review_cards");
    deActivateDots();
    switch (card) {
      case 1:
        setReviewClass("review_cards card_1");
        setDotClassOne("dot dot_active");
        break;
      case 2:
        setReviewClass("review_cards");
        setDotClassTwo("dot dot_active");
        break;
      case 3:
        setReviewClass("review_cards card_3");
        setDotClassThree("dot dot_active");
        break;
      case 4:
        setReviewClass("review_cards card_4");
        setDotClassFour("dot dot_active");
        break;
      default:
        setReviewClass("review_cards");
        break;
    }
    // slideCard = card
  };

  const deActivateDots = () => {
    setDotClassOne("dot");
    setDotClassThree("dot");
    setDotClassTwo("dot");
    setDotClassFour("dot");
  };


  return (
    <div
      className="parents_review"
      onTouchStart={(e) => setSwipeStartPos(e.touches[0].clientX)}
      onTouchEnd={(e) => onSwipeStop(e)}
      onMouseDown={e=>{e.preventDefault();setSwipeStartPos(e.clientX)}}
      onMouseUp={e=>onSwipeStop(e)}
    >
      <h3>Don't just take out word for it</h3>
      <div className="review_card_bg">
        <div className={reviewClass}>
          <ParentReviewCard
            profilePic="/images/parent profile/parent_1.png"
            profileName="Madhavan SR"
            childName="Advaith"
            childClass="12"
            review="“Revamp has brought in the type of professional coaching that’s required in this age of technology
                . Be it home tuitions, classroom based or online, everything was handled and coordinated very well. 
                Good research on course material for school and competitive exams were also shared proactively.”"
          />
          <ParentReviewCard
            profilePic="/images/parent profile/Vrinda.jpg"
            profileName="Vrinda"
            childName="Ananya"
            childClass="12"
            review="“Got to know from Revamp24 from the Internet.The trial class went well,  and we continued with the classes for my 11th grader.  She is really happy because she got a connection with the teacher as well as the subject.”"
          />
          <ParentReviewCard
            profilePic="/images/parent profile/Sree Charan.jpg"
            profileName="Sree Charan"
            childName="Student"
            student={true}
            childClass="12"
            review="“ Very helpful academic support for students who need help, and are falling behind in their classes. Expert teachers and advanced methods of teaching to secure best marks in exams”"
          />
          <ParentReviewCard
            profilePic="/images/parent profile/Varun Nair.png"
            profileName="Varun Nair"
            childName="Student"
            student={true}
            childClass="12"
            review="“I had started from a very basic level and the teacher quickly adapts to the level that meets yours, while at the same time demands your full focus. ”"
          />
        </div>
      </div>
      <div className="slide_dots">
        <div
          className={dotClassOne}
          onClick={() => {
            handleSlide(1);
          }}
        ></div>
        <div className={dotClassTwo} onClick={() => handleSlide(2)}></div>
        <div className={dotClassThree} onClick={() => handleSlide(3)}></div>
        <div className={dotClassFour} onClick={() => handleSlide(4)}></div>
      </div>
      <p className="review_text">
        Experience the Revamp24 Method for yourself in a FREE trial class.
      </p>
      <div className="review_trial-btn">
        <TrailBtn
          color="red-orange"
          type="btn_trail_why_revamp"
          link="book-a-free-trial"
          children="Book a free trail"
          btnID="Button_book_a_free_trail"
        />
      </div>
    </div>
  );
};

export default ParentsReview;
