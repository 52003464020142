import React from 'react'
import QuestionCard from './QuestionCard/QuestionCard'
import './FrequentQuestions.scss'

const FrequentQuestions = ({ bgBlur, widthParameter, marginParameter }) => {
    return (
        <div className={"frequent_questions " + marginParameter}>
            <h3>FAQs</h3>
            <div className="questions_container">
                <QuestionCard
                    widthParameter={widthParameter}
                    question="What classes does Revamp24 offer?"
                    answer="Revamp24 offers maths and science classes for students in class 8th -12th across all
                    boards(CBSE,ICSE,IGCSE,State Board etc)"
                />
                <QuestionCard
                    widthParameter={widthParameter}
                    question="How do Revamp24 classes work?"
                    isList={true}
                    answer={[
                        "Revamp24 offers live Interactive online classes with expert tutors for math and sciences for class 8-12th.",
                        "Revamp24 master teacher takes 2 or more live classes per week with the student.",
                        "Classes are conducted on Zoom Platform.",
                        "Class size is up to 1:6 (but 1:1 is also available for Premium Subscribers)",
                        "Master Tutor-student interaction is always 1:1 (even in group classes).",
                        "To join a class, the student needs a desktop / laptop with a headphone and mic, and broadband internet."    
                    ]}
                />
                <QuestionCard
                    widthParameter={widthParameter}
                    question="What is the frequency and duration of classes?"
                    isList={true}
                    answer={[
                        "We recommend two classes per week for each subject(Maths physics chemistry and biology).",
                        "Each class is 60-90 mins long and class size is up to 1:6 (but 1:1 is also available for Premium subscribers).",
                        "You CAN choose a class frequency and class timings that suit you."
                    ]}
                />
                <QuestionCard
                    widthParameter={widthParameter}
                    question="Are Revamp24’s programs applicable for all classes?"
                    answer="Revamp24’s learning programs are for students from 8th to Class 12. We also offer programs for
                    competitive exams like JEE, NEET, State entrance examinations,SAT etc"
                />
                <QuestionCard
                    widthParameter={widthParameter}
                    question="What languages are the learning programs available in?"
                    answer="The Revamp24’s programs are available only in English"
                />
                <QuestionCard
                    widthParameter={widthParameter}
                    question="Do you provide customer support?"
                    answer="Yes, we provide customer support for all our enrolled users. You can write to us at
                    support@revamp24.in or on 6366440333"
                />
            </div>
            {bgBlur ? null : <img src="/images/FAQs/circle.svg" className="blur-bg" alt=""></img>}
        </div>
    )
}

export default FrequentQuestions
