import React from "react";
import "./Expert.scss";
export default function Expert() {
  return (
    <div className="expert_container">
      <h1 className="perks">
      Advantages of being a Revamp24 Mentor
      </h1>
      <div className="expert_main">
        <div className="expert_content">
          <div className="img">
            <img
              className="expert_image"
              src="/images/social-care.png"
              alt=""
            />
          </div>
          <div className="expert_font_container">
            <h3 className="expert_title">Full-time support</h3>
            <p className="expert_para">
              End to end support from creating the lesson plans to its proper
              deliverance.
            </p>
          </div>
        </div>
        <div className="expert_content">
          <div className="img">
            <img className="expert_image" src="/images/clock.png" alt="" />
          </div>
          <div className="expert_font_container">
            <h3 className="expert_title">Adaptable Schedule</h3>
            <p className="expert_para">
              Work along your free and <br /> available hours.
            </p>
          </div>
        </div>
        <div className="expert_content">
          <div className="img">
            <img className="expert_image" src="/images/learning.png" alt="" />
          </div>
          <div className="expert_font_container">
            <h3 className="expert_title">Purposeful Career</h3>
            <p className="expert_para">
              Join us in the mission to ensure
              <br />
              no child is left behind in school education
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
