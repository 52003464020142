import React, { useState } from 'react'
import './TutorProfile.scss'
import TutorProfileCard from './TutorProfileCard/TutorProfileCard'
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'

function TutorProfile() {
    const [transtion,setTransition] = useState(null),
        [leftIcon,setLeftIcon] = useState(false),
        [rightIcon,setRightIcon] = useState(true),
        [swipeStart,setSwipeStart] = useState(0),
        [dotOne, setDotOne] = useState("dot-active"),
        [dotTwo, setDotTwo] = useState(),
        [dotThree, setDotThree] = useState(),
        [dotFour, setDotFour] = useState(),
        [grab,setGrab] = useState(null)

    const onSwipe = e=>{
        console.log(e)
        var swipeEndPos =   e.clientX || e.changedTouches[0].clientX;
        if(swipeStart > swipeEndPos){

            if(window.innerWidth < 801){

                if(transtion === 't-100'){
                    setTransition('t-200')
                    setDotTwo(null)
                    setDotThree('dot-active')
                } else if(transtion === 't-200'){
                    setTransition('t-300')
                    setRightIcon(false)
                    setDotThree(null)
                    setDotFour('dot-active')
                }else if(transtion==='t-300'){
                    console.clear();
                }else{
                    setTransition('t-100')
                    setLeftIcon(true)
                    setDotOne(null)
                    setDotTwo('dot-active')
                }
            }else{
                setTransition('t-100')
                setRightIcon(false)
                setLeftIcon(true)
            }
            
        }else if(swipeStart < swipeEndPos){
            

            if(window.innerWidth < 801){

                if(transtion === 't-200'){
                    setTransition('t-100');
                    setDotThree(null);
                    setDotTwo('dot-active');
                } else if(transtion === 't-300'){
                    setTransition('t-200');
                    setRightIcon(true);
                    setDotFour(null);
                    setDotThree('dot-active')
                }else{
                    setTransition(null)
                    setLeftIcon(false)
                    setDotTwo(null)
                    setDotOne('dot-active')
                }
            }else{
                setTransition(null)
                setRightIcon(true)
                setLeftIcon(false)
            }


        }
    }
        


    return (
        <div className="tutor_profile">
            <h2>Tutor Profiles</h2>
            <div className={"card-slide-container "+grab}
            onMouseDown={(e)=>{e.preventDefault();setSwipeStart(e.clientX);setGrab('grab')}} 
            onMouseUp={e=>{onSwipe(e);setGrab(null)}}
            onTouchStart={e=>setSwipeStart(e.touches[0].clientX)}
            onTouchEnd={e=>onSwipe(e)} >
                <div className={"tutor_card_bg "+transtion} id="tutor_card_bg">
                    <div className="cards" >
                        <div className="cards_for_mobile">
                            <TutorProfileCard
                                teacher={"Ramees PP"}
                                teacherGraduation="Phd from James Cook university B.Sc & M.Sc in Chemistry from IISER Bhopa"
                                teacherProfile="/images/Teachers profile/Ramees.png"
                                bg="l-blue"
                                special="ml-0"
                            />
                            <TutorProfileCard
                                teacher="Muhammed Yaseen c"
                                teacherGraduation="Dual degree Btech and Mtech from IIT Madras in Electrical engineering."
                                teacherProfile="/images/Teachers profile/yaseen.jpg"
                                bg="beach"
                            />
                        </div>
                        <div className="cards_for_mobile">
                            <TutorProfileCard
                                teacher="DR.Mufeed"
                                teacherGraduation="MBBS from AIIMS Delhi ,MD from AIIMS Jodhpur"
                                teacherProfile="/images/Teachers profile/mufeed.jpg"
                                bg="l-blue"
                            />
                            <TutorProfileCard
                                teacher="Luthfi"
                                teacherGraduation="B.Tech in EC From College of engineering Trivandrum"
                                teacherProfile="/images/Teachers profile/luthfi.png"
                                bg="beach"
                            />
                        </div>
                    </div>
                    <div className="cards">
                        <div className="cards_for_mobile">
                            <TutorProfileCard
                                teacher="Gibi mol Babu"
                                teacherGraduation="Ms from Cambrian college,Sadburry,Canada"
                                teacherProfile="/images/Teachers profile/Gibi mol.png"
                                bg="l-blue"
                            />
                            <TutorProfileCard
                                teacher="Hisham Sidhikk"
                                teacherGraduation="Secured All india Rank of  255 in GATE 2018(Mechanical)"
                                teacherProfile="/images/Teachers profile/hisham.jpg"
                                bg="beach"
                            />
                        </div>
                        <div className="cards_for_mobile">
                            <TutorProfileCard
                                teacher="Safal"
                                teacherGraduation="Btech from College of engineering trivandrum"
                                teacherProfile="/images/Teachers profile/safal.jpeg"
                                bg="l-blue"
                            />
                            <TutorProfileCard
                                teacher="Razi Haneef"
                                teacherGraduation="Btech from National institute of technology"
                                teacherProfile="/images/Teachers profile/Razi haneef.jpg"
                                bg="beach"
                            />
                        </div>
                    </div>
                </div>
                <div className="tutor-slide-dots">
                    <div className={"dot "+dotOne}></div>
                    <div className={"dot "+dotTwo}></div>
                    <div className={"dot "+dotThree}></div>
                    <div className={"dot "+dotFour}></div>
                </div>
            </div>
            {
                rightIcon
                &&
                <IoIosArrowForward className="right_arrow" onClick={()=>{
                    
                   if(window.innerWidth < 801){

                       if(transtion === 't-100'){
                           setTransition('t-200')
                       } else if(transtion === 't-200'){
                           setTransition('t-300')
                           setRightIcon(false)
                       }else{
                           setTransition('t-100')
                           setLeftIcon(true)
                       }
                   }else{
                       setTransition('t-100')
                       setRightIcon(false)
                       setLeftIcon(true)
                   }


                    }}/>
            }
            {
                leftIcon
                &&
                <IoIosArrowBack className="left_arrow" onClick={() =>{

                    if(window.innerWidth < 801){

                        if(transtion === 't-200'){
                            setTransition('t-100')
                        } else if(transtion === 't-300'){
                            setTransition('t-200')
                            setRightIcon(true)
                        }else{
                            setTransition(null)
                            setLeftIcon(false)
                        }
                    }else{
                        setTransition(null)
                        setRightIcon(true)
                        setLeftIcon(false)
                    }


                }}/>
            }
        </div>
    )
}

export default TutorProfile
