import React, { useEffect } from "react";
import "./BecomeTutor.scss";
import Expert from "./Expert/Expert";
import Skills from "./Skills/Skills";
import FrequentQuestions from '../FrequentQuestions/FrequentQuestions'
import Footer from '../Footer/Footer'
import TeacherSession from "./Slider/TeacherSlider";
import Navbar from "../Navbar/Navbar";
import BecomeTutorLanding from "./BecomeTutorLanding/BecomeTutorLanding";
import { GlanceCard } from "./LandingCard/GlanceCard";
import FiveStep from "./FiveStep/FiveStep";
import { Helmet } from "react-helmet";

export default function BecomeTutor() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className="become_tutor_container">
      <Helmet>
        <title>Career at Revamp24</title>
      </Helmet>
      <Navbar navImageVisible={true} loginRemove={true} trialBtnColor="blue-gradient" />
      <BecomeTutorLanding />
      <GlanceCard />
      <Expert />
      <TeacherSession />
      <FiveStep/>
        <Skills />
        <FrequentQuestions bgBlur={true} />
        <Footer />
    </div>
  );
}
