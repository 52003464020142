import React from 'react'
import './ParentReviewCard.scss'

const ParentReviewCard = ({review,profilePic,profileName,childClass,childName,student}) => {
    return (
        <div className="parent_review_card">
            <p>{review}</p>
            <div className="parent_profile">
                <img src={profilePic} alt=""/>
                <ul>
                    <li>{profileName}</li>
                    <li>{!student&&"Parent of"} {childName}</li>
                    <li>Class {childClass}</li>
                </ul>
            </div>
        </div>
    )
}

export default ParentReviewCard
