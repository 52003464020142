import React from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../../Assets/dataStore/dataStoreAction";
import "./SelectTime.scss";
export default function SelectTime(props) {
  const essentials = useSelector(state => state.essential)
  const times = ['twelve', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight']
  const dispatch = useDispatch();

  const handleTimeClick = (id, time ) => {
      if(essentials.hasOwnProperty('time')){
        if(essentials.time === time){
          dispatch(actions.deleteTime())
        }else{
          dispatch(actions.storeTime(time))
        }
      }else{
        dispatch(actions.storeTime(time))
      }
      
      for (var i = 0; i < 9; i++) {
        if (times[i] !== id) {
          document.getElementById(times[i]).classList.remove('time-active')
        } else {
          document.getElementById(times[i]).classList.toggle('time-active')
        }
      }
  }
  return (
    <div className="time_but_container">
      <button className={"time_button "+props.disable} id={props._id} onClick={() => handleTimeClick(props._id, props.time, props.disable)}>
        {props.time}{props.disable === "disable" && <span>Slot is full</span>}
        </button>
    </div>
  );
}
