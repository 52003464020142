import React from "react";
import "./FiveStep.scss";
import FiveStepCard from "./FiveStepCard/FiveStepCard";
// import { LazyLoadImage } from "react-lazy-load-image-component";
// import "react-lazy-load-image-component/src/effects/blur.css";

export default function FiveStep() {
  return (
    <div className="fivestep_container">
      <h1>Our Selection Process</h1>
      <FiveStepCard
        left="left"
        cardContent="We process your applications and set up a discovery call to shortlist
        potential candidates."
        cardHeading="Profile Shortlisting"
        leftImage="/images/become tutor/group(1).png"
        color="orange"
        cardNumber="01"
      />
      <FiveStepCard
        cardContent="After the call, the candidates have to submit a short video presentation
        as the next step. Our expert team will assess it for demonstration skills
        and teaching aptitude."
        cardHeading="Video Presentation"
        rightImage="/images/become tutor/video.png"
        color="violet"
        cardNumber="02"
      />
      <FiveStepCard
        left="left"
        cardContent="Candidates who satisfactorily complete the video assessment will
        attend a virtual interview with our team."
        cardHeading="Interview"
        leftImage="/images/become tutor/interview.png"
        color="red"
        cardNumber="03"
      />
      <FiveStepCard
        cardContent="Revamp24 provides exclusive training for the selected candidates and
        grand a certification."
        cardHeading="Training and Certification"
        rightImage="/images/become tutor/badge.png"
        color="sky-blue"
        cardNumber="04"
      />
      <FiveStepCard
        cardContent="Finally, the applicants present demo classes to real students putting the
        training into practice. 
        The final evaluation is based on the performance
        in the DEMO classes and the student feedback."
        cardHeading=" DEMO classes and evaluation"
        left="left"
        leftImage="/images/become tutor/teacher.png"
        color="yellow"
        cardNumber="05"
      />
      {/* <FiveStepCard
        title="Profile Shortlisting"
        contant="We process your applications and set up a discovery call to shortlist
        potential candidates."
        posClass="pos_right"
        image="url('/images/FiveStep/one.png')"
        color='rgba(255, 117, 74, 1)'
      />
      <FiveStepCard
        title="Video Presentation"
        contant="After the call, the candidates have to submit a short video presentation
        as the next step. Our expert team will assess it for demonstration skills
        and teaching aptitude."
        posClass="pos_left second-card"
        image="url('/images/FiveStep/two.png')"
        color='rgba(107, 63, 255, 1)'
      />
      <FiveStepCard
        title="Interview"
        contant="Candidates who satisfactorily complete the video assessment will
        attend a virtual interview with our team."
        posClass="pos_right"
        image="url('/images/FiveStep/three.png')"
        color='rgba(255, 80, 80, 1)'
      />
      <FiveStepCard
        title="Training and Certification"
        contant="Revamp24 provides exclusive training for the selected candidates and
        grand a certification."
        posClass="pos_left"
        image="url('/images/FiveStep/four.png')"
        color='rgba(126, 203, 238, 1)'
      />
      <FiveStepCard
        title=" DEMO classes and evaluation"
        contant="Finally, the applicants present demo classes to real students putting the
        training into practice. 
        The final evaluation is based on the performance
        in the DEMO classes and the student feedback."
        posClass="pos_right last-card"
        image="url('/images/FiveStep/five.png')"
        color='rgba(248, 179, 0, 1)'

      /> */}
    </div>
  );
}

// const FiveStepCard = ({ title, contant, posClass, image,color }) => (
//   <div className="steps_container" style={{ backgroundImage: image }}>
//     <div className={`five_step_content ${posClass}`}>
//       <h2 style={{ color: color }}>{title}</h2>
//       <p>{contant}</p>
//     </div>
//   </div>
// );
