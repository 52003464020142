import React, { useState } from 'react'
import { instance_url } from '../../../../Assets/config'
import './LeadForm.scss'


export default function LeadForm() {
  const [name, setName] = useState('')
  const [mobile, setMobile] = useState('')
  const [mobilecode, setMobilecode] = useState('')
  const [grade, setGrade] = useState('')
  const [laptop, setLaptop] = useState('')
  const [isSubmited, setIsSubmitted] = useState(false);
  const [verifiednumber,setverifiedNumber] = useState('')
  const bookTrial = async (event) => {
    event.preventDefault()
    const response = await fetch(`${instance_url}/trialclass`, {

      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        verifiednumber,
        grade,
        laptop
      })

    })
    console.log(response)
    const data = await response.json()
    console.log(data)
    setIsSubmitted(true);
  }

const mobileNumber = () =>{
  if(!mobilecode===''){
  const Final = mobilecode+mobile
  setverifiedNumber(Final)
  }else{
  const Final = '+91'+mobile
  setverifiedNumber(Final)
  }

}


  return (
    <div id="form-data" className="lead-form-container">
      {isSubmited ? (
        <div className="thankyou">
          <img src="/images/KCET/Landing/checked.png" alt="" />
          <h4>THANK YOU!</h4>
          <p>
            Your details have been submitted
            <br />
            successfully.
          </p>
        </div>

      ) : (

        <form onSubmit={bookTrial}>
          <h1>Book A Free Trial Class</h1>
          <input onChange={(e) => setName(e.target.value)} value={name} placeholder="Student Name" type="text" />
          <div className="mobile">
            <select className="country code" onChange={(e) => setMobilecode(e.target.value)} value={mobilecode} >
              <option >+91</option>
              <option >+965</option>
              <option >+971</option>
              <option >+974</option>
              <option >+966</option>
              <option >+968</option>
              <option >+973</option>
            </select>
            <input onChange={(e) => setMobile(e.target.value)}  value={mobile} placeholder="Mobile Number" type="tel" />
          </div>
          <input onChange={(e) => setGrade(e.target.value)} value={grade} placeholder="Current Class" type="tel" />
          <div className="laptop-main">
            <p>Do you Have Laptop ?</p>
            <div className="laptop">
              <input onChange={(e) => setLaptop(e.target.value)} type="radio" id="laptop" name="Laptop" required="" value="yes"></input>
              <label >Yes</label>
              <input onChange={(e) => setLaptop(e.target.value)} type="radio" id="laptop" name="Laptop" required="" value="No"></input>
              <label >No</label>
            </div>
          </div>
          <button onClick={mobileNumber} type="submit">Book A Free Trial Class</button>
        </form>
      )}
    </div>
  )
}
