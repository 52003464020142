import React from 'react'
import './BestCard.scss'
export default function BestCard({cardImage,cardTitle}) {
    return (
      <div className="bestcard_container">
          <div className="bestcard_main">
          <img src={cardImage} alt="" />
          <p>{cardTitle}</p>
          </div>
      </div>
    )
}
