import React from 'react'
import './Card.scss'
import { FaQuoteLeft } from 'react-icons/fa'

const TeacherCard = ({
    teacherProfile,
    description,
    teacherName,
    teacherGraduation,
    bg
    }) => {
    return (
        <div className={"teacher_tutor_card "+bg}>
            <img src={teacherProfile} alt=""/>
            <FaQuoteLeft className="quotes"/>
            <div className="teacher_description">{description}</div>
            <ul className="teacher">
                <li>{teacherName}</li>
                <li>{teacherGraduation}</li>
            </ul>
        </div>
    )
}

export default TeacherCard
