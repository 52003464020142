import React from 'react'
import './OnlineSubject.scss'
import Subjects from './Subjects/Subjects'
export default function OnlineSubject() {
    return (
       <div className="online_subject_container">
           <h1>Find Online Tutor In Any Subject</h1>
           <div className="online_subject_main">
            <Subjects
            subjectImage="/images/maths.svg"
            subjectName="MATHEMATICS"
            />
             <Subjects
            subjectImage="/images/physics.svg"
            subjectName="PHYSICS"
            />
             <Subjects
            subjectImage="/images/chemistry.svg"
            subjectName="CHEMISTRY"
            />
             <Subjects
            subjectImage="/images/biology.svg"
            subjectName="BIOLOGY"
            />
           </div>
           <div className="online_subject_main">
            <Subjects
            subjectImage="/images/HUMANITIES.svg"
            subjectName="HUMANITIES"
            />
             <Subjects
            subjectImage="/images/bussines.svg"
            subjectName="BUSSINESS"
            />
             <Subjects
            subjectImage="/images/accounting.svg"
            subjectName="ACCOUNTS"
            />
           </div>
       </div>
    )
}
