import React from 'react'
import './HowRevampCard.scss'
export default function HowRevampCard({heading,headImage,contents,bullet}) {
    return (
        <div className="howrevamp-card">
            <div className="image-section">
                <h6>{heading}</h6>
                <img className="head-image" src={headImage} alt="" />
            </div>
            <ul className="card-content">
                {
                    contents.map((content, key) => (
                        <li key={key}>
                            <span>
                                <img className="bullet" src={bullet} alt="" />
                            </span>
                            {content}
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}
