import React from 'react'
import './OnlineLanding.scss'
export default function OnlineLanding() {
    return (
        <div className="online_container">
        <div className="online_main">
            <div className="heading">
            <h1 >ONLINE CLASSES</h1>
        <h3>Keep your child's studies on track with interactive<br/>online tuition</h3>
        <p>Finding expert online help doesn’t have to be difficult.Just answer a few questions about what
you’re looking for and when you’re available,you'll see a list of professional online tutors ready to
help — some of whom can be booked the same day! Let us find you the help you need,when you need</p>
            </div>
            <img src="/images/onlinelanding.png" alt=""/>
           
        </div>
        </div>
    )
}
