import React from "react";
import PreparationCard from "./PreparationCard/PreparationCard";
import "./Preparation.scss";
export default function preperation() {
  return (
    <div className="preparation_container" id="classroom-program">
      <h1>
        START YOUR KCET 2023 OR 2024 PREPARATION WITH <br /> REVAMP24 CLASSES
      </h1>
      <div className="preparation_main">
        <PreparationCard
          cardTitle="BEST ONLINE CLASSROOM PROGRAM"
          cardImage="/images/KCET/Preparation/violetkcet.png"
          Title="Regular online classes by India's leading KCET trainers"
          Title2="Indepth discussion of Daily Practice Problems (DPPs) & Practice Sheets"
          Title3="All India Tests to benchmark yourself against other students"
          prepareParagraph="Online Classes will be conducted 3-5 times a week by India's Best KCET Trainers. This will help you understand every concept thoroughly and ensure that all your doubts are cleared."
          prepareParagraph2="The well-designed and structured DPP will help you revise the concepts every week for all the topics. Practice Sheet discussion ensures that you learn to apply the concepts taught in the class."
          prepareParagraph3="Online Classes will be conducted 3-5 times a week by India's Best KCET Trainers. This will help you understand every concept thoroughly and ensure that all your doubts are cleared."
          curveImage="/images/KCET/Preparation/violet1.png"
          straightImage="/images/KCET/Preparation/png-feedback.png"
          downImage="/images/KCET/Preparation/exam.png"
          titleCard="card-title"
        />
        <PreparationCard
          cardTitle="BEST ONLINE CLASSROOM PROGRAM"
          cardImage="/images/KCET/Preparation/orangemain.png"
          Title="Extra personalised classes by expert faculty to work on your weaker topics"
          Title2="High quality elaborate video lessons to revise anytime, anywhere"
          Title3="Quick doubt resolution"
          prepareParagraph="Personalised classes will be conducted 2-3 times a week to help you spend more time on topics that need focus. This increases your chances of you cracking the KCET exam."
          prepareParagraph2="Every concept is explained visually through engaging and personalised video lessons. This helps you prepare for the classes and revise after the classes. These video lessons can be watched multiple times until you have a complete understanding of the concept."
          prepareParagraph3="Convenient and instant doubt resolution by expert faculty during and after class."
          curveImage="/images/KCET/Preparation/teach.png"
          straightImage="/images/KCET/Preparation/feedback.png"
          downImage="/images/KCET/Preparation/orangeexam.png"
          titleCard="card-title2"
        />
      </div>
    </div>
  );
}
