import React from 'react'
import { useHistory } from 'react-router-dom';
import BlogCard from '../../Blog card/BlogCard';
import './BlogCategory.scss';

export default function BlogCategory({item}) {
    const history = useHistory()
    const handleViewAllBtn = () => {
        history.push(`/blog/category/${item._id}`)
    }
    return (
        <div className="blog-category">
            <h3 className="category-heading">{item._id}</h3>
            <div className="blog-cards-wrapper">
                {
                    item.data &&
                    item.data.map((blog) => (
                        <BlogCard 
                            key={blog._id}
                            blogData={blog}    
                        />
                    ))
                }
            </div>
            <button className="btn-view-all" onClick={handleViewAllBtn}>View All</button>
        </div>
    )
}
