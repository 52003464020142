import React from "react";
import "./BestTuition.scss";
import TuitionCards from "./TuitionCards/TuitionCards";
export default function BestTuition() {
  return (
    <div className="best_tuition_container">
      <h1 className="center_head_second">Why Revamp24 Centers Are The Best Tuition Centers?</h1>
      <div className="best_tuition_main">
        <TuitionCards
          cardHeader="We are everywhere"
          cardIcon="/images/location.png"
          cardContent="In a metropolitan city like Bangalore, the location is everything. We have centres
           across the city which enables our students to easily reach our centres from anywhere around the city."
        />

        <TuitionCards
          cardHeader="We Operate In Small Groups"
          cardIcon="/images/search.png"
          cardContent="One of the biggest drawback of any tuition centre is the large size of their classes. It
           interferes with the individual attention to each student. But, unlike the usual centres,
           we operate as small group classes.Most of our classes have no more than five students ensuring one on one attention in the class."
        />

        <TuitionCards
          cardHeader="We customize learning experiences for each student"
          cardIcon="/images/network.png"
          cardContent="In a metropolitan city like Bangalore, the location is everything. We have centres
           across the city which enables our students to easily reach our centres from anywhere around the city."
        />
      </div>
    </div>
  );
}
