import React from "react";
import { Link } from "react-router-dom";
import "./AttachedBookingButton.scss";

export default function AttachedBookingButton({isAd}) {
    const handleEnrollBtnClick = () => {
        window.open("https://rzp.io/l/JEK3YkTA5")
    }
    return (
        <div className={isAd?"attached-button-container attached-btn-ad":"attached-button-container"} style={{backgroundImage: isAd ? `url("/images/Banner Ads/sticky-ad.jpeg")`:"none"}}>
            {isAd ? (
                <div className="ad-container" >
                    <button className="enroll-btn" onClick={handleEnrollBtnClick}>Register Now</button>
                </div>
            ) : (
                <>
                    <p>
                        Pick your preffered
                        <br />
                        day & time:
                    </p>
                    <Link to="/book-a-free-trial">Book a free trial</Link>
                </>
            )}
        </div>
    );
}
