import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import BtnSelectClass from './BtnSelectClass'
import './SelectClass.scss'

export default function SelectClass() {
    const { id } = useParams()
    const [ btnChildren,setBtnChildren ] = useState([])

    useEffect(()=>{
        if(id === "class"){
            setBtnChildren([
                "Class 8",
                "Class 9",
                "Class 10",
                "Class 11",
                "Class 12"
            ])
        }else{
            setBtnChildren([
                "JEE",
                'NEET',
                "COMEDK",
                "KCET",
                "PESSAT"
            ])
        }
    },[id])
    return (
        <div className="select-class">
            <h4>Choose a class below</h4>
            <div className="btns">
                {
                    btnChildren.map((children,index)=>(
                        <BtnSelectClass children={children} key={index} type={id}/>
                    ))
                }
            </div>
            <Link to="/" >Go back to home</Link>
        </div>
    )
}
