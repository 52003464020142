import React, { useEffect } from 'react'
import Footer from '../Footer/Footer'

export default function Disclaimer() {
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])
    return (
        <div className="info_links_contents">
            <div className="info_content_container">
                <div className="logo">
                    <img src="/icons/logo.png" className="logo_image" alt="" />
                </div>
                <p>
                    ‘Revamp24 edu pvt ltd’ expressly disclaims warranties of any kind for any use of or any access to
                    www.revamp24.in, to any material, information, links, or content presented on the web pages at
                    www.revamp24.in, to any external website linked thereto, and to any external material, information,
                    links, or content linked thereto. Revamp24.in, and any material, information, links, and content
                    presented on the web pages at www.revamp24.in, as well as any external website and any external
                    material, information, links, and content linked thereto, are provided on an “as is” basis, without
                    warranty of any kind, either express or implied, including, without limitation, the implied warranties of
                    merchantability or fitness for a particular purpose, or non-infringement. ‘Revamp24 edu pvt ltd’ has
                    no control over any external website or over any external material, information, links, and content
                    linked to www.revamp24.in. Certain jurisdictions do not permit the exclusion of implied warranties
                    and the foregoing exclusions of implied warranties may not apply to you. Revamp24.in and its
                    internal web pages may be unavailable for online access from time to time and at anytime; there are
                    no guarantees and no warranties of online availability, impressions, and click-throughs. The entire
                    risk as to the performance of, or non-performance of, or arising out of the use of, or the access of, or
                    the lack of access to www.revamp24.in, to any material, information, links, or content presented on
                    the web pages at www.revamp24.in, to any external website linked thereto, or to any external
                    material, information, links, or content linked thereto, is borne by the user, visitor, customer, or other
                    person.
            </p>
                <h4>DISCLAIMER OF ONLINE AVAILABILITY, IMPRESSIONS, AND CLICK-THROUGHS:</h4>
                <p>
                    In addition to the other disclaimers and limitations discussed in this notice, there are no
                    guarantees and no warranties regarding online availability, impressions, and
                    click-through of www.revamp24.in, its web pages, and any material, information, links,
                    or content presented on the web pages at www.revamp 24.in. Revamp24.in, its web
                    pages, and any material, information, links, or content presented on the web pages at
                    www.revamp 24.in, may be unavailable for online access at any time. Advertisingsponsors and advertising must be approved by ‘Revamp24 edu pvt ltd’ before the
                    posting of any advertising material, information, links, content, banners, and graphics on
                    www.revamp 24.in. Any advertising should be related to interactive digital television and
                    related subject areas. ‘Revamp24 edu pvt ltd’ reserves the right to accept or to reject
                    any advertising sponsor or any advertising for any reason.
            </p>
                <h4>LIMITATION OF LIABILITY:</h4>
                <p>
                    In no event and under no circumstances and under no legal theory, tort, contract, or
                    otherwise shall ‘Revamp24 edu pvt ltd’ be liable, without limitation, for any damages
                    whatsoever, including direct, indirect, incidental, consequential or punitive damages,
                    arising out of any access to or any use of or any inability to access or use this website
                    including any material, information, links, and content accessed through this website or
                    through any linked external website.
            </p>
                <h4>LOCAL LAWS</h4>
                <p>
                    ‘Revamp24 edu pvt ltd’ controls and operates this Website from its headquarters in
                    Mumbai, Maharashtra, India and makes no representation that the materials on the
                    website are appropriate or available for use in other locations. If you use this Website
                    from other locations, you are responsible for compliance with applicable local laws
                    including but not limited to the export and import regulations of other countries. Unless
                    otherwise explicitly stated, all marketing or promotional materials found on this Website
                    are solely directed to individuals, companies or other entities located in India and
                    comply with the laws prevailing for the time being in force in India. Disputes if any shall
                    be subject to the exclusive jurisdiction of Courts at Delhi.
            </p>
                <h4>GENERAL</h4>
                <ul>
                    <li>
                        This Website could include unintended inaccuracies or typographical errors. ‘Revamp24 edu
                        pvt ltd’ and the Third Party Providers may make improvements and/or changes in the
                        products, services, programs, and prices described in this Website at any time without
                        notice. Changes are periodically made to the Website.
                </li>
                    <li>
                        The material, information, links, and content presented on and by this website are of a
                        general nature only and are not intended to address the specific circumstances,
                        requirements, or any other needs of any particular individual or entity. It cannot be
                        guaranteed that the material, information, links, and content presented on and by this
                        website is comprehensive, complete, accurate, sufficient, timely, or up to date for any
                        particular purpose or use. The material, information, links, and content presented on and by
                        this website should not be considered as professional, legal, business, financial, investment,
                        or purchasing advice (if you need specific advice, you should always consult a suitably
                        qualified professional). This website is sometimes linked to external websites over which
                        ‘Revamp24 edu pvt ltd’ has no control and assumes no responsibility, and are in no way
                        acting as a publisher of material, information, links, and content contained on external linked
                        websites. Links may become invalid, may expire, or may become misdirected at any time.
                        Links are provided as a convenience and do not necessarily constitute, signify, or otherwise
                        imply an endorsement by, or an endorsement for, or a relationship with, or connection to
                        ‘Revamp24 edu pvt ltd’. The statements expressed on external linked websites are not those
                        of ‘Revamp24 edu pvt ltd’; and users are advised that ‘Revamp24 edu pvt ltd’ neither
                        maintains editorial control over externally linked websites nor determines the
                        appropriateness regarding the material, information, links, and content contained on external
                        linked websites. ‘Revamp24 edu pvt ltd’ has no control over any external website or over any
                        external material, information, links, and content linked to www.revamp 24.in.
                </li>
                    <li>
                        The subjects and their coverage, tests and their results as also various tutorials are to be
                        used to assess one’s knowledge of concepts and as test papers and should not be
                        interpreted as a definitive score of ones ability. The tests and exercises are created for you to
                        test your understanding of concepts and are not to be interpreted as a sample question
                        paper for any examinations.
                </li>
                    <li>
                        The Website, and your use of the Website will be governed in all respects by the laws of
                        India.
                </li>
                    <li>
                        These Terms represent the entire understanding relating to the use of the Website and
                        prevail over any prior or contemporaneous, conflicting, additional, or other communications.
                        ‘Revamp24 edu pvt ltd’ can modify these Terms at any time without notice or consent by
                        updating this posting.
                </li>
                    <li>
                        In the event any or a portion of the provisions of these Terms are held invalid, illegal or
                        otherwise unenforceable by a Court, such provision shall be deemed amended to be valid,
                        legal and enforceable to the fullest extent permitted under applicable law, and the remaining
                        provisions of these Terms shall remain in full force and effect. These Terms are binding upon
                        you and your heirs, representatives, successors and assigns. The headings of the several
                        articles and subdivisions of these Terms are inserted solely for the convenience of reference
                        and shall have no further meaning, force or effect. No third party shall be a beneficiary of any
                        provision of these Terms, except with the express written consent of ‘Revamp24 edu pvt ltd’..
                </li>
                </ul>
                <h4>LICENSE DISCLAIMER</h4>
                <p>
                    Nothing on any Revamp24 edu pvt ltd website shall be construed as conferring any
                    license under any of Revamp24 edu pvt ltds or any third party’s intellectual property
                    rights, whether by estoppel, implication, or otherwise.
                </p>
                <h4>TUTOR DISCLAIMER</h4>
                <p>
                    Revamp24 edu pvt ltd shall not be responsible for any misbehavior, misconduct,
                    harassment (sexual or otherwise) or any other untoward incident committed by the
                    tutors listed onwww.revamp24.in
                </p>
                <h4>CONTENT AND LIABILITY DISCLAIMER</h4>
                <p>
                    Revamp24 edu pvt ltd shall not be responsible for any errors or omissions contained on
                    any Revamp24 edu pvt ltd website, and reserves the right to make changes anytime
                    without notice. Mention of non-Revamp24 edu pvt ltd products or services is provided
                    for informational purposes only and constitutes neither an endorsement nor a
                    recommendation by Revamp24 edu pvt ltd All Revamp24 edu pvt ltd and third-party
                    information provided on any Revamp24 edu pvt ltd website is provided on an “as is”
                    basis.
                    <br />
                    <br />
                    Views expressed by the users are their own, Revamp24 edu pvt ltd does not endorse
                    the same. No claim as to the accuracy and correctness of the information on the site is
                    made although every attempt is made to ensure that the content is not misleading. In
                    case any inaccuracy is or otherwise improper content is sighted on the website, please
                    report it to report abuse
                    <br />
                    <br />
                    ‘Revamp24 edu pvt ltd ‘ DISCLAIMS ALL WARRANTIES, EXPRESSED OR IMPLIED,
                    WITH REGARDS TO ANY INFORMATION (INCLUDING ANY SOFTWARE,
                    PRODUCTS, OR SERVICES) PROVIDED ON ANY ‘Revamp24 edu pvt ltd’ WEBSITE,
                    INCLUDING THE IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS
                    FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.
                </p>
                <h4>PURCHASING AND ORDERING DISCLAIMER</h4>
                <p>
                    Make your own decisions: If you are making important purchasing or planning
                    decisions, whether personal or business decisions, you should look at an actual
                    demonstration model of any product you are considering before making your important
                    purchasing or planning decisions. (Contact a supplier, store, or manufacturer regarding
                    looking at any demonstration units.) All decisions made would be entirely your
                    prerogative and Revamp24 edu pvt ltd does not claim to offer any advice, either legal or
                    financial.
                    <br />
                    <br />
                    In purchasing a product you understand and agree to the terms below. If you do not
                    understand and agree with these terms then do not place an order for a product.
                </p>
                <h4>PRICING</h4>
                <p>
                    All prices are subject to change without notice. Every effort has been made to ensure
                    accurate pricing of the products featured on our website. In the event a part or
                    accessory is ordered and the listed price has changed, you will be notified prior to our
                    processing your order. Purchase “as is.” you agree that all products purchased by you
                    through this website are provided under warranties, if any, of the manufacturer only, and
                    not by Revamp24 edu pvt ltd all products are provided by Revamp24 edu pvt ltd on “as
                    is” basis with no representations or warranties of any kind from Revamp24 edu pvt ltd
                </p>
                <h4>YOUR RESPONSIBILITY</h4>
                <p>
                    You have sole responsibility for use of the products/services you purchase through this
                    website. In no event shall Revamp24.in be liable to you in relation to the
                    products/services, or your use, misuse or inability to use the products, for any indirect,
                    punitive, special, exemplary, incidental, or consequential damage (including loss ofbusiness, revenue, profits, use, data or other economic advantage); or direct damages
                    in excess of the amount you paid Revamp24.in for the applicable product.
                </p>
                <h4>YOUR REPRESENTATIONS</h4>
                <p>
                    You represent that you are you are not under any legal or other disability which limits
                    your ability to comply with these Terms or to install and use the products you purchase
                    with minimal risk of harm to you or others. You further represent that you are not
                    purchasing the products/services for resale to others and will not do so without
                    Revamp24 edu pvt ltd’s prior written consent.
                </p>
                <h4>USE OF WEBSITE</h4>
                <p>You represent, warrant and covenant that your use of the Website shall not:</p>
                <ul>
                    <li>
                        violate any applicable local, provincial, state, national or international law, statute, ordinance,
                        rule or regulation;
                    </li>
                    <li>
                        interfere with or disrupt computer networks connected to the Website;
                    </li>
                    <li>
                        impersonate any other person or entity, or make any misrepresentation as to your
                        employment by or affiliation with any other person or entity;
                    </li>
                    <li>
                        forge headers or in any manner manipulate identifiers in order to disguise the origin of any
                        user information;
                    </li>
                    <li>
                        upload, post, transmit, publish, or distribute any material or information for which you do not
                        have all necessary rights and licenses;
                    </li>
                    <li>
                        upload, post, transmit, publish or distribute any material which infringes, violates, breaches
                        or otherwise contravenes the rights of any third party, including any copyright, trademark,
                        patent, rights of privacy or publicity or any other proprietary right;
                    </li>
                    <li>
                        interfere with or disrupt the use of the Website by any other user, nor “stalk”, threaten, or in
                        any manner harass another user;
                    </li>
                    <li>
                        upload, post, transmit, publish, or distribute any material or information which contains a
                        computer virus, or other code, files or programs intending in any manner to disrupt or
                        interfere with the functioning of the Website, or that of other computer systems;
                    </li>
                    <li>
                        use the Website in such a manner as to gain unauthorized entry or access to the computer
                        systems of others;
                    </li>
                    <li>
                        upload, post, transmit, publish or distribute any material or information which constitutes or
                        encourages conduct that would constitute a criminal offence, give rise to other liability, or
                        otherwise violate applicable law;
                    </li>
                    <li>
                        upload, post, transmit, publish, or distribute any material or information that is unlawful, or
                        which may potentially be perceived as being harmful, threatening, abusive, harassing,
                        defamatory, libellous, vulgar, obscene, or racially, ethnically, or otherwise objectionable;
                    </li>
                    <li>
                        reproduce, copy, modify, sell, store, distribute or otherwise exploit for any commercial
                        purposes the Website, or any component thereof (including, but not limited to any materials
                        or information accessible through the Website);
                    </li>
                    <li>
                        use any device, software or routine to interfere or attempt to interfere with the proper working
                        of the Website;
                    </li>
                    <li>
                        take any action that imposes an unreasonable or disproportionately large load on the
                        Website infrastructure;
                    </li>
                    <li>
                        any interpretation of test results or study material other than as a tool of learning and
                        enhancing knowledge;
                    </li>
                    <li>
                        or the tests and exercises are created for you to test your understanding of concepts and are
                        not to be interpreted as a sample question paper for any examinations.
                    </li>
                </ul>
                <p>
                    Use of this Website is at your own risk, and Revamp24 edu pvt ltd will not be held liable
                    for any errors or omissions contained in this Website. In no event, shall Revamp24 edu
                    pvt ltd be liable for any special, indirect or consequential damages, or any damages
                    whatsoever resulting from loss of use, data or profits whether contract, negligence orany tort action arising out of, or in connection with, the use or performance of the
                    information available from www.revamp 24.in.
                </p>
            </div>
            <Footer/>
        </div>
    )
}
