import React from 'react'
import { Link } from 'react-router-dom'
import './Maintanance.scss'
import {FaFacebookF} from 'react-icons/fa';
import {AiFillInstagram,AiFillLinkedin,AiFillYoutube,AiOutlineTwitter} from 'react-icons/ai'
import {FaWhatsapp} from 'react-icons/fa'

function Maintanance() {
    return (
        <div className="maintanance">
            <h4>COMING SOON</h4>
            <img src="images/Maintance/web-maintenance.png" alt=""/>
            <p>This page is under maintenance , but we are ready to go! want to know more about us, tell us about your learning requirement or just say hello? <a href="https://wa.me/message/6VRFIA5SXXRAE1">Click here<FaWhatsapp className="whatsapp-icon"/></a> chat with us on WhatsApp.</p>
            <p><Link to="/">Go to Home</Link></p>
            <ul className="footer_social_icons">
                    <li>
                        <a href="https://www.facebook.com/revamp24privatetuitions/"><FaFacebookF className="social_icon"/></a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/revamp24edu/"><AiFillInstagram className="social_icon"/></a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/checkpoint/challengesV2/AQGxdlRyLktBdQAAAXrj0D6djQr2AWwkyMgvwV96fDDwo6eDcKyim0-xM7YTv28ACUHleZBpdcEIgtc6bWrEnPZti5gyza91iw?original_referer=https%3A%2F%2Fwww.revamp24.in%2F"><AiFillLinkedin className="social_icon"/></a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/channel/UCQb9wfwTSgOVorVFSHZDEQg"><AiFillYoutube className="social_icon"/></a>
                    </li>
                    <li>
                        <a href="https://twitter.com/revamp24edu"><AiOutlineTwitter className="social_icon"/></a>
                    </li>
                </ul>
        </div>
    )
}

export default Maintanance
