import React from 'react'
import { EssentialProvider } from '../../Assets/EssentialContext'
import Navbar from './Navbar/Navbar'
import Landing from './LandingPage/Landing'
import Preparation from './Preparation/Preparation'
import More from './More/More'
import LearnAndPractice from './Learn and practice/LearnAndPractice'
import VideoSection from './VideoSection/VideoSection'
import ResultSection from './ResultSection/ResultSection'
import EnquireSection from './Enquire section/EnquireSection'
import Footer from './footer/Footer'
import { BrowserRouter as Router } from 'react-router-dom'
import './KcetPage.scss'
import { Helmet } from 'react-helmet'

export default function KcetPage() {
    return (
        <EssentialProvider>
            <Helmet>
                <title>Revamp24 | KCET 2023</title>
            </Helmet>
            <Router>
                <Navbar/>
                <Landing />
                <Preparation />
                <More />
                <LearnAndPractice />
                <VideoSection />
                <ResultSection />
                <EnquireSection />
                <Footer />
            </Router>
        </EssentialProvider>
    )
}
