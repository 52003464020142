import React from "react";
import "./Features.scss";
export default function Features() {
  return (
    <div className="features_container">
      <div className="features_main">
        <div className="features_content">
          <img className="features_image" src="/images/know.png" alt="" />
          <p className="features_para">Math and Science <br/> classes for 8-12</p>
        </div>

        <div className="features_content">
          <img className="features_image" src="/images/read1.png" alt="" />
          <p className="features_para">8000+ students <br/> in 10+ countries</p>
        </div>
        <div className="features_content">
          <img className="features_image" src="/images/location1.png" alt="" />
          <p className="features_para">Headquartered in <br/>Bangalore, India</p>
        </div>
      </div>
    </div>
  );
}
