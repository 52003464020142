import React, { useState, useEffect } from "react";
import "./AdmissionForm.scss";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { instance_url } from "../../../Assets/config";

const AdmissionForm = () => {
  const [stateData, setStateData] = useState([]);
  const [details, setDetails] = useState({ subjectsArray: [] });
  const [err, setErr] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const country = async () => {
    const response = await fetch(
      "https://countriesnow.space/api/v0.1/countries/states"
    );
    const data = await response.json();
    return data;
  };
  const state = async () => {
    const filteredState = countries.filter((data, i) => {
      return data.name === selectedCountry;
    })[0]?.states;
    console.log(filteredState);
    setStateData(filteredState);
  };
  useEffect(() => {
    state();
  }, [selectedCountry]);
  useEffect(() => {
    country().then((data) => {
      setCountries(data.data);
    });
  }, []);
  console.log(selectedCountry);

  const classes = [
    "LKG",
    "UKG",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
  ];
  const time = ["morning", "daytime", "evening", "weekends"];
  const subjects = [
    "Chemistry",
    "Physics",
    "Mathematics",
    "Biology",
    "German",
    "Economics",
    "Accounts",
    "Bussiness Studies",
    "Arabic",
    "French",
    "Hindi",
    "Computer science",
    "Malayalam",
    "English",
    "social studies"
  ];
  const source = [
    "By a friend",
    "Facebook",
    "Whatsapp",
    "Email",
    "YouTube",
    "Google",
  ];
  const board = ["CBSE", "IGCSE", "ICSE", "State Board", "Others"];

  const history = useHistory();
  console.log(history.location.pathname);

  const {
    REACT_APP_CLIENT_EMAIL,
    REACT_APP_PRIVATE_KEY,
    REACT_APP_SPREADSHEET_ID,
    REACT_APP_DUPLICATE_ADMISSION_SPREADSHEET_ID,
    REACT_APP_KCET_ADMISSION_SPREADSHEET_ID,
    REACT_APP_KCET_DUPLICATE_ADMISSION_SPREADSHEET_ID,
    REACT_APP_MHTCET_ADDMISSION_SPREADSHEET_ID,
    REACT_APP_MHTCET_DUPLICATE_ADMISSION_SPREADSHEET_ID,
    REACT_APP_EAMCET_ADDMISSION_SPREADSHEET_ID,
    REACT_APP_EAMCET_DUPLICATE_ADMISSION_SPREADSHEET_ID,
    REACT_APP_ONETOONE_ADMISSION_SPREADSHEET_ID,
    REACT_APP_ONETONE_DUPLICATE_ADMISSION_SPREADSHEET_ID,
    REACT_APP_ONETOSIX_ADMISSION_SPREADSHEET_ID,
    REACT_APP_ONETOSIX_DUPLICATE_ADMISSION_SPREADSHEET_ID,
  } = process.env;

  const docDuplicate = new GoogleSpreadsheet(
    REACT_APP_DUPLICATE_ADMISSION_SPREADSHEET_ID
  );

  const docKcet = new GoogleSpreadsheet(
    REACT_APP_KCET_ADMISSION_SPREADSHEET_ID
  );

  const docDupKcet = new GoogleSpreadsheet(
    REACT_APP_KCET_DUPLICATE_ADMISSION_SPREADSHEET_ID
  );

  ///onetoone
  const doconetoone = new GoogleSpreadsheet(
    REACT_APP_ONETOONE_ADMISSION_SPREADSHEET_ID
  );

  const docDuponetone = new GoogleSpreadsheet(
    REACT_APP_ONETONE_DUPLICATE_ADMISSION_SPREADSHEET_ID
  );
  //ONetoone

  ///onetosix
  const doconetosix = new GoogleSpreadsheet(
    REACT_APP_ONETOSIX_ADMISSION_SPREADSHEET_ID
  );

  const docDuponetosix = new GoogleSpreadsheet(
    REACT_APP_ONETOSIX_DUPLICATE_ADMISSION_SPREADSHEET_ID
  );
  //ONetosix

  const mhtcet = new GoogleSpreadsheet(
    REACT_APP_MHTCET_ADDMISSION_SPREADSHEET_ID
  );
  const mhtcetDup = new GoogleSpreadsheet(
    REACT_APP_MHTCET_DUPLICATE_ADMISSION_SPREADSHEET_ID
  );
  const eamcet = new GoogleSpreadsheet(
    REACT_APP_EAMCET_ADDMISSION_SPREADSHEET_ID
  );
  const eamcetDup = new GoogleSpreadsheet(
    REACT_APP_EAMCET_DUPLICATE_ADMISSION_SPREADSHEET_ID
  );

  const doc = new GoogleSpreadsheet(REACT_APP_SPREADSHEET_ID);

  const appendSpreadsheet = async (row) => {
    try {
      await doc.useServiceAccountAuth({
        client_email: REACT_APP_CLIENT_EMAIL,
        private_key: REACT_APP_PRIVATE_KEY,
      });
      const docInfo = await doc.loadInfo();
     
      const sheet = doc.sheetsById["0"];
      const result = await sheet.addRow(row);

      return result;
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const appendSpreadsheetDuplicate = async (row) => {
    try {
      await docDuplicate.useServiceAccountAuth({
        client_email: REACT_APP_CLIENT_EMAIL,
        private_key: REACT_APP_PRIVATE_KEY,
      });
      await docDuplicate.loadInfo();
      console.log(docDuplicate.loadInfo());
      const sheet = docDuplicate.sheetsById["0"];
      const result = await sheet.addRow(row);
      console.log(result);
      return result;
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const appendSpreadsheetKcet = async (row) => {
    try {
      await docKcet.useServiceAccountAuth({
        client_email: REACT_APP_CLIENT_EMAIL,
        private_key: REACT_APP_PRIVATE_KEY,
      });
      await docKcet.loadInfo();
      console.log(docKcet.loadInfo());
      const sheet = docKcet.sheetsById["0"];
      const result = await sheet.addRow(row);
      console.log(result);
      return result;
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const appendSpreadsheetDuplicateKcet = async (row) => {
    try {
      await docDupKcet.useServiceAccountAuth({
        client_email: REACT_APP_CLIENT_EMAIL,
        private_key: REACT_APP_PRIVATE_KEY,
      });
      await docDupKcet.loadInfo();
      console.log(docDupKcet.loadInfo());
      const sheet = docDupKcet.sheetsById["0"];
      const result = await sheet.addRow(row);
      console.log(result);
      return result;
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  /////ONETO ONE
  const appendSpreadsheetOnetone = async (row) => {
    try {
      await doconetoone.useServiceAccountAuth({
        client_email: REACT_APP_CLIENT_EMAIL,
        private_key: REACT_APP_PRIVATE_KEY,
      });
      await doconetoone.loadInfo();
      console.log(doconetoone.loadInfo());
      const sheet = doconetoone.sheetsById["0"];
      const result = await sheet.addRow(row);
      console.log(result);
      return result;
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const appendSpreadsheetDuplicateOnetoone = async (row) => {
    try {
      await docDuponetone.useServiceAccountAuth({
        client_email: REACT_APP_CLIENT_EMAIL,
        private_key: REACT_APP_PRIVATE_KEY,
      });
      await docDuponetone.loadInfo();
      console.log(docDuponetone.loadInfo());
      const sheet = docDuponetone.sheetsById["0"];
      const result = await sheet.addRow(row);
      console.log(result);
      return result;
    } catch (e) {
      console.error("Error: ", e);
    }
  };
  ///ONETO ONE

  /////ONETO six
  const appendSpreadsheetOnetsix = async (row) => {
    try {
      await doconetosix.useServiceAccountAuth({
        client_email: REACT_APP_CLIENT_EMAIL,
        private_key: REACT_APP_PRIVATE_KEY,
      });
      await doconetosix.loadInfo();
      console.log(doconetosix.loadInfo());
      const sheet = doconetosix.sheetsById["0"];
      const result = await sheet.addRow(row);
      console.log(result);
      return result;
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const appendSpreadsheetDuplicateOnetosix = async (row) => {
    try {
      await docDuponetosix.useServiceAccountAuth({
        client_email: REACT_APP_CLIENT_EMAIL,
        private_key: REACT_APP_PRIVATE_KEY,
      });
      await docDuponetosix.loadInfo();
      console.log(docDuponetosix.loadInfo());
      const sheet = docDuponetosix.sheetsById["0"];
      const result = await sheet.addRow(row);
      console.log(result);
      return result;
    } catch (e) {
      console.error("Error: ", e);
    }
  };
  ///ONETO six

  const appendSpreadsheetmhtcet = async (row) => {
    try {
      await mhtcet.useServiceAccountAuth({
        client_email: REACT_APP_CLIENT_EMAIL,
        private_key: REACT_APP_PRIVATE_KEY,
      });
      await mhtcet.loadInfo();
      console.log(mhtcet.loadInfo());
      const sheet = mhtcet.sheetsById["0"];
      const result = await sheet.addRow(row);
      console.log(result);
      return result;
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const appendSpreadsheetDuplicatemhtcet = async (row) => {
    try {
      await mhtcetDup.useServiceAccountAuth({
        client_email: REACT_APP_CLIENT_EMAIL,
        private_key: REACT_APP_PRIVATE_KEY,
      });
      await mhtcetDup.loadInfo();
      console.log(mhtcetDup.loadInfo());
      const sheet = mhtcetDup.sheetsById["0"];
      const result = await sheet.addRow(row);
      console.log(result);
      return result;
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const appendSpreadsheeteamcet = async (row) => {
    try {
      await eamcet.useServiceAccountAuth({
        client_email: REACT_APP_CLIENT_EMAIL,
        private_key: REACT_APP_PRIVATE_KEY,
      });
      await eamcet.loadInfo();
      console.log(eamcet.loadInfo());
      const sheet = eamcet.sheetsById["0"];
      const result = await sheet.addRow(row);
      console.log(result);
      return result;
    } catch (e) {
      console.error("Error: ", e);
    }
  };
  const appendSpreadsheetDuplicateeamcet = async (row) => {
    try {
      await eamcetDup.useServiceAccountAuth({
        client_email: REACT_APP_CLIENT_EMAIL,
        private_key: REACT_APP_PRIVATE_KEY,
      });
      await eamcetDup.loadInfo();
      console.log(eamcetDup.loadInfo());
      const sheet = eamcetDup.sheetsById["0"];
      const result = await sheet.addRow(row);
      console.log(result);
      return result;
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  const handleInput = (event) => {
    if (event.target.name === "country") {
      setSelectedCountry(event.target.value);
      state();
    }
    const date = new Date();
    setDetails({
      ...details,
      [event.target.name]: event.target.value,
      date: date,
    });
  };

  const handleCheckInput = (event) => {
    var index = details.subjectsArray.findIndex(
      (d) => d === event.target.value
    );
    if (index === -1) {
      setDetails({
        ...details,
        subjectsArray: [...details.subjectsArray, event.target.value],
      });
    } else {
      var newArray = details.subjectsArray;
      newArray.splice(index, 1);
      setDetails({ ...details, subjectsArray: newArray });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (details.subjectsArray.length) {
      var finalDetails = { ...details, subjects: details.subjectsArray.join() };
      console.log(finalDetails);
      appendSpreadsheet(finalDetails);
      appendSpreadsheetDuplicate(finalDetails);
      if (history.location.pathname.includes("kcet")) {
        console.log('kcet')
        appendSpreadsheetKcet(finalDetails);
        appendSpreadsheetDuplicateKcet(finalDetails);
      } else if (history.location.pathname.includes("mhtcet")) {
        appendSpreadsheetmhtcet(finalDetails);
        appendSpreadsheetDuplicatemhtcet(finalDetails);
      } else if (history.location.pathname.includes("eamcet")) {
        appendSpreadsheeteamcet(finalDetails);
        appendSpreadsheetDuplicateeamcet(finalDetails);
      } else if (history.location.pathname.includes("onetoone")) {
        console.log("trigger ibe tn one")
        appendSpreadsheetOnetone(finalDetails);
        appendSpreadsheetDuplicateOnetoone(finalDetails);
      } else if (history.location.pathname.includes("onetosix")) {
        appendSpreadsheetOnetsix(finalDetails);
        appendSpreadsheetDuplicateOnetosix(finalDetails);
      }

      fetch(`${instance_url}/student/create`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(finalDetails),
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
      
        })
        .catch((err) => {
          alert(
            "Something went wrong! may be your using already existing mail / mobile no"
          );
       
        });
          alert("Form Submited Sucessfully!");
          history.push("/");
    } else {
      setErr("Please select any subjects!");
    }
  };

  console.log(stateData);
  return (
    <>
      <Helmet>
        <title>Revamp24 | Admission</title>
      </Helmet>
      <form className="admission_form_container" onSubmit={handleSubmit}>
        <img src="/logo/logo.png" alt="" className="admission_form_logo" />
        <div className="form_banner">
          <h1>
            ADMISSION <br /> FORM
          </h1>
          <img src="/images/AdmissionBanner.png" alt="" />
        </div>

        <div className="form_student_detail">
          <h1>STUDENT DETAILS</h1>
          <div className="intput_section">
            <div className="form_input_container">
              <label htmlFor="student_name">Student Name</label>
              <input
                type="text"
                name="student_name"
                required
                onChange={handleInput}
              />
            </div>
            <div className="form_input_container">
              <label htmlFor="student_name">School Name</label>
              <input
                type="text"
                name="school_name"
                required
                onInput={handleInput}
              />
            </div>
          </div>
          <div className="intput_section">
            <div className="form_input_container">
              <label htmlFor="student_name">Mobile No</label>
              <input type="tel" name="mobile" required onInput={handleInput} />
            </div>
            <div className="form_input_container">
              <label htmlFor="student_name">Address</label>
              <input
                type="text"
                name="address"
                required
                onInput={handleInput}
              />
            </div>
          </div>
          <div className="intput_section">
            <div className="form_input_container">
              <label htmlFor="country">Country</label>
              <select
                type="text"
                required
                name="country"
                onChange={handleInput}
              >
                <option value="">None</option>
                {countries.map((d, i) => (
                  <option value={d.name} key={i}>
                    {d.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form_input_container">
              <label htmlFor="state">State</label>
              <select type="text" required name="state" onChange={handleInput}>
                {
                  // stateData.length === 0 && selectedCountry === "" ? (
                  //   <option value="">Please Select Country</option>
                  // ) :
                  stateData?.map((d, i) => (
                    <option value={d.name} key={i}>
                      {d.name}
                    </option>
                  ))
                }
              </select>
            </div>
          </div>
          <div className="intput_section">
            <div className="form_input_container">
              <label htmlFor="student_name">City</label>
              <input type="text" name="city" required onInput={handleInput} />
            </div>
            <div className="form_input_container">
              <label htmlFor="student_name">Pin Code</label>
              <input type="number" name="pin" required onInput={handleInput} />
            </div>
          </div>
          <div className="intput_section">
            <div className="form_input_container">
              <label htmlFor="student_name">
                Percentage scored in last term exam
              </label>
              <input
                type="number"
                name="score"
                required
                onInput={handleInput}
              />
            </div>
            <div className="form_input_container">
              <label htmlFor="student_name">Your Email</label>
              <input type="email" name="mail" required onInput={handleInput} />
            </div>
          </div>
        </div>

        <div className="form_student_detail">
          <h1>GUARDIAN DETAILS</h1>
          <div className="intput_section">
            <div className="form_input_container">
              <label htmlFor="student_name">Guardian Name</label>
              <input
                type="text"
                name="guardian_name"
                required
                onInput={handleInput}
              />
            </div>
            <div className="form_input_container">
              <label htmlFor="student_name">Relationship</label>
              <input
                type="text"
                name="guardian_relation"
                required
                onInput={handleInput}
              />
            </div>
          </div>
          <div className="intput_section">
            <div className="form_input_container">
              <label htmlFor="student_name">Guardian Email</label>
              <input
                type="email"
                name="guardian_mail"
                required
                onInput={handleInput}
              />
            </div>
            <div className="form_input_container">
              <label htmlFor="student_name">Guardian Mobile</label>
              <input
                type="tel"
                name="guardian_mobile"
                required
                onInput={handleInput}
              />
            </div>
          </div>
          <h1>ACADEMICS</h1>
          <div className="intput_section">
            <div className="form_input_container">
              <label htmlFor="student_name">Class of student</label>
              <select type="text" required name="class" onChange={handleInput}>
                <option value="">None</option>
                {classes.map((d, i) => (
                  <option value={d} key={i}>
                    {d}
                  </option>
                ))}
              </select>
            </div>
            <div className="form_input_container">
              <label htmlFor="student_name">Preffered time slot</label>
              <select
                type="text"
                required
                name="time_slot"
                onChange={handleInput}
              >
                <option value="">None</option>
                {time.map((d, i) => (
                  <option value={d} name="time_slot" key={i}>
                    {d}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="form_subject_section" id="subject_list">
            <p>Required subjects</p>
            <div className="admission_subject_container">
              {subjects.map((d, i) => (
                <div className="checkbox_container" key={i}>
                  <input
                    type="checkbox"
                    name={d}
                    value={d}
                    onClick={handleCheckInput}
                  />
                  <label htmlFor="subject">{d}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="form_subject_section">
            <p>Board</p>
            <div className="admission_subject_container">
              {board.map((d, i) => (
                <div className="checkbox_container" key={i}>
                  <input
                    type="radio"
                    name="board"
                    onChange={handleInput}
                    required
                    value={d}
                  />
                  <label htmlFor="subject">{d}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="form_subject_section">
            <p>How did you came to know about Revamp24?</p>
            <div className="admission_subject_container">
              {source.map((d, i) => (
                <div className="checkbox_container" key={i}>
                  <input
                    type="radio"
                    name="source"
                    onChange={handleInput}
                    required
                    value={d}
                  />
                  <label htmlFor="subject">{d}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="form_message_section">
            {/* <p>Your Message</p> */}
            <textarea
              placeholder="Your Message..."
              name="message"
              required
              cols="30"
              onChange={handleInput}
              rows="10"
            ></textarea>
          </div>
        </div>
        <b>{err}</b>
        <button type="submit" className="admission_form_btn">
          Submit
        </button>
      </form>
    </>
  );
};

export default AdmissionForm;
