import React from 'react'
import { Link } from 'react-router-dom'
import CourseDetails from './CourseDetails/CourseDetails'
import './CourseLanding.scss'

const CourseLanding = ({id}) => {

    return (
        <>
            {
                <div className="course_landing">
                    <div className="course_details">
                        <div className="course_class">
                            <h2>{id.split(" ")[0]} <span>{id.split(" ")[1]}</span></h2>
                        </div>
                        <CourseDetails />
                    </div>
                    <div className="image-container">
                        <div className="image_bg_circle"></div>
                        <img src="/images/coursePage/course_landing_girl.png" alt="" />
                        <img src="/images/coursePage/course_landing_icons.svg" className="landing_icons" alt="" />
                    </div>
                    <div className="schedule_class">
                        <h6>Class Shedule</h6>
                        <Link to={"/schedule/" + id} className="schedule_dwnld_btn">Download</Link>
                    </div>
                </div>

            }
        </>
    )
}

export default CourseLanding
