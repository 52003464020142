import React from 'react'
import './InnovativeMobileCard.scss'

export default function InnovativeMobileCard({icon,content,heading,iconBg}) {
    return (
        <div className="innovative-mobile-card">
            <img src={icon}  alt="" className={iconBg} />
            <div className="inno-card-content">
                <h6>{heading}</h6>
                <p>{content} </p>
            </div>
        </div>
    )
}
