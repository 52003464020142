const actions = {
    storeData : (data) =>(
        {
            type: 'STORE_DATA',
            payload: data
        }
    ),
    storeDate: (date) =>(
        {
            type: 'STORE_DATE',
            payload: date
        }
    ),
    storeSubject : (subject) =>(
        {
            type: "STORE_SUBJECT",
            payload:subject
        }
    ),
    storeTime : (time) => (
        {
            type : "STORE_TIME",
            payload : time
        }
    ),
    dateCheck : (dateArray) =>(
        {
            type : 'DATE_CHECK',
            payload : dateArray
        }
    ),
    addLogs : (logs) =>({
        type: "ADD_LOGS",
        payload : logs
    }),
    timesAvailibility : (times) =>({
        type : "TIME_AVAILIBILITY",
        payload : times
    }),
    deleteSubject : () =>({
        type : "DELETE_SUBJECT"
    }),
    deleteDate : () => ({
        type : "DELETE_DATE"
    }),
    deleteTime : () => ({
        type : "DELETE_TIME"
    }),
    reload : () => ({
        type:"WINDOW_RELOAD"
    }),
    toggleBannerAd : () => ({
        type: "BANNER_TOGGLE"
    }),
    switchOnLoading : () => ({
        type: "SWITCH_ON_LOADING"
    }),
    switchOffLoading : () => ({
        type: "SWITCH_OFF_LOADING"
    })
}

export default actions;