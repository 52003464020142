import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom';
import './BlogCard.scss'

export default function BlogCard({blogData}) {
    const [description,setDescription] = useState(null);
    const history = useHistory();
    useEffect(()=>{
        let desc = blogData.body.replace(/[^A-Za-z0-9]/g, ' ')
        desc = desc.replace(/\s\s+/g," ")
        setDescription(desc)
    },[])

    // Function for handle the click on the card
    const handleCardClick = () => {
        history.push(`/blog/single/${blogData._id}`)
    }
    return (
        <div className="blog-card" onClick={handleCardClick}>
            <div className="image-wrapper">
                <img src={blogData.image.image_url} alt="" />
            </div>
            <h5 className="card-heading">{blogData.title}</h5>
            {
                description &&
                <p className="card-description">{description}</p>
            }
        </div>
    )
}

