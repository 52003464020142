import React from 'react'
import Footer from '../Footer/Footer'
import './PrivacyPolicy.scss'
export default function PrivacyPolicy() {
    return (
        <div className="info_links_contents">
            <div className="info_content_container">
                <div className="logo">
                    <img src="/icons/logo.png" className="logo_image" alt="" />
                </div>
                <p className="main_description">
                    We value the trust you place in Revamp24. That’s why we insist upon the highest standards
                    for secure transactions and customer information privacy. Please read the following
                    statement to learn about our information gathering and dissemination practices.
                    <br />
                    <br />
                    Note: Our privacy policy is subject to change at any time without notice. To make sure you
                    are aware of any changes, please review this policy periodically.
                </p>
                <h4 className="introduction">Introduction</h4>
                <p className="intro_content">
                    We, at ‘Revamp24 edu pvt ltd’ are committed to respecting your online privacy and
                    recognize your need for appropriate protection and management of any personally
                    identifiable information (“Personal Information”) you share with us.
                    <br />
                    <br />
                    “Personal Information” means any information that may be used to identify an individual,
                    including, but not limited to, a first and last name, a home or other physical address and an
                    email address or any other contact information, whether at work or at home. In general, you
                    can visitRevamp24 edu pvt ltd’s Web pages without telling us who you are or revealing any
                    Personal Information about yourself.
                </p>
                <h4>Cookies and Other Tracking</h4>
                <p>
                    Technologies Some of our Web pages utilize “cookies” and other tracking technologies. A
                    “cookie” is a small text file that may be used, for example, to collect information about Web
                    site activity. Some cookies and other technologies may serve to recall Personal Information
                    previously indicated by a Web user. Most browsers allow you to control cookies, including
                    whether or not to accept them and how to remove them.
                    <br />
                    <br />
                    You may set most browsers to notify you if you receive a cookie, or you may choose to block
                    cookies with your browser, but please note that if you choose to erase or block your cookies,
                    you will need to re-enter your original user ID and password to gain access to certain parts
                    of the Web site.
                    <br />
                    <br />
                    Tracking technologies may record information such as Internet domain and host names;
                    Internet protocol (IP) addresses; browser software and operating system types; clickstream
                    patterns; and dates and times that our site is accessed. Our use of cookies and other
                    tracking technologies allows us to improve our Web site and your Web experience. We may
                    also analyze information that does not contain Personal Information for trends and statistics.
                </p>
                <h4>Third Party Services</h4>
                <p>
                    Third parties provide certain services available on www.Revamp24.in onRevamp24 edu pvt
                    ltd’s behalf. ‘Revamp24 edu pvt ltd’ may provide information, including Personal Information,
                    that ‘Revamp24 edu pvt ltd’ collects on the Web to third-party service providers to help us
                    deliver programs, products, information, and services which includes but not limited to
                    background checks, security checks and other verification by third party agencies and
                    service providers. Service providers are also an important means by which ‘Revamp24 edu
                    pvt ltd’ maintains its Web site and mailing lists. ‘Revamp24 edu pvt ltd’ will take reasonable
                    steps to ensure that these third-party service providers are obligated to protect Personal
                    Information onRevamp24 edu pvt ltd’s behalf.
                    <br />
                    <br />
                    ‘Revamp24 edu pvt ltd’ does not intend to transfer Personal Information without your
                    consent to third parties who are not bound to act onRevamp24 edu pvt ltd’s behalf unless
                    such transfer is legally required. Similarly, it is againstRevamp24 edu pvt ltd’s policy to sell
                    Personal Information collected online without consent.
                </p>
                <h4>Your Consent</h4>
                <p>
                    By using this Web site, you consent to the terms of our Online Privacy Policy and
                    toRevamp24 edu pvt ltd’s processing of Personal Information for the purposes given above
                    as well as those explained where ‘Revamp24 edu pvt ltd’ collects Personal Information on
                    the Web Information security.
                    <br />
                    <br />
                    We take appropriate security measures to protect against unauthorized access to or
                    unauthorized alteration, disclosure or destruction of data.
                    <br />
                    <br />
                    We restrict access to your personally identifying information to employees who need to
                    know that information in order to operate, develop or improve our services.
                </p>
                <h4>Updating your information</h4>
                <p>
                    We provide mechanisms for updating and correcting your personally identifying information
                    for many of our services.
                </p>
                <h4>Information Sharing and Disclosure</h4>
                <p>
                    ‘Revamp24 edu pvt ltd’ does not rent, sell, or share personal information about you with
                    other people (save with your consent) or non-affiliated companies except to provide
                    products or services you’ve requested, when we have your permission, or under the
                    following circumstances :
                </p>
                <ul>
                    <li>
                        We provide the information to trusted partners who work on behalf of or with
                        ‘Revamp24 edu pvt ltd’ under confidentiality agreements. These companies may use
                        your personal information to help ‘Revamp24 edu pvt ltd’ communicate with you
                        about offers from ‘Revamp24 edu pvt ltd’ and our marketing partners. However, these
                        companies do not have any independent right to share this information.
                    </li>
                    <li>
                        We respond to subpoenas, court orders, or legal process, or to establish or exercise
                        our legal rights or defend against legal claims;
                    </li>
                    <li>
                        We believe it is necessary to share information in order to investigate, prevent, or
                        take action regarding illegal activities, suspected fraud, situations involving potential
                        threats to the physical safety of any person, violations ofRevamp24 edu pvt ltd’s
                        terms of use, or as otherwise required by law.
                    </li>
                    <li>
                        We transfer information about you if ‘Revamp24 edu pvt ltd’ is acquired by or merged
                        with another company. In this event, ‘Revamp24 edu pvt ltd’ will notify you before
                        information about you is transferred and becomes subject to a different privacy policy.
                    </li>
                    <li>
                        ‘Revamp24 edu pvt ltd’ displays targeted advertisements based on personal
                        information. Advertisers (including ad serving companies) may assume that people
                        who interact with, view, or click on targeted ads meet the targeting criteria – for
                        example, women aged 18-24 from a particular geographic area.
                    </li>
                    <li>
                        ‘Revamp24 edu pvt ltd’ does not provide any personal information to the advertiser
                        when you interact with or view a targeted ad. However, by interacting with or viewing
                        an ad you are consenting to the possibility that the advertiser will make the
                        assumption that you meet the targeting criteria used to display the ad.
                    </li>
                    <li>
                        ‘Revamp24 edu pvt ltd’ advertisers include financial service providers (such as banks,
                        insurance agents, stock brokers and mortgage lenders) and non-financial companies
                        (such as stores, airlines, and software companies).
                    </li>
                    <li>
                        ‘Revamp24 edu pvt ltd’ works with vendors, partners, advertisers, and other service
                        providers in different industries and categories of business.
                    </li>
                </ul>
                <h4>Confidentiality and Security</h4>
                <ul>
                    <li>
                        We limit access to personal information about you to employees who we believe
                        reasonably need to come into contact with that information to provide products or
                        services to you or in order to do their jobs.
                    </li>
                    <li>
                        We have physical, electronic, and procedural safeguards that comply with the laws
                        prevalent in India to protect personal information about you.
                    </li>
                </ul>
                <h4>Changes to this Privacy Policy</h4>
                <ul>
                    <li>
                        ‘Revamp24 edu pvt ltd’ reserves the right to update, change or modify this policy at
                        any time. The policy shall come to effect from the date of such update, change or
                        modification.
                    </li>
                    <li>
                        Revamp24 edu pvt ltd shall not be liable for any loss or damage sustained by reason
                        of any disclosure (inadvertent or otherwise) of any information concerning the user’s
                        account and / or information relating to or regarding online transactions using credit
                        cards / debit cards and / or their verification process and particulars nor for any error,
                        omission or inaccuracy with respect to any information so disclosed and used
                        whether or not in pursuance of a legal process or otherwise.
                    </li>
                </ul>
                <h4>Contact Information</h4>
                <p>
                    ‘Revamp24 edu pvt ltd’ welcomes your comments regarding this privacy statement at the
                    contact address given at the website. Should there be any concerns about contravention of
                    this Privacy Policy, ‘Revamp24 edu pvt ltd’ will employ all commercially reasonable efforts to
                    address the same.
                    <br />
                    <br />
                    Note : The terms in this agreement may be changed byRevamp24 edu pvt ltd at any
                    time.Revamp24 edu pvt ltd is free to offer its services to any client/prospective client without
                    restriction
                </p>
            </div>
            <Footer/>
        </div>
    )
}
