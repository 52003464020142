import React from 'react'
import { Link } from 'react-router-dom'
import TrailBtn from '../../../TrailButton/TrailBtn'
import './PriceStrip.scss'
import { AiOutlineArrowRight } from 'react-icons/ai'

export default function PriceStrip({ cardName, type, btn, color, link, knowMore }) {
    return (
        <div className={ "price-strip "+type }>
            <div className="card-content">
                <p>{cardName}</p>
                <Link to={knowMore}>Know more <AiOutlineArrowRight/></Link>
            </div>
            <TrailBtn children={btn} color={color} link={link} type="btn_free_session"/>

        </div>
    )
}
