import React from 'react'
// import { useParams } from 'react-router-dom'
import CoursePriceCard from '../../compititive exam/compititivePrice/compititive price card/CompititivePriceCard'
import './CoursePricing.scss'

const CoursePricing = ({ id }) => {
    return (
        <div className="course_pricing">
            <div className="pricing_sections">
                <div className="course_includes">
                    {
                        id
                        &&
                        <h6>{id} course includes</h6>
                    }
                    <ul>
                        <ul className="for_tab_course">
                            <li><img src="/images/coursePage/live.png" alt="" />Live interactive classes</li>
                            <li><img src="/images/coursePage/weekly.png" alt="" />Weekly Five sessions</li>
                            <li><img src="/images/coursePage/one_to_one.png" alt="" />One-on-one mentor Guidance</li>
                        </ul>
                        <ul className="for_tab_course">
                            <li><img src="/images/coursePage/progress.png" alt="" />Tests and progress report after each chapter</li>
                            <li><img src="/images/coursePage/additional_support.png" alt="" />Additional support to Improve understanding of weak concepts</li>
                            <li><img src="/images/coursePage/parent_meeting.png" alt="" />Monthly parent teacher meetings to get updates</li>
                        </ul>
                    </ul>
                </div>
            </div>
            <div className="pricing_sections">
                <CoursePriceCard type="in_course"/>
            </div>
            
            {/* <CoursePriceCard/> */}
        </div>
    )
}

export default CoursePricing
