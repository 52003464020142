import React from 'react'
import CompititiveCard from './CourseCard/CompititiveCard'
import CourseCard from './CourseCard/CourseCard'
import './Landing.scss'

const classes = ["Class 5","Class 6","Class 7","Class 8", "Class 9", "Class 10", "Class 11", "Class 12"]
const compititiveExams = ["NEET", "JEE", "KCET", "COMEDK","TS EAMCET","AP EAMCET","MHTCET"]

const Landing = () => {
    return (
        <div className="landing_page">
            <div className="landing_circle_bg">
                <img src="/images/DNA.png" className="DNA_icon" alt="" />
                <img src="/images/Vector.png " className="atom-icon" alt="" />
                <div className="bg_rectangle_s"></div>
                <div className="bg_rectangle_xs"></div>
                <div className="bg_rectangle_l"></div>
                <div className="bg_rectangle_tilt"></div>
            </div>
            <div className="landing_contents">
                <div className="hero_text_container">
                    <div className="hero_text">
                        <h1>Face to face interactive classes for maths and science.</h1>
                        <p>Live online classes with personal attention.</p>
                    </div>
                    <CourseCard cardTitle="Live face to face classes" courses={classes} type="text_attach"/>
                </div>
                <div className="owners_container">
                    <img src="/images/homeparent2.png" loading="lazy" alt=""/>
                    <CompititiveCard cardTitle="Competitive Exams" courses={compititiveExams} type="image_attach"/>
                </div>
            </div>
            <div className="mobile_cards">
                <CourseCard cardTitle="Live face to face classes" courses={classes} type="course_card_mobile"/>
                <CompititiveCard cardTitle="Competitive Exams" courses={compititiveExams} type="course_card_mobile"/>
            </div>
        </div>
    )
}

export default Landing
