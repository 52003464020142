import React from 'react'
import "./TuitionCards.scss"
export default function TuitionCards({cardHeader,cardIcon,cardContent}) {
    return (
       <div className="tuition_cards_container">          
       <img src={cardIcon} alt=""/>
       <div className="tuition_cards_main">
       <h2>{cardHeader}</h2>
       <p>{cardContent}</p>    
       </div>
       </div>
    )
}
