import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PriceStrip from '../../../Courses/CoursePricing/Price strip/PriceStrip'
import SubjectCard from '../Subject card/SubjectCard'
import './CompititivePriceCard.scss'

export default function CoursePriceCard({ type, compititive,id }) {
    const [oneMonthPrice, setOneMonthPrice] = useState(null),
        [oneMonthDiscount,setOneMonthDiscount] = useState(null),
        [buyPrice, setBuyPrice] = useState(null),
        [buyDiscount,setBuyDiscount] = useState(null)
    
    const plan = useSelector(state => state.coursePrice.plan)
    const KCET = {
        oneMonth:{
            Lite:"https://rzp.io/l/A4NyFhA",
            Classic:"https://rzp.io/l/tSpKfYv",
            Plus:"https://rzp.io/l/0Iv2oKwdSU"
        },
        completeCourse:{
            Lite:"https://rzp.io/l/i3dsm5JZ4",
            Classic:"https://rzp.io/l/ebfjMEWNqh",
            Plus:"https://rzp.io/l/rWsrzEV"
        }
    }
    const COMEDK ={
        oneMonth:{
            Lite:"https://rzp.io/l/YMw6bWa",
            Classic:"https://rzp.io/l/0inAuZt7",
            Plus:"https://rzp.io/l/AepZYk7q3"
        },
        completeCourse:{
            Lite:"https://rzp.io/l/9EAK4zxsPz",
            Plassic:"https://rzp.io/l/iOJHwlLFa",
            Plus:"https://rzp.io/l/wXqnVmXc"
        }
    }
    const PESSAT ={
        oneMonth:{
            Lite:"https://rzp.io/l/tsUmYVnY1",
            Classic:"https://rzp.io/l/koCmjYxuK",
            Plus:"https://rzp.io/l/YW88Zy8B8C"
        },
        completeCourse:{
            Lite:"https://rzp.io/l/9aTGyYjkW",
            Plassic:"https://rzp.io/l/WNC6lCBJfx",
            Plus:"https://rzp.io/l/0JxqMZLjXe"
        }
    }
    const NEET ={
        oneMonth:{
            Lite:"https://rzp.io/l/WCGQCIwScK",
            Classic:"https://rzp.io/l/PefCW1xVCt",
            Plus:"https://rzp.io/l/q9ePx3pfFj"
        },
        completeCourse:{
            Lite:"https://rzp.io/l/la4cts4r",
            Plassic:"https://rzp.io/l/KTfBQF4l8",
            Plus:"https://rzp.io/l/ciLZDBmVJ1"
        }
    }
    const JEE ={
        oneMonth:{
            Lite:"https://rzp.io/l/RXg0kk5Q",
            Classic:"https://rzp.io/l/FYgUn07E",
            Plus:"https://rzp.io/l/zturRMGauo"
        },
        completeCourse:{
            Lite:"https://rzp.io/l/q9ePx3pfFj",
            Plassic:"https://rzp.io/l/uoJMTcCsV",
            Plus:"https://rzp.io/l/7rpSYEsGnx"
        }
    }

    useEffect(()=>{
        if(plan === "Lite"){
            setOneMonthPrice("2,900")
            setOneMonthDiscount("2,340")
            setBuyPrice("1,30000")
            setBuyDiscount("1,12997")
        }else if(plan === "Classic"){
            setOneMonthPrice("3,700")
            setOneMonthDiscount("2,970")
            setBuyPrice("1,30000")
            setBuyDiscount("1,12997")
        }else if(plan === "Plus"){
            setOneMonthPrice("4,230")
            setOneMonthDiscount("5,500")
            setBuyPrice("71,799")
            setBuyDiscount("58,499.1")
        }
    },[plan])

    const handleTryOneMonth = () =>{
        switch(id){
            case "KCET":
                window.location.href = (KCET.oneMonth[plan])
                break;
            case "COMEDK":
                window.location.href =  (COMEDK.oneMonth[plan])
                break;
            case 'PESSAT':
                window.location.href = (PESSAT.oneMonth[plan])
                break;
            case 'NEET':
                window.location.href = (NEET.oneMonth[plan])
                break;
            case 'JEE':
                window.location.href = (JEE.oneMonth[plan])
                break;
            default:
                return null
        }
    }
    const handleBuyComplete = () =>{
        switch(id){
            case "KCET":
                window.location.href = (KCET.completeCourse[plan])
                break;
            case "COMEDK":
                window.location.href =  (COMEDK.completeCourse[plan])
                break;
            case 'PESSAT':
                window.location.href = (PESSAT.completeCourse[plan])
                break;
            case 'NEET':
                window.location.href = (NEET.completeCourse[plan])
                break;
            case 'JEE':
                window.location.href = (JEE.completeCourse[plan])
                break;
            default:
                return null
        }
    }
    
    return (
        <div className={"course_pricing_card " + type}>
            {
                compititive ?
                    (
                        <>
                            <div className="subject_card_container">
                                {/* <SubjectCard 
                                    subject="Lite" 
                                    type="mt-0" 
                                    price="30,599.1" 
                                    off= "19%"
                                    offRate = "37,799"
                                    isActive={plan === "Lite" ? plan : false} 
                                /> */}
                                <SubjectCard 
                                    subject="Classic" 
                                    price="1,12997" 
                                    // speciality="LITE + Doubt solving on Whatsapp"
                                    isActive={plan === "Classic" ? plan : false}
                                    off="19%"
                                    offRate="1,30000"
                                />
                                {/* <SubjectCard 
                                    subject="Plus" 
                                    type="mb-0" 
                                    price="58,499.1" 
                                    speciality="CLASSIC + Personal mentor"
                                    off="19%"
                                    offRate="71,799"
                                    isActive={plan === "Plus" ? plan : false}
                                /> */}
                            </div>
                            <div className="price-total">
                                {/* <img className="price-balance-image" src="/images/compititive exam/balance.png" alt=""/>
                                <p className="emi">EMI starts at ₹1,499/month</p> */}
                                {/* <div className="try-btn" onClick={handleTryOneMonth}>
                                    <p className="btn-main-text">Try for 1 month</p>
                                    <div className="price-in-btn">
                                        <p>₹{oneMonthDiscount}</p>
                                        <p>₹{oneMonthPrice}</p>
                                    </div>
                                </div> */}
                                <div className="buy-btn" onClick={handleBuyComplete}>
                                    <p className="btn-main-text">Buy complete course</p>
                                    <div className="price-in-btn">
                                        <p>₹{buyDiscount}</p>
                                        <p>₹{buyPrice}</p>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                    :
                    (
                        <div className="price-strips">
                            <PriceStrip
                                cardName="Online personalised tution"
                                btn="Book FREE Session"
                                link="book-a-free-trial"
                                knowMore="/online-tution"
                                type="mt-0"
                            />
                            <PriceStrip
                                cardName="Offline home tution"
                                color="disabled-btn"
                                knowMore="/home-tution"
                                link={false}
                                btn="Currently unavailable"
                            />
                            <PriceStrip
                                cardName="Offline apartment tution"
                                link={false}
                                color="disabled-btn"
                                knowMore="/centre-tuition"
                                btn="Currently unavailable"
                            />
                            <PriceStrip
                                cardName="Offline centre tution"
                                link={false}
                                type="mb-0"
                                color="disabled-btn"
                                knowMore="/centre-tuition"
                                btn="Currently unavailable"
                            />
                            <p className="not-available">*Offline classes are not available due to COVID19.</p>
                        </div>
                    )
            }
        </div>
    )
}

