import React from "react";
import "./InnovativeMobile.scss";
import TrailBtn from "../../../TrailButton/TrailBtn";
import InnovativeMobileCard from "./InnovativeMobileCard/InnovativeMobileCard";
export default function InnovativeMobile() {
  return (
    <div className="innovative_mobile_container">
      <InnovativeMobileCard
        icon="/icons/eval.png"
        content="We begin with a free demo class to assess the learning aptitude of
        the students and assign groups of students with a similar pace of
        learning. This helps in designing effective teaching strategies and
        for the better emotional health of the students."
        iconBg="yellow"
        heading="Evaluation of learning aptitude"
      />
      <InnovativeMobileCard
        icon="/icons/book.png"
        content="Revamp24 Method uses simulations and real-life examples to teach
        concepts aiding in a better understanding of the topics. Our classes
        are through live sessions and the students are encouraged to ask
        questions to obtain conceptual clarity."
        heading="Active style of learning"
        iconBg="light-blue"
      />
      <InnovativeMobileCard
        icon="/icons/learn.png"
        content="Each student receives one to one attention in the classroom that
        will help them identify their specific problem areas and solutions.
        The teachers will assist the students in their unique learning
        systems without compromising the results."
        heading="Customized experience"
        iconBg="purple"
      />
      <InnovativeMobileCard
        icon="/icons/assesment.png"
        content=" We ensure interactive periodic assessments of the learning profile
        of students through well curated tests and otherwise. Students will
        be able to correct themselves at the root of mistakes helping them
        to improve with time."
        heading="Periodic assessments and feedbacks"
        iconBg="magenta"
      />
      <div>
        <div className="button_container_innovative">
          <TrailBtn
            color="red"
            btnID="Button_book_a_free_trial"
            type="btn_trail_why_revamp"
            children="Book a free trail"
          />
        </div>
      </div>
    </div>
  );
}
