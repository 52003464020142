import React from 'react'
import Footer from '../Footer/Footer'
import './ReturnAndCancellation.scss'

export default function ReturnAndCancellation() {
    return (
        <div className="return_and_cancellation">
            <div className="return_container">
                <div className="logo">
                    <img src="/icons/logo.png" className="logo_image" alt="" />
                </div>
                <p>
                    We do not provide any option for cancellation of products and/or services you have
                    purchased or subscribed from Revamp24. In addition, once a product/service has been
                    purchased by you, we cannot provide any refund, either totally or partially. We strongly
                    recommend that you go through the free content/products/services before you
                    subscribe to or purchase any products/services from Revamp24.
                </p>
            </div>
            <Footer/>
        </div>
    )
}
