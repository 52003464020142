import React, { useEffect } from 'react'
import Landing from '../LandingScreen/Landing'
import WhyRevamp from '../WhyRevamp/WhyRevamp'
import HowRevamp from '../WhyRevamp/HowRevamp/HowRevamp'
import Footer from '../Footer/Footer'
import LowPrice from '../WhyRevamp/LowPrice/LowPrice'
import Classroom from '../WhyRevamp/Classroom/Classroom'
import ParentsReview from '../ParentsReview/ParentsReview'
import TeacherSession from '../TeacherSession/TeacherSession'
import FrequentQuestions from '../FrequentQuestions/FrequentQuestions'
import './Home.scss'
import Navbar from '../Navbar/Navbar'
import AttachedBookingButton from '../AttachedBookingButton/AttachedBookingButton'

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (

    <div className="home">
      <Navbar />
      <Landing />
      <WhyRevamp />
      <HowRevamp />
      <LowPrice />
      <Classroom />
      <ParentsReview />
      <TeacherSession />
      <FrequentQuestions />
      <AttachedBookingButton isAd={false}/>
      <Footer />
    </div>
  )
}

export default Home
