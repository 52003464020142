import React, { useEffect, useState } from 'react'
import './BlogContent.scss'
import RecommendationCard from './RecommendationCard/RecommendationCard'
import { BsFacebook, BsLinkedin, BsTwitter } from 'react-icons/bs'
import { RiWhatsappFill } from 'react-icons/ri'
import LeadForm from './LeadForm/LeadForm'
import StickyBar from './LeadForm/StickyBar/StickyBar'
import { instance_url } from '../../../Assets/config'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import actions from '../../../Assets/dataStore/dataStoreAction'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];


export default function BlogContent({ test }) {

    const [post, setPost] = useState('')
    const [category, setCategory] = useState('')
    const [date, setDate] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        getPost(window.location.pathname)
        dispatch(actions.switchOnLoading());
    }, [])

    const getPost = async (path) => {
        const postresponse = await fetch(instance_url + path)
        const content = await postresponse.json()
        setPost(content.blog)
        const category = content.blog.category
        setCategory(category)
        dispatch(actions.switchOffLoading())
        let postDate = new Date(content.blog.last_edited)
        setDate(postDate.getDay() + ' ' + monthNames[postDate.getMonth()] + ' ' + postDate.getFullYear())
    }



    return (
        <div className="blogcontent-container">
            <div className="blogcontent-main">

                <div className="heading">
                    <ul className="navigation-path" >
                        <li className="path" >
                            <Link className="category" to="/blog" >Blogs</Link>
                        </li>
                        <li className="path" >
                            <Link className="category" to={`/blog/category/${category}`}>{category}</Link>
                        </li>
                        <li className="path path-after-none">
                            <p>{post.title}</p>
                        </li>
                    </ul>
                    <h1>
                        {post.title}
                    </h1>
                    <h5>{date}</h5>
                </div>
                <div className="main-content">
                    <ReactMarkdown remarkPlugins={[remarkGfm]} >{post.body}</ReactMarkdown>
                </div>
                <div className="sticky-wrapper">
                    <div className="recomendation">
                        <RecommendationCard
                            Category={category}
                        />
                    </div>
                    <div className="lead-form">
                        <LeadForm />
                    </div>
                </div>
                <StickyBar />
                <div className="share">

                    <p>Share this with your friends</p>
                    <div className="social-media">
                        <BsFacebook className="fb"
                            onClick={() => window.open(`https://www.facebook.com/sharer.php?u=revamp24.in${window.location.pathname}`, 'Revamp24', `width=500px,
                            height=600px`)}

                        />
                        <RiWhatsappFill className="wp" onClick={() => window.open(`https://api.whatsapp.com/send?text=hey checkout Revamp  post ${post.title} ${`https://revamp24.in${window.location.pathname}`}`, 'Revamp24', `width=500px,
                                    height=600px`)} />
                        <BsTwitter className="tr" onClick={() => window.open(`https://twitter.com/share?url=https://revamp24.in${window.location.pathname}&text=hey checkout Revamp New post ${post.title}&hashtags=#${category}#revamp24 #revamp`, 'Revamp24', `width=500px,
                                    height=600px`)} />
                        <BsLinkedin className="ln" onClick={() => window.open(`https://www.linkedin.com/shareArticle?url=https://revamp24.in${window.location.pathname}&title=hey checkout Revamp  post ${post.title} `, 'Revamp24', `width=500px,
                                    height=600px`)} />
                    </div>
                </div>
            </div>
        </div>
    )
}

