import React from "react";
import "./LowPrice.scss";
import TrailBtn from "../../TrailButton/TrailBtn";
export default function LowPrice(props) {
  return (
    <div className="lowprice_container">
      <h4 className="lowprice_title">
        Pricing starts as low as ₹275 per class
      </h4>
      <div className="lowcontainer">
        <div className="price_content">
          <img className="lowprice_image" src="/images/medal 1.png" alt="" />
        </div>
        <p className="medal_para">incomparable quality & price</p>

        <div className="price_content2">
          <img
            className="lowprice_image"
            src="/images/project-plan 1.png"
            alt=""
          />
        </div>
        <p className="project_para">Flexible & transparent plans</p>
        <div className="price_content3">
          <img className="lowprice_image" src="/images/refund 1.png" alt="" />
        </div>
        <p className="refund_para">No-questions-asked refund policy</p>
      </div>

      <p className="revamp_exp">
        Before you decide to sign-up, experience the Revamp24 Method for
        yourself in a FREE trial class.
      </p>
      <div className="button_container_price">
        <TrailBtn type="btn_trail_why_revamp" btnID="Button_book_a_free_trial" link="book-a-free-trial" children="Book a free trail" />
      </div>
    </div>
  );
}
