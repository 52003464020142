import React from 'react'
import './Footer.scss'
export default function Footer() {
    return (
        <div className="footer-kcet">
            <ul className="links">
                <li onClick={()=>window.open("https://www.revamp24.in/terms-and-conditions")}>Terms & Condition</li>
                <li>|</li>
                <li onClick={()=>window.open("https://www.revamp24.in/privacy-policy")}>Privacy Policy</li>
                <li>|</li>
                <li onClick={()=>window.open("https://www.revamp24.in/return-and-cancellation")}>Return, Refund & Cancellation</li>
                <li>|</li>
                <li onClick={()=>window.open("https://www.revamp24.in/disclaimer")}>Disclaimer</li>
            </ul>
            <p>&copy; 2021, REVAMP24. All rights reserved.</p>
        </div>
    )
}
