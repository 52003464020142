import React from 'react'
import './AboutLanding.scss'

function AboutLanding() {
    return (
        <div className="about_landing">
            <div className="text_content">
                <p>
                    <span>OUR MISSION</span><br/>
                    Our mission is to provide high quality online and offline tutoring services, using state of the art Internet technology, to school students worldwide.
                </p>
            </div>
            <div className="image_content">
                <div className="image-wrapper">
                    <img src="/images/about page/landing_main.jpg" alt="" className="main"/>
                    <img src="/images/about page/lt.jpg" alt="" className="left_top"/>
                    <img src="/images/about page/lb.jpg" alt="" className="left_bottom"/>
                    <img src="/images/about page/rt.jpg" alt="" className="right_top"/>
                    <img src="/images/about page/rb.jpg" alt="" className="right_bottom"/> 
                </div>
                {/* 
                */}
            </div>
        </div>
    )
}

export default AboutLanding
