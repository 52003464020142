import React from 'react'
import './HomeTutionChooseCard.scss'

export default function HomeTutionChooseCard({icon, heading, description}) {
    return (
        <div className="home-tution-choose-card">
            <img src={icon} alt=""/>
            <h6>{heading}</h6>
            <p>{description}</p>
        </div>
    )
}
