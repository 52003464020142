import React from 'react'
import { useParams } from 'react-router-dom'

export default function ClassSchedulePdf() {
    const {id} = useParams();
    return (
        <div className="container" style={{width:'100%',height:'100vh'}}>
            <iframe src={"/PDF/"+id+".pdf"} title="class-schedule" frameborder="0" width="100%" height="100%"></iframe>
        </div>
    )
}
