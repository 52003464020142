import React from 'react'
import './ClassRoomBox.scss'

export default function ClassRoomBox({image,description,heading}) {
    return (
        <div className="classroom-box">
            <img src={image} alt=""/>
            <h6>{heading}</h6>
            <p>{description}</p>
        </div>
    )
}
