import React from "react";
import "./WorkPrinc.scss";

export default function WorkPrinc(props) {
  return (
    <div className="work_princ">
      <div className={"line_img " + props.color}>
        <img src={props.princimg} alt="" />
      </div>
      <p>{props.princword}</p>
    </div>
  );
}
