import React from 'react'
import './GlanceCard.scss'


export const GlanceCard = () => {
    return (
        <>
            <h3 className="revamp-at-glance-head">REVAMP NOW</h3>
            <div className='glanceCard_container'>
                <div className="glacecard_section">
                    <div className="glance_card glace_blue">
                        <h1>80k+</h1>
                        <p>Students</p>
                    </div>
                    <div className="glance_card glace_orange">
                        <h1>60k+</h1>
                        <p>Tutors</p>
                    </div>
                </div>
                <div className="glacecard_section">
                    <div className="glance_card glace_blue">
                        <h1>10+</h1>
                        <p>Countries</p>
                    </div>
                    <div className="glance_card glace_orange">
                        <h1>400k+</h1>
                        <p>Hours of education</p>
                    </div>
                </div>
            </div>
        </>
    )
}
