import React from 'react'
import './Footer.scss'
import InfoLink from './infoLink/InfoLink'
import { FaFacebookF } from 'react-icons/fa'
import { AiFillInstagram,AiFillLinkedin,AiFillYoutube,AiOutlineTwitter} from 'react-icons/ai'

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer_description">
                <h1>Revamp24</h1>
                <p className="revamp_description"> 
                    Founded in 2018, Revamp24 is a continuously growing e-learning platform
                    that provides LIVE face-to-face online tuitions for CBSE, ICSE and state board
                    students of grades 8 to 12. With top 1% teachers from the country, our team is focused
                    on delivering quality education for children of new age parents.
                </p>
            </div>
            <div className="footer_info">
                <h1 className="info_head">Info</h1>
                <ul className="footer_info_list">
                    <li>
                        <InfoLink link="/terms-and-conditions" children="Terms & Conditions " />
                    </li>
                    <li>
                        <InfoLink link="/privacy-policy" children="Privacy Policy" />
                    </li>
                    <li>
                        <InfoLink link="/return-and-cancellation" children="Return, Refund & Cancellation" />
                    </li>
                    <li>
                        <InfoLink link="/disclaimer" children="Disclaimer" />
                    </li>
                </ul>
            </div>
            <div className="footer_contact">
                <h1 className="contact_head">Contact us</h1>
                <ul className="footer_contact_list">
                    <li>
                        <img src="/icons/telephone.png" alt=""/>
                        <p><a href="tel:+916366440333">+916366440333</a></p>
                    </li>
                    <li>
                        <img src="/icons/email.png" alt=""/>
                        <p><a href="mailto:info@revamp24.in">info@revamp24.in</a></p>
                    </li>
                    <li>
                        <img src="/icons/pin.png" alt=""/>
                        <p>Revamp24 Hustle hub, 3rd floor Ravnnas building, 27th Garden layout Rd, HSR Layout Bengaluru Karnataka 560102.</p>
                    </li>
                </ul>
                <ul className="footer_social_icons">
                    <li>
                        <a href="https://www.facebook.com/revamp24privatetuitions/"><FaFacebookF className="social_icon"/></a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/revamp24edu/"><AiFillInstagram className="social_icon"/></a>
                    </li>
                    <li>
                        <a href="https://www.linkedin.com/checkpoint/challengesV2/AQGxdlRyLktBdQAAAXrj0D6djQr2AWwkyMgvwV96fDDwo6eDcKyim0-xM7YTv28ACUHleZBpdcEIgtc6bWrEnPZti5gyza91iw?original_referer=https%3A%2F%2Fwww.revamp24.in%2F"><AiFillLinkedin className="social_icon"/></a>
                    </li>
                    <li>
                        <a href="https://www.youtube.com/channel/UCQb9wfwTSgOVorVFSHZDEQg"><AiFillYoutube className="social_icon"/></a>
                    </li>
                    <li>
                        <a href="https://twitter.com/revamp24edu"><AiOutlineTwitter className="social_icon"/></a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Footer
