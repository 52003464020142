import React from 'react'
import { Link } from 'react-router-dom'

export default function BtnSelectClass({ children, type }) {
    return (
        <>
            {
                type === "class" ?
                    <Link className="btn-select-class" to={"/course/" + children}>
                        {children}
                    </Link>
                    :
                    <Link className="btn-select-class" to={"/compititive-exam/" + children}>
                        {children}
                    </Link>
            }
        </>

    )
}
