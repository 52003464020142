import React from 'react'
import './RevampWorking.scss'
import WorkingCard from './WorkingCard/WorkingCard'
export default function RevampWorking() {
    return (
       <div className="revamp_container">
           <h1>How Revamp24 Work's</h1>
        <div className="revamp_main">

          <WorkingCard
          image="/images/work1.png"
          para="Tell is what you need help with and our smart matching system will connect you with an online tutor"
          workClass="content_image"
          />
           <WorkingCard
          image="/images/work2.png"
          para="Get 1-on-1 help in our advanced lesson space.Use audio/videochat,screensharing text editor and much more"
          workClass="content_image"
         />

<WorkingCard
          image="/images/work3.png"
          para="After the lesson is completed,both the tutor and the student have the oppurtunity to rate each other, maintaining quality of our community"
        workClass="content_image3"
        />
           
        </div>
       </div>
    )
}
