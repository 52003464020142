import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./SchedulePage.scss";
import WorkPrinc from "./WorkPrinc/WorkPrinc";
import SelectDateButton from "./SelectDateButton/SelectDateButton";
import SelectSubjectButton from "./SelectSubjectButton/SelectSubjectButton";
import SelectTime from "./SelectTime/SelectTime";
import { useSelector } from "react-redux";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { Helmet } from "react-helmet";

const d = [];
const SchedulePage = () => {
  const [date, setDate] = useState([]);
  const [subjectError, setSubjectError] = useState(false);
  const [dateError, setDateError] = useState(false);
  const [timeError, setTimeError] = useState(false);
  const history = useHistory();

  const {
    REACT_APP_CLIENT_EMAIL,
    REACT_APP_PRIVATE_KEY,
    REACT_APP_WEBDATA_SPREADSHEET_ID,
    REACT_APP_WEBDATA_SPREADSHEET_ID_DUPLICATE,
  } = process.env;

  const essentials = useSelector((state) => state.essential.essential);

  const doc = new GoogleSpreadsheet(REACT_APP_WEBDATA_SPREADSHEET_ID);
  const docDuplicate = new GoogleSpreadsheet(
    REACT_APP_WEBDATA_SPREADSHEET_ID_DUPLICATE
  );

  // Main sheet row adding function
  const appendSpreadsheet = async (row) => {
    try {
      await doc.useServiceAccountAuth({
        client_email: REACT_APP_CLIENT_EMAIL,
        private_key: REACT_APP_PRIVATE_KEY,
      });
      await doc.loadInfo();

      const sheet = doc.sheetsById["0"];
      const result = await sheet.addRow(row);
      return result;
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  // Duplicate sheet row adding function
  const appendSpreadsheetDuplicate = async (row) => {
    try {
      await docDuplicate.useServiceAccountAuth({
        client_email: REACT_APP_CLIENT_EMAIL,
        private_key: REACT_APP_PRIVATE_KEY,
      });
      await docDuplicate.loadInfo();

      const sheet = docDuplicate.sheetsById["0"];
      const result = await sheet.addRow(row);
      return result;
    } catch (e) {
      console.error("Error: ", e);
    }
  };

  useEffect(
    () => {
      const dateSet = async () => {
        if (!essentials.hasOwnProperty("parentPhone")) {
          console.log(essentials);
          history.push("/booking");
        }
        dateAdder(1);
        dateAdder(2);
        dateAdder(3);
      };
      dateSet();
    },
    [
      /*essentials.timeAvailibility*/
    ]
  );

  const dateAdder = (inc) => {
    return new Promise((resolve, reject) => {
      var today = new Date();
      today.setDate(today.getDate() + inc);
      var newDate = {
        weekName: today.toString().split(" ")[0],
        month: today.toString().split(" ")[1],
        day: today.toString().split(" ")[2],
        id: inc,
      };
      switch (newDate.weekName) {
        case "Mon":
          newDate.weekName = "MONDAY";
          break;
        case "Tue":
          newDate.weekName = "TUESDAY";
          break;
        case "Wed":
          newDate.weekName = "WEDNESDAY";
          break;
        case "Thu":
          newDate.weekName = "THURSDAY";
          break;
        case "Fri":
          newDate.weekName = "FRIDAY";
          break;
        case "Sat":
          newDate.weekName = "SATURDAY";
          break;
        case "Sun":
          newDate.weekName = "SUNDAY";
          break;
        default:
          break;
      }
      d.push(newDate);
      setDate(d);
      resolve(newDate);
    });
  };

  const handleBooking = (event) => {
    event.preventDefault();
    if (!essentials.hasOwnProperty("subject")) {
      console.log(essentials, "here its");
      setSubjectError(true);
      setDateError(false);
      setTimeError(false);
    } else if (!essentials.hasOwnProperty("date")) {
      setTimeError(false);
      setSubjectError(false);
      setDateError(true);
    } else if (!essentials.hasOwnProperty("time")) {
      setTimeError(true);
      setSubjectError(false);
      setDateError(false);
    } else {
      appendSpreadsheet(essentials);
      appendSpreadsheetDuplicate(essentials);
      submitToLeadsquare(essentials)
      history.push("/thanks");
    }
  };

  const Attributes = [
    "mx_Parent_Name",
    "EmailAddress",
    "Phone",
    "mx_Child_Name",
    "mx_Board",
    "mx_Class",
    "mx_Have_Laptop",
    "mx_Demo_Class_Subject",

]

const extra = {
    "Attribute": "Source",
    "Value":"Direct Traffic",
    
}

// const extraplus = {
//   "Attribute": "Notes",
//   "value":`time ${essentials?.time}`
//   // "value":`time:${essentials?.time} date:${essentials?.date}`
// }

const submitToLeadsquare = (data) => {
    console.log(data);
    let Attribute = Attributes
    let keys = Object.keys(data)
    let newData = []
    for(let i=0;i<keys.length-2;i++){
        let key = keys[i]
        let att = Attribute[i]
        let obj = {
            "Attribute": att,
            "Value": essentials[key]
        }

        newData.push(obj);
    }
    newData.push(extra)
    // newData.push(extraplus)

    console.log(newData);
    fetch(`https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$rd69b0fe442bd5b19652e003f57e67e88&secretKey=374b41196f0daadc114e42b74925541df1e03776`,{
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        body: JSON.stringify(newData)
    }).then(res => {
        console.log(res);
    })
}


  return (
    <>
    <Helmet >
      <title>Revamp24 | Schedule a Free class</title>
    </Helmet>
      <div className="schedule-container">
        {/* {loading && <Loader />} */}
        <div className="schedule-sub-container">
          <Link to="#" className="logo-schedule">
            <img src="/images/campaign/logo/logo.png" alt="" />
            {window.innerWidth > 550 && <p>"No student is left behind"</p>}
          </Link>
          <div className="circle-top">
            <h1>Schedule your FREE Class</h1>
            <p>HURRY ! Only 16 slots left</p>
          </div>
          <div className="select_subject_container">
            <h6>
              Select Subject *{" "}
              {subjectError && <span> This field is madidatory</span>}
            </h6>
            <div className="select_subject">
              <div className="sub_set1">
                <SelectSubjectButton
                  subject="MATHS"
                  subjectImage="/images/campaign/icons/MATHSBLACK.png"
                  subjectImageWhite="/icons/MATHSWHITE.png"
                  _id="m"
                />
                <SelectSubjectButton
                  subject="PHYSICS"
                  subjectImage="/images/campaign/icons/PHYSICS-BLACK.png"
                  subjectImageWhite="/icons/PHYSICS-WHITE.png"
                  _id="p"
                />
              </div>
              <div className="sub_set2">
                <SelectSubjectButton
                  subject="CHEMISTRY"
                  subjectImage="/images/campaign/icons/CHEMISTRY-BLACK.png"
                  subjectImageWhite="/icons/CHEMISTRY-WHITE.png"
                  _id="c"
                />
                <SelectSubjectButton
                  subject="BIOLOGY"
                  subjectImage="/images/campaign/icons/BIOLOGY-BLACK.png"
                  subjectImageWhite="/icons/BIOLOGY-WHITE.png"
                  _id="b"
                />
              </div>
            </div>
          </div>

          <div className="select-date-container">
            <h6>
              Select a Date *{" "}
              {dateError && <span> This field is madidatory</span>}
            </h6>
            <div className="date-btns">
              {date[2] ? (
                <>
                  <SelectDateButton
                    day={date[0].day}
                    week={date[0].weekName}
                    month={date[0].month}
                    _id={date[0].id}
                    dayNum="dayOne"
                  />
                  <SelectDateButton
                    day={date[1].day}
                    week={date[1].weekName}
                    month={date[1].month}
                    _id={date[1].id}
                    dayNum="dayTwo"
                  />
                  <SelectDateButton
                    day={date[2].day}
                    week={date[2].weekName}
                    month={date[2].month}
                    _id={date[2].id}
                    dayNum="dayThree"
                  />
                </>
              ) : null}
            </div>
          </div>
          <div className="time_container">
            <h6>
              Select a Time(IST) Slot *
              {timeError && <span> This field is madidatory</span>}
            </h6>
            <div className="set1">
              <SelectTime
                // disable={isTwelve ? "enable" : "disable"}
                time="12 :00 PM"
                _id="twelve"
              />
              <SelectTime
                // disable={isOne ? "enable" : "disable"}/
                time="01 :00 PM"
                _id="one"
              />
              <SelectTime
                // disable={isTwo ? "enable" : "disable"}
                time="02 :00 PM"
                _id="two"
              />
            </div>
            <div className="set2">
              <SelectTime
                // disable={isThree ? "enable" : "disable"}
                time="03 :00 PM"
                _id="three"
              />
              <SelectTime
                // disable={isFour ? "enable" : "disable"}
                time="04 :00 PM"
                _id="four"
              />
              <SelectTime
                // disable={isFive ? "enable" : "disable"}
                time="05 :00 PM"
                _id="five"
              />
            </div>
            <div className="set3">
              <SelectTime
                // disable={isSix ? "enable" : "disable"}
                time="06 :00 PM"
                _id="six"
              />
              <SelectTime
                // disable={isSeven ? "enable" : "disable"}
                time="07 :00 PM"
                _id="seven"
              />
              <SelectTime
                // disable={isEight ? "enable" : "disable"}
                time="08 :00 PM"
                _id="eight"
              />
            </div>
            <div className="free_button_container">
              <button id="Organic_Schedule_Button" className="free_but" onClick={handleBooking}>
                BOOK A FREE CLASS
              </button>
            </div>
          </div>

          <div className="work_princ_container">
            <h3 className="how">How it Works</h3>
            <div className="align">
              <div className="line"></div>
              <WorkPrinc
                princimg="/images/campaign/icons/mail.svg"
                princword="After booking the class you will receive the class link by SMS/WhatsApp"
                color="mailcolor"
              />

              <WorkPrinc
                princimg="/images/campaign/icons/live.svg"
                princword="Trial class will be Live one-one for 60 minutes"
                color="livecolor"
              />
              <WorkPrinc
                princimg="/images/campaign/icons/online-class.svg"
                princword="Join the class on time and learn from our top teachers"
                color="classcolor"
              />
              <div className="girl_name">
                <WorkPrinc
                  princimg="/images/campaign/icons/question.png"
                  princword="Get all your doubts clarified instantly during the class"
                  color="questioncolor"
                />
              </div>
              <WorkPrinc
                princimg="/images/campaign/icons/homework.png"
                princword="Revise your concepts after class through the homework that will be provided"
                color="homeworkcolor"
              />
              <div className="teacher">
                <WorkPrinc
                  princimg="/images/campaign/icons/teacher.svg"
                  princword="Get proficiency report discussed with parent"
                  color="teachercolor"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SchedulePage;
