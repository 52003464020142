import React from 'react'
import Footer from '../Footer/Footer'

function TermAndCondition() {
    return (
        <div className="info_links_contents">
            <div className="info_content_container">
                <div className="logo">
                    <img src="/icons/logo.png" className="logo_image" alt="" />
                </div>
                <h4>Terms and Conditions</h4>
                <p>
                    The following terms and conditions, along with all other terms and legal notices located on
                    www.revamp24.in (collectively, “Terms”), govern your use of www.revamp24.in (the “Website”). If you
                    do not understand and/ or agree to be bound by all Terms, do not use this Website. Your use of this
                    Website anytime constitutes a binding agreement by you to abide by these Terms and Conditions.
                    <br />
                    <br />
                    Registration/ Sign Up
                    <br />
                    <br />
                    Upon registration, you agree to:
                    <br />
                </p>
                <ol>
                    <li>
                        Make your contact details (including but not limited to mobile number and email) available to
                        Revamp24 edu pvt ltd also referred to as “Company”, you may be contacted by Revamp24
                        edu pvt ltd regarding our offerings, products, promotions, new introductions and any other
                        relevant information through email, phone, SMS, Social Media, Social Messaging or any
                        other means of communication.
                    </li>
                    <li>
                        Receive promotional emails/SMS/ or any other special offers from Revamp24 or any of its
                        partner websites.
                    </li>
                    <li>
                        Be contacted by the Company in accordance with the privacy settings set by you:
                    </li>
                </ol>
                <p>
                    Restrictions Regarding Materials and other information
                </p>
                <ol>
                    <li>
                        All information, documents, software, images, photographs, text, services, study notes, question
                        banks, sample papers and other similar materials (collectively, “Materials”) contained in this Website
                        are provided by ‘ Revamp24 edu pvt ltd’ or its third party manufacturers, authors, developers and
                        vendors (“Third Party Providers”) and are the copyrighted work of ‘ Revamp24 edu pvt ltd’ and/or the
                        respective Third Party Providers. Except for:
                        <br />
                        <br />
                        a)
                        the videos provided as a part of Studyzone, which are Third Part videos available for FREE
                        on open source video sharing sites like Youtube and other education and non-education websites
                        <br />
                        b)
                        NCERT ebooks which are published by NCERT, New Delhi. Revamp24 has permission to
                        host links of NCERT ebooks and direct the user to the NCERT page upon clicking the links. The
                        Company is not involved in selling NCERT ebooks directly and disclaims any responsibility for any of
                        its users selling NCERT books through any means
                    </li>
                    <li>
                        The videos and NCERT ebooks are available as an additional FREE offering along with our other
                        content. The Company doesn’t charge explicitly for any NCERT ebooks or the Videos
                    </li>
                    <li>
                        The amount that a user pays for a paid subscription is towards the Study Material, Sample
                        Papers, NABUmania – our testing platform, Customized Test Generator, Personalized Workbooks,
                        Benchmark Test and using Analytics Platform which is available through the Dashboard
                    </li>
                    <li>
                        Except as stated herein, none of the Materials may be copied, reproduced, distributed,
                        republished, downloaded, displayed, posted or transmitted in any form or by any means, including,
                        but not limited to, electronic, mechanical, photocopying, recording, or otherwise, without the prior
                        express written permission of ‘ Revamp24 edu pvt ltd’ or the Third Party Provider. No part of the
                        Website, including logos, graphics, sounds or images, may be reproduced or retransmitted in any
                        way, or by any means, without the prior express written permission of ‘ Revamp24 edu pvt ltd’. You
                        also may not, without Revamp24 edu pvt ltd’s prior express written permission, “mirror” any Materials
                        contained on this Website on any other server, website or any other location.
                    </li>
                    <li>
                        Nothing on this Website shall be construed as conferring any license under any of Revamp24 edu
                        pvt ltd or any Third Party Provider’s intellectual property rights, whether by estoppels, implication, or
                        otherwise. You acknowledge sole responsibility for obtaining any such licenses.
                    </li>
                    <li>
                        Permission is granted to display, copy, distribute and download Revamp24 edu pvt ltd’s Materials
                        on this Website provided that: (1) both the copyright notice identified below and this permission
                        notice appear in the Materials, (2) the use of such Materials is solely for personal, non commercial
                        and informational use and will not be copied or posted on any networked computer or broadcast in
                        any media, and (3) no modifications of any of the Materials are made. This permission terminates
                        automatically without notice if you breach any of these terms or conditions. Upon termination, you
                        will immediately destroy any downloaded or printed Materials. Any unauthorized use of any Materials
                        contained on this Website may violate copyright laws, trademark laws, and other related laws and
                        may have various legal consequences.
                    </li>
                    <li>
                        Materials provided by Third Party Providers have not been independently authenticated in whole
                        or in part by ‘ Revamp24 edu pvt ltd’. www.Revamp24 does not provide, sell, license, or lease any
                        of the Materials other than those specifically identified as being provided by ‘ Revamp24 edu pvt ltd’.
                        ‘ Revamp24 edu pvt ltd’ is committed to respecting others’ intellectual property rights, and we ask
                        our users to do the same. ‘Revamp24 edu pvt ltd’ may, in its sole discretion, terminate the access
                        rights of users who infringe or otherwise violate others’ intellectual property rights.
                    </li>
                    <li>
                        Revamp24 edu pvt ltd puts in special care for verifying the credentials of the tutors listed on the
                        Tutor Search Platform. However, the Company directly disclaims the responsibility of any wrong
                        information, misbehaviour, fraud, disrespect, harassment (sexual or otherwise) or any other
                        untoward incident by the tutors listed on www.Revamp24 .
                    </li>
                    <li>
                        For Tutors:
                        <ol>
                            <li>
                                Registering or signing up as a tutor on our platform is completely FREE.
                            </li>
                            <li>
                                The Company holds the right to ask for all relevant information from the tutors for verification
                                process
                            </li>
                            <li>
                                The Company may get the profiles of the registered and listed tutors verified by third party
                                agencies for security purpose
                            </li>
                            <li>
                                All the payments for tutor connect will be directed through the website and the Company will
                                directly deal with students/parents in collection of payments.
                            </li>
                            <li>
                                Tutors in no way are allowed to collect any payment on behalf of the company, until and
                                unless directed to do so by the Company under special circumstances
                            </li>
                            <li>
                                The Company will deduct a suitable proportion of the fees paid by the student(s)/parents, for
                                connecting with the tutor, before making the final payment to the tutor. This proportion will be
                                conveyed to the tutor and may vary on a case-to-case basis on sole discretion of the
                                Company
                            </li>
                            <li>
                                Any tutor violating these terms will be liable for legal action
                            </li>
                        </ol>
                    </li>
                </ol>

                <h4>DISCLAIMER OF ONLINE AVAILABILITY, IMPRESSIONS, AND CLICK-THROUGHS:</h4>
                <p>
                    In addition to the other disclaimers and limitations discussed in this notice, there are no guarantees
                    and no warranties regarding online availability, impressions, and click-through of www.Revamp24 ,
                    its web pages, and any material, information, links, or content presented on the web pages at
                    www.Revamp24 . Revamp24 , its web pages, and any material, information, links, or content
                    presented on the web pages at www.Revamp24 , may be unavailable for online access at anytime.
                    Advertising sponsors and advertising must be approved by ‘Revamp24 edu pvt ltd’ before the
                    posting of any advertising material, information, links, content, banners, and graphics on
                    www.Revamp24 . Any advertising should be related to interactive digital television and related
                    subject areas. ‘Revamp24 edu pvt ltd’ reserves the right to accept or to reject any advertising
                    sponsor or any advertising for any reason.
                </p>
                <h4>LIMITATION OF LIABILITY:</h4>
                <p>
                    In no event and under no circumstances and under no legal theory, tort, contract, or otherwise shall
                    ‘Revamp24 edu pvt ltd’ be liable, without limitation, for any damages whatsoever, including direct,
                    indirect, incidental, consequential or punitive damages, arising out of any access to or any use of orany inability to access or use this website including any material, information, links, and content
                    accessed through this website or through any linked external website.
                </p>
                <h4>LOCAL LAWS</h4>
                <p>
                    ‘Revamp24 edu pvt ltd’ controls and operates this Website from its headquarters in Bangalore
                    Karnataka, India and makes no representation that the materials on the website are appropriate or
                    available for use in other locations. If you use this Website from other locations, you are responsible
                    for compliance with applicable local laws including but not limited to the export and import
                    regulations of other countries. Unless otherwise explicitly stated, all marketing or promotional
                    materials found on this Website are solely directed to individuals, companies or other entities located
                    in India and comply with the laws prevailing for the time being in force in India. Disputes if any shall
                    be subject to the exclusive jurisdiction of Courts at Delhi.
                </p>
                <h4>GENERAL</h4>
                <ul>
                    <li>
                        This Website could include unintended inaccuracies or typographical errors.
                        ‘Revamp24 edu pvt ltd’ and the Third Party Providers may make improvements
                        and/or changes in the products, services, programs, and prices described in this
                        Website at any time without notice. Changes are periodically made to the
                        Website.
                    </li>
                    <li>
                        The material, information, links, and content presented on and by this website
                        are of a general nature only and are not intended to address the specific
                        circumstances, requirements, or any other needs of any particular individual or
                        entity. It cannot be guaranteed that the material, information, links, and content
                        presented on and by this website is comprehensive, complete, accurate,
                        sufficient, timely, or up to date for any particular purpose or use. The material,
                        information, links, and content presented on and by this website should not be
                        considered as professional, legal, business, financial, investment, or purchasing
                        advice (if you need specific advice, you should always consult a suitably qualifiedprofessional). This website is sometimes linked to external websites over which
                        ‘Revamp24 edu pvt ltd’ has no control and assumes no responsibility, and are in
                        no way acting as a publisher of material, information, links, and content
                        contained on external linked websites. Links may become invalid, may expire, or
                        may become misdirected at any time. Links are provided as a convenience and
                        do not necessarily constitute, signify, or otherwise imply an endorsement by, or
                        an endorsement for, or a relationship with, or connection to ‘Revamp24 edu pvt
                        ltd’. The statements expressed on external linked websites are not those of
                        ‘Revamp24 edu pvt ltd’; and users are advised that ‘Revamp24 edu pvt ltd’
                        neither maintains editorial control over externally linked websites nor determines
                        the appropriateness regarding the material, information, links, and content
                        contained on external linked websites. ‘Revamp24 edu pvt ltd’ has no control
                        over any external website or over any external material, information, links, and
                        content linked to www.Revamp24 .
                    </li>
                    <li>
                        The subjects and their coverage, tests and their results as also various tutorials
                        are to be used to assess one’s knowledge of concepts and as test papers and
                        should not be interpreted as a definitive score of one's ability. The tests and
                        exercises are created for you to test your understanding of concepts and are not
                        to be interpreted as a sample question paper for any examinations.
                    </li>
                    <li>
                        The Website, and your use of the Website will be governed in all respects by the
                        laws of India.
                    </li>
                    <li>
                        These Terms represent the entire understanding relating to the use of the
                        Website and prevail over any prior or contemporaneous, conflicting, additional, orother communications. ‘Revamp24 edu pvt ltd’ can modify these Terms at any
                        time without notice or consent by updating this posting.
                    </li>
                    <li>
                        The company may come up with reward points and gratification schemes for its
                        users. The company reserves the right to change or update any such programs
                        without prior notice and any company’s decision in this regard will be final and
                        binding.
                    </li>
                    <li>
                        In the event any or a portion of the provisions of these Terms are held invalid,
                        illegal or otherwise unenforceable by a Court, such provision shall be deemed
                        amended to be valid, legal and enforceable to the fullest extent permitted under
                        applicable law, and the remaining provisions of these Terms shall remain in full
                        force and effect. These Terms are binding upon you and your heirs,
                        representatives, successors and assigns. The headings of the several articles
                        and subdivisions of these Terms are inserted solely for the convenience of
                        reference and shall have no further meaning, force or effect. No third party shall
                        be a beneficiary of any provision of these Terms, except with the express written
                        consent of ‘Revamp24 edu pvt ltd’.
                    </li>
                </ul>
                <h4>LICENSE DISCLAIMER</h4>
                <p>
                    Nothing on any Revamp24 edu pvt ltd website shall be construed as conferring any license under
                    any of Revamp24 edu pvt ltds or any third party’s intellectual property rights, whether by estoppel,
                    implication, or otherwise.
                </p>
                <h4>CONTENT AND LIABILITY DISCLAIMER</h4>
                <p>
                    Revamp24 edu pvt ltd shall not be responsible for any errors or omissions contained on any
                    Revamp24 edu pvt ltd website, and reserves the right to make changes anytime without notice.
                    Mention of non-Revamp24 edu pvt ltd products or services is provided for informational purposes
                    only and constitutes neither an endorsement nor a recommendation by Revamp24 edu pvt ltd AllRevamp24 edu pvt ltd and third-party information provided on any Revamp24 edu pvt ltd website is
                    provided on an “as is” basis.
                    Views expressed by the users are their own, Revamp24 edu pvt ltd does not endorse the same. No
                    claim as to the accuracy and correctness of the information on the site is made although every
                    attempt is made to ensure that the content is not misleading. In case any inaccuracy is or otherwise
                    improper content is sighted on the website, please report it to report abuse
                    ‘Revamp24 edu pvt ltd’ DISCLAIMS ALL WARRANTIES, EXPRESSED OR IMPLIED, WITH
                    REGARDS TO ANY INFORMATION (INCLUDING ANY SOFTWARE, PRODUCTS, OR SERVICES)
                    PROVIDED ON ANY ‘Revamp24 edu pvt ltd’ WEBSITE, INCLUDING THE IMPLIED WARRANTIES
                    OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND
                    NON-INFRINGEMENT.
                </p>
                <h4>PURCHASING AND ORDERING DISCLAIMER</h4>
                <h4>Make your own decisions:</h4>
                <p>
                    If you are making important purchasing or planning decisions, whether personal or business
                    decisions, you should look at an actual demonstration model of any product you are considering
                    before making your important purchasing or planning decisions. (Contact a supplier, store, or
                    manufacturer regarding looking at any demonstration units.) All decisions made would be entirely
                    your prerogative and Revamp24 edu pvt ltd does not claim to offer any advice, either legal or
                    financial.
                    In purchasing a product you understand and agree to the terms below. If you do not understand and
                    agree with these terms then do not place an order for a product.
                </p>
                <h4>PRICING</h4>
                <p>
                    All prices are subject to change without notice. Every effort has been made to ensure accurate
                    pricing of the products featured on our website. In the event a part or accessory is ordered and the
                    listed price has changed, you will be notified prior to our processing your order. Purchase “as is.” you
                    agree that all products purchased by you through this website are provided under warranties, if any,
                    of the manufacturer only, and not by Revamp24 edu pvt ltd all products are provided by Revamp24
                    edu pvt ltd on “as is” basis with no representations or warranties of any kind from Revamp24 edu pvt
                    ltd
                </p>
                <h4>YOUR RESPONSIBILITY</h4>
                <p>
                    You have sole responsibility for use of the products/services you purchase through this website. In
                    no event shall Revamp24 be liable to you in relation to the products/services, or your use, misuse or
                    inability to use the products, for any
                </p>
                <ul>
                    <li>
                        indirect, punitive, special, exemplary, incidental, or consequential damage
                        (including loss of business, revenue, profits, use, data or other economic
                        advantage); or
                    </li>
                    <li>
                        direct damages in excess of the amount you paid Revamp24 for the applicable
                        product.
                    </li>
                </ul>
                <h4>YOUR REPRESENTATIONS</h4>
                <p>
                    You represent that you are not under any legal or other disability which limits your ability to comply
                    with these Terms or to install and use the products you purchase with minimal risk of harm to you or
                    others. You further represent that you are not purchasing the products/services for resale to others
                    and will not do so without Revamp24 edu pvt ltd’s prior written consent.
                </p>
                <h4>USE OF WEBSITE</h4>
                <p>You represent, warrant and covenant that your use of the Website shall not:</p>
                <ul>
                    <li>
                        violate any applicable local, provincial, state, national or international law, statute,
                        ordinance, rule or regulation;
                    </li>
                    <li>
                        interfere with or disrupt computer networks connected to the Website;
                    </li>
                    <li>
                        impersonate any other person or entity, or make any misrepresentation as to your
                        employment by or affiliation with any other person or entity;
                    </li>
                    <li>
                        forge headers or in any manner manipulate identifiers in order to disguise the
                        origin of any user information;
                    </li>
                    <li>
                        upload, post, transmit, publish, or distribute any material or information for which
                        you do not have all necessary rights and licenses;
                    </li>
                    <li>
                        upload, post, transmit, publish or distribute any material which infringes, violates,
                        breaches or otherwise contravenes the rights of any third party, including any
                        copyright, trademark, patent, rights of privacy or publicity or any other proprietary
                        right;
                    </li>
                    <li>
                        interfere with or disrupt the use of the Website by any other user, nor “stalk”,
                        threaten, or in any manner harass another user;
                    </li>
                    <li>
                        upload, post, transmit, publish, or distribute any material or information which
                        contains a computer virus, or other code, files or programs intending in any
                        manner to disrupt or interfere with the functioning of the Website, or that of other
                        computer systems;
                    </li>
                    <li>
                        use the Website in such a manner as to gain unauthorized entry or access to the
                        computer systems of others;
                    </li>
                    <li>
                        upload, post, transmit, publish or distribute any material or information which
                        constitutes or encourages conduct that would constitute a criminal offence, give
                        rise to other liability, or otherwise violate applicable law;
                    </li>
                    <li>
                        upload, post, transmit, publish, or distribute any material or information that is
                        unlawful, or which may potentially be perceived as being harmful, threatening,
                        abusive, harassing, defamatory, libellous, vulgar, obscene, or racially, ethnically,
                        or otherwise objectionable;
                    </li>
                    <li>
                        reproduce, copy, modify, sell, store, distribute or otherwise exploit for any
                        commercial purposes the Website, or any component thereof (including, but not
                        limited to any materials or information accessible through the Website);
                    </li>
                    <li>
                        use any device, software or routine to interfere or attempt to interfere with the
                        proper working of the Website;
                    </li>
                    <li>
                        take any action that imposes an unreasonable or disproportionately large load on
                        the Website infrastructure;
                    </li>
                    <li>
                        any interpretation of test results or study material other than as a tool of learning
                        and enhancing knowledge; or
                    </li>
                    <li>
                        the tests and exercises are created for you to test your understanding of
                        concepts and are not to be interpreted as a sample question paper for any
                        examinations.
                    </li>
                </ul>
                <p>
                    Use of this Website is at your own risk, and Revamp24 edu pvt ltd will not be held liable for any
                    errors or omissions contained in this Website. In no event, shall Revamp24 edu pvt ltd be liable for
                    any special, indirect or consequential damages, or any damages whatsoever resulting from loss of
                    use, data or profits whether contract, negligence or any tort action arising out of, or in connection
                    with, the use or performance of the information available from www.Revamp24.in
                    <br />
                    <br />
                    Parents/ Students/ Tutors hereby authorize and give consent to Revamp24 edu pvt ltd to send,
                    either through itself or through any third party service provider, from time to time various information/
                    alerts/ SMS/ other messages or calls or commercial communication, other services on the registered
                    telephone numbers, whether these numbers are registered with National Do Not Call Registry/ listed
                    in National Customer Preference Register or not . They also confirm that by sending any of such
                    messages/ calls, they will not hold ASBL its third party service provider liable/institute complaint
                    under the Telecom Commercial Communications Customer Preference(TRAI) Regulations, 2010 or
                    such other applicable regulations including any amendment thereof, as may be applicable time to
                    time. It will be auto renewed every month & if they want to stop this service please write an email to
                    care@Revamp24.in
                </p>
                <h4>Revamp24 Tutoring Packages Terms & Conditions</h4>
                <p>
                    Revamp24 Students endeavours to provide its students and parents with the best possible tutors
                    that help improve the child’s learning. In order to do so, we ensure that you are matched with the
                    right tutor. Below are the terms and conditions governing the purchase of the tuition packages.
                </p>
                <h4 className="underline">General expectations and T&C</h4>
                <ol>
                    <li>
                        The chosen package should be fully paid before the Start of Connect.
                    </li>
                    <li>
                        The package once chosen & paid for, cannot be revised. Parents can choose to buy
                        additional packages if and when required.
                    </li>
                    <li>
                        The packages are based on the number of hours of tutoring.
                    </li>
                    <li>
                        The utilization of hours tutored will be based on attendance marked by the Tutor. Parents will
                        receive notification for each tutoring session.Once a package is exhausted, parents can
                        renew the package to continue uninterrupted tutoring. The rates available at the time of
                        renewal will be applicable.
                    </li>
                    <li>
                        Schedule once fixed shall not be altered.
                    </li>
                    <li>
                        Parents must ensure that the student is appearing for the session on time and is attentive
                        during the entire session.
                    </li>
                    <li>
                        For seamless functioning of Online Classes, parents shall ensure that they have proper
                        functioning hardware as required.
                    </li>
                </ol>
                <h4>Validity of Packages for AY 2021-2022 (1st April 2021 – 31st March 2022)</h4>
                <p>
                    <span className="red">*</span>Note : All unused hours credit will expire at the end of current academic year i.e. on 31 Mar 2022
                    and will not be carried forward for the next academic year.
                </p>
                <h4 className="underline">Replacement Request</h4>
                <ol>
                    <li>
                        Failure of providing tutoring services by the assigned Revamp24 Tutor will rise a call for
                        replacement. Parents can request for replacement under genuine circumstances only.
                    </li>
                    <li>
                        Replacement of Tutor/s will be done within 5 – 7 business days. In some cases, the same
                        can be extended based on mutual discussion with the parents.
                    </li>
                    <li>
                        A free Demo will be provided before finalizing the replacement.
                    </li>
                    <li>
                        Explorer packages shall not qualify for Replacement
                    </li>
                </ol>
                <h4 className="underline">Refund Request</h4>
                <ol>
                    <li>
                        Parents can request for Refund only in case we are unable to provide a replacement. In case
                        of any changes made to the original query request, the same will not qualify for refund.
                    </li>
                    <li>
                        Non-adherence to the schedule may result in discontinuation of a connect. In such a case,
                        the unutilized hours will lapse and no refund will be issued.
                    </li>
                    <li>
                        In case of a refund, the utilized hours will be charged at the original rate (without discount).
                    </li>
                    <li>
                        A 25% of overall amount paid will be deducted from the refund amount on account of
                        statutory & other charges already paid on behalf of the parent.
                    </li>
                    <li>
                        Refund will be issued only for the unutilized hours available in the package. Upon approval
                        from the management, it will be processed within 28 – 30 business days via NEFT.
                    </li>
                    <li>
                        All disputes arising under this agreement shall be governed by Mumbai Jurisdiction.
                    </li>
                    <li>
                        In case of any dispute, the company’s decision will be final. Company reserves the rights to
                        modify these Terms and Conditions without prior notice.
                    </li>
                    <li>
                        No refund will be provided in the following circumstances:
                            <ol>
                            <li>
                                For Explorer packages
                                </li>
                            <li>
                                If the student has secured non satisfactory result due to poor exam performance
                                </li>
                            <li>
                                If the classes are discontinued due to non-availability of the student
                                </li>
                        </ol>
                    </li>
                </ol>
                <br/>
                <br/>
                <h4>For any queries or assistance please contact us through care@revamp24.in</h4>
            </div>
            <Footer/>
        </div>
    )
}

export default TermAndCondition
