import React from 'react'
import ClassRoomBox from '../ClassRoom Box/ClassRoomBox'
import './ClassRoomIcons.scss'

export default function ClassRoomIcons() {
    return (
        <div className="classroom-icons">
            <div className="icon-container">
                <ClassRoomBox 
                    heading="Live Classes"
                    image="/images/online-learning 1.png"
                    description="High quality Live streaming online classes offered to individuals or small groups."
                />
                <ClassRoomBox
                    heading="Home Tution"
                    image="/images/student 1.png"
                    description="One on One tuition at your home ensuring individual attention and
                    mentoring for the student"
                />
            </div>
            <div className="icon-container">
                <ClassRoomBox
                    heading="Center Tution"
                    image="/images/teaching 1.png"
                    description="We run a well equipped and easily accessible centre in the heart of
                    the city"
                />
                <ClassRoomBox
                    heading="Apartment Classes"
                    image="/images/class.png"
                    description="We do tie-ups with resident associations and apartments to offer
                    group classes for the families."
                />
            </div>
        </div>
    )
}
