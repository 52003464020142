import React from 'react'
import './Methodology.scss'
export default function Methodology({head,para,ClassName}) {
    return (
        <div className={ClassName}>
                <h5 className="methodology_head evaluation_head experiance_head learning_head ">
                    {head}
                </h5>
                <p className="methodology_para evaluation_para experiance_para learning_para" >{para}</p>
        </div>
    )
}
