import React from 'react'
import './HowRevamp.scss'
import TrailBtn from '../../TrailButton/TrailBtn'
import HowRevampCard from './HowRevampCard/HowRevampCard'
export default function HowRevamp({ headingInvisible }) {
    return (
        <div className="working_container">
            {headingInvisible ? null : <h4 class="work_title">How do Revamp24 classes work</h4>}
            <div className="card-container">
                <HowRevampCard
                    heading="60-90 mins 5+ times"
                    headImage="/images/ZOOM 2.png"
                    bullet="/images/orangesignal.png"
                    contents={[
                        "Class duration is 60-90 minutes",
                        "Class size is up to 1:6 (but 1:1 is also available for higher pricing)",
                        "Class frequency is 5+ times/week depending on grade & preference."
                    ]}
                />
                <HowRevampCard
                    heading="ZOOM platform"
                    headImage="/images/ZOOM 1.png"
                    bullet="/images/bluesignal.png"
                    contents={[
                        "Classes happen through ZOOM platform",
                        "This enables our tutors to deliver completely personalised instruction based on the Revamp24 Method."
                    ]}
                />
                <HowRevampCard
                    heading="Live 1:1 interaction"
                    headImage="/images/live 3.png"
                    bullet="/images/orangesignal.png"
                    contents={[
                        "Teacher-student interaction is always 1:1 (even in group classes).",
                        "This ensures that every student gets the exact help that they need, at the exact time when they need it."
                    ]}
                />
            </div>
        
            <div className="button_container_Zoom">
                <TrailBtn color="red-orange" btnID="button_book_a_free_trail" link="book-a-free-trial" type="btn_trail_why_revamp" children="Book a free trail" />
            </div>

        </div>
    )
}
