import React from 'react'
import './Exams.scss'
import Subjects from '../OnlineSubject/Subjects/Subjects'
export default function Exams() {
    return (
       <div className="exam_container">
           <h1>EXAMS</h1>
           <div className="exam_main">
           <Subjects
           subjectImage="/images/IIT .svg"
           subjectName="JEE-MAIN"      
           />
             <Subjects
           subjectImage="/images/NEET.svg"
           subjectName="NEET"      
           />
             <Subjects
           subjectImage="/images/pen.png"
           subjectName="KCET"      
           />
           </div>
           <div className="exam_main">
           <Subjects
           subjectImage="/images/aims.svg"
           subjectName="AIIMS"      
           />
             <Subjects
           subjectImage="/images/BITS.png"
           subjectName="BITS"      
           />
             <Subjects
           subjectImage="/images/sat.png"
           subjectName="SAT"   
           examImage="sat_image"   
           />
           </div>




       </div>
    )
}
