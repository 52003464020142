import React from "react";
import "./Rigrous.scss";
import TrailBtn from "../../TrailButton/TrailBtn";
import RigrousMain from "./RigrousMain/RigrousMain";

export default function Rigrous() {
  return (
    <div className="rigrous_container">
      <div className="rigrous_main">
        <div className="rigrous_main1">
          <RigrousMain
            img1="/images/teacher_rig.png"
            para1="Diligent teaching system"
            class="rigrous_head"
            classimg="rigrous_img0"
          />
          <RigrousMain
            img1="/images/riglive.png"
            para1="Interactive live classes"
            class="rigrous_head1"
            classimg="rigrous_img1"
          />
          <RigrousMain
            img1="/images/instructor_rig.png"
            para1="Resourceful teachers"
            class="rigrous_head2"
            classimg="rigrous_img"
          />
        </div>

        <div className="rigrous_main2">
          <RigrousMain
            img1="/images/exam_rig.png"
            para1="Everyday Assignments"
            class="rigrous_head3"
            classimg="rigrous_img2"
          />
          <RigrousMain
            img1="/images/exam2_rig.png"
            para1="Weekly and monthly follow up tests"
            class="rigrous_head4"
            classimg="rigrous_img3"
          />
          <RigrousMain
            img1="/images/chat_rig.png"
            para1="Parent Teacher Student Meet Ups"
            class="rigrous_head5"
            classimg="rigrous_img4"
          />
        </div>
      </div>
       
      <div className="button_container_rigrous">
                <TrailBtn color="red-orange" type="btn_trail_why_revamp" children="Book a free trail"
                btnID="Button_book_a_free_trail"/>
            </div>
    </div>
  );
}
