import React from 'react'
import HomeTutionChooseCard from './choose card/HomeTutionChooseCard'
import './HomeTutionChoose.scss'
export default function HomeTutionChoose() {
    return (
        <div className="home-tution-choose">
            <h2>Why choose us?</h2>
            <div className="choose-cards">
                <HomeTutionChooseCard
                    icon="/images/home tution/flexible.svg"
                    heading="Qualified Teachers"
                    description="Our teachers are experienced and well acquainted with the newest curriculum developments."
                />
                <HomeTutionChooseCard
                    icon="/images/home tution/HOME.svg"
                    heading="At Your Home"
                    description="The teaching takes place at the comforts of your home as per your prefered schedule."
                />
                <HomeTutionChooseCard
                    icon="/images/home tution/TUTOR.svg"
                    heading="Choose your tutor"
                    description="You can meet your teachers before finalizing your tutor. It helps to build an excellent rapport between the student and the tutor."
                />
                <HomeTutionChooseCard
                    icon="/images/home tution/flexible.svg"
                    heading="Flexible Tuition"
                    description="There is no advance payment, contract or commitment restrictions or extra teacher expenses."
                /><HomeTutionChooseCard
                    icon="/images/home tution/BENEFITS.svg"
                    heading="Academic Benefits"
                    description="We aim to realize our student's fullest learning potential and thus excel in their academics."
                />
                <HomeTutionChooseCard
                    icon="/images/home tution/SERVICE.svg"
                    heading="Personal Services"
                    description="We offer prompt customer service and guide you through any decision making regarding studies."
                />
            </div>
        </div>
    )
}
