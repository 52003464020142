import React from "react";
import TrailBtn from "../../TrailButton/TrailBtn";
import "./TeachMethod.scss";
export default function TeachMethod() {
  return (
    <div className="teaching_method_content">
      <div className="teaching_method">
        <p>
          We believe every student needs personal attention according to their
          unique <br />
          learning path for a fruitful outcome. We operate with a maximum of 6
          students in
          <br />a class
        </p>
      </div>

      <h4 className="believe">
        Revamp24 believes 1:6 classes have a two-fold advantage
      </h4>

      <div className="teaching_image">
        <img className="teachimage1" src="/images/1-1.png" alt="" />
        <p className="teach_method_para1">
          The small group enables one to one attention for the teachers to give
          immediate <br/>feedback and constructive solutions for each students
          specific problem areas leading to <br/>high academic performance and better
          understanding
        </p>
      </div>

      <div className="teaching_para">
        <img className="teachimage2" src="/images/p2p.png" alt="" />
        <p className="teach_method_para2">
          A group of six is ideal for interactive peer study without missing the
          opportunities for <br/> self-paced and self-directed learning. The students
          learn to study as a team with mutual<br/> encouragement and support.
        </p>
      </div>
      <div className="button_container_method">
        <TrailBtn
          color="red"
          type="btn_trail_why_revamp"
          children="Book a free trail"
          link="book-a-free-trial"
          btnID="Button_book_a_free_trail"
        />
        </div>
    </div>
  );
}
