import React from 'react'
import { TiTick } from 'react-icons/ti'
import './CardDetails.scss'
import { RiCloseFill } from 'react-icons/ri'
export default function CardDetails({ isActive }) {
    return (
        <div className={isActive ? "card-details card-details-active" : "card-details"}>
            <ul >
                <li>
                    <TiTick className="tick-or-cross" />
                    Complete coverage of class-12 topics-(PCM)
            </li>
                <li>
                    <TiTick className="tick-or-cross" />
                    Complete coverage of KCET/COMEDK syllabus (Class 11 & 12)
            </li>
                <li>
                    <TiTick className="tick-or-cross" />
               Chapter wise KCET/COMEDK/VIT etc question papers for each chapters
            </li>
                <li>
                    <TiTick className="tick-or-cross" />
                    Question papers for each chapter
            </li>
            <li>
                    <TiTick className="tick-or-cross" />
                    Test series
            </li>
            <li>
                    <TiTick className="tick-or-cross" />
                    A Bundle of 10000 expected questions of KCET/COMEDK 2023
            </li>
            <li>
                    <TiTick className="tick-or-cross" />
                    BONUS:24*7 doubt clarification
            </li>
                {/* <li>
                {
                        isActive === "Plus" || isActive === "Classic"?
                        <TiTick className="tick-or-cross" />
                        :
                        <RiCloseFill className="tick-or-cross"/>

                }
                Doubt solving on mobile app
            </li> */}
                {/* <li>
                    {
                        isActive === "Plus"?
                        <TiTick className="tick-or-cross" />
                        :
                        <RiCloseFill className="tick-or-cross"/>

                    }
                Personal mentor
            </li> */}
            </ul>
        </div>
    )
}
