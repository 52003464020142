import React from 'react'
import LearnCardBtn from '../LearnCardButton/LearnCardBtn'
import './LearnDownloadCard.scss'

export default function LearnDownloadCard({cardHead, downloadOne, downloadTwo, downloadThree,action,status}) {
    return (
        <div className="learn-download-card">
            <h3>{cardHead}</h3>
            <LearnCardBtn link={downloadOne} handlePopup={action}      status={status}/>
            <LearnCardBtn link={downloadTwo}  handlePopup={action}      status={status}/>
            <LearnCardBtn link={downloadThree} handlePopup={action}      status={status}/>
        </div>
    )
}
