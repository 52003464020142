import React from 'react'
import './CentreLandingCard.scss'
export default function CentreLandingCard({image, heading, paragraph}) {
    return (
        <div className="centre-landing-card">
            <img className="card-icon" src={image} alt=""/>
            <h6>{heading}</h6>
            <p>{paragraph}</p>
        </div>
    )
}
