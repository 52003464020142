import React,{useState} from 'react'
import TeacherCard from './Card/Card';
import './TeackerSlider.scss'
import {GrNext,GrPrevious} from 'react-icons/gr'

const TeacherSlider = () => {
    const [slide,setSlide] = useState("teacher_card_slide")
    const handleNext = () =>{
        setSlide("teacher_card_slide next-100")
    }
    const handlePrevious = () =>{
        setSlide("teacher_card_slide")
    }

    return (
        <div className="teacher_tutor_slider">
            <h3>What do our teachers have to say?</h3>
            <div className="teacher_cards_main">
                <GrPrevious className="tutor_cards_arrow" onClick={handlePrevious}/>
                <div className="teacher_card_container">
                    <div className={slide}>
                    <TeacherCard 
                        teacherProfile="/images/Teachers profile/luthfi.png"
                        teacherName = "Luthfi"
                        teacherGraduation = "Btech in Electrical and electronic engineering"
                        description = "He has several years’ experience in training students for IIT JEE, KVPY, Maths Olympiad etc. as a passionate teacher, a trustworthy mentor and a true motivator."
                    />
                    <TeacherCard 
                        teacherProfile="/images/Teachers profile/mufeed.jpg"
                        teacherName = "Dr.Mufeedh"
                        teacherGraduation = "MBBS from AIIMS Delhi ,MD from AIIMS Jodhpur"
                        description = " He has been consistently rated as an amazing teacher for his incredible energy and in depth coverage while delivering the classrooms lessons"
                        bg="teacher-white-card"
                        
                    />
                    <TeacherCard 
                        teacherProfile="/images/Teachers profile/Ramees.png"
                        teacherName = "Ramees PP"
                        teacherGraduation = "Phd from James Cook university B.Sc & M.Sc in Chemistry from IISER Bhopal"
                        description = "Ramees teaches Chemistry and  Physics at the secondary level and also served in instructional leadership roles as a curriculum manager for various institutions in India."
                    />
                    <TeacherCard 
                        teacherProfile="/images/Teachers profile/Gibi mol.png"
                        teacherName = "Gibi mol Babu"
                        teacherGraduation = "Ms in Cloud Computing from Cambrian college ,Sadburry,Canada"
                        description = "Gibi mol teaches Chemistry and  Physics at the secondary level and also served in instructional leadership roles as a curriculum manager for various institutions in India"
                    />
                    <TeacherCard 
                        teacherProfile="/images/Teachers profile/yaseen.jpg"
                        teacherName = "Muhammed Yaseen C"
                        teacherGraduation = "Dual degree Btech and Mtech from IIT Madras in Electrical engineering."
                        description = " Yaseen has helped hundreds of students to develop their math comprehension through its unique one-on-one online interface. "
                        bg="teacher-white-card"
                    />
                    <TeacherCard 
                        teacherProfile="/images/Teachers profile/Razi haneef.jpg"
                        teacherName = "Razi Haneef"
                        teacherGraduation = "Btech from National institute of technology"
                        description = "Btech from National institute of technologyRazi’s  is to put a strong emphasis on problem-solving techniques and leading students to answers rather than memorizing facts."
                    />
                    </div>
                </div>
                <GrNext className="tutor_cards_arrow" onClick={handleNext}/>
            </div>
        </div>
    )
}

export default TeacherSlider
