import React from 'react'
import './Map.scss'
function Map() {
    return (
        <div className="map">
            <p>
                Revamp24 has taught 8,000+ students <br/>
                in 10+ countries across the world!
            </p>
            <img src="/images/about page/map.png" alt=""/>
            <p>
                Revamp24 offers live online classes - 1:1 and in <br/> small groups - for math and Science.
            </p>
        </div>
    )
}

export default Map
