import React from 'react'
import './Skills.scss'
import TrailBtn from '../../TrailButton/TrailBtn'
export default function Skills() {
  return (
    <div className="skills_container">
      <h2 className="skill_head">What does it take to be<br/>
      a Revamp24 Teacher?</h2>
      <div className="skills_main">

        <div className="skill_content">
          <h4 className="skills">Skills</h4>
          <div className="skill_para">

            <ul>
              <li><span><img src="/images/round.png" alt="" /></span>An affinity for or interest in math/Science</li>
              <li><span><img src="/images/round.png" alt="" /></span>Strong logical reasoning skills</li>
              <li><span><img src="/images/round.png" alt="" /></span>Patience and passion for teaching</li>
            </ul>


          </div>
        </div>

        <div className="skill_content2">
          <h4 className="requirements">Requirements</h4>
          <div className="skill_para">

            <ul>
              <li><span><img src="/images/Rectangle 102.png" alt="" /></span>A personal computer/laptop and a headset</li>
              <li> <span><img src="/images/Rectangle 102.png" alt="" /></span>Consistent availability during chosen class hours</li>
              <li><span><img src="/images/Rectangle 102.png" alt="" /></span>High-speed internet (8Mbps or higher)</li>
            </ul>


          </div>
        </div>
      </div>
      <div className="job_requirement_button">
        <TrailBtn
          color="grey"
          type="btn_tutor_requirement"
          children="View Job Requirements"
          url="https://forms.gle/Yto5Uko8X6Bkrb1H8"
        />
      </div>

    </div>
)
}