import React from 'react'
import './NewWay.scss'
export default function NewWay() {
    return (
      <div className="new_way_container">
        <div className="new_way_main">
                <h1 className="main_head"> Welcome to a new way to Learn </h1>
                <p className="first_para">We help you or your student connect with the right tutor or class for your needs, right when you need them most. Our innovative Live
Learning Platform, personalized approach, and highly credentialed instructors make it easier for you to learn with confidence.
</p>
<p className=" second_para">Find the right instructors for your needs, from 8th Std to 12th,CBSE,ICSE, State syllabus, test prep like
JEE Mains, NEET, BITS, AIIMS, State Entrance Exams, Languages, Enrichment, Engineering And More.</p>
       <h1 className="head2">Online Sessions And Resources For Better At-Home Learning</h1>
       <p className="first_para">As a leader in online education, we feel a responsibility to help parents and students as schools close down. Take advantage of live
online classes and interactive summer camps  free to all</p>
        </div>
      </div>
    )
}

