import React from 'react'
import { Link } from 'react-router-dom'
import './HomeTutionWorking.scss'

export default function HomeTutionWorking() {
    return (
        <div className="home-tution-working">
            <div className="working-container">
                <div className="image-section">
                    <img src="/images/home tution/bghome.png" alt="" className="working-image"/>
                </div>
                <div className="working-section">
                    <h1>How's it work?</h1>    
                    <ul>
                        <li>
                            <h6 className="counts">1</h6>
                            <div className="content">
                                <h6>Tell us your tution needs</h6>
                                <p>Just fill few details about your home tution needs and we will give you a right fit tutor for your child.</p>
                            </div>
                        </li>
                        <li>
                            <h6 className="counts">2</h6>
                            <div className="content">
                                <h6>Get free demo</h6>
                                <p>We will arrange a free demo class by our tutor at your place.</p>
                            </div>
                        </li>
                        <li>
                            <h6 className="counts">3</h6>
                            <div className="content">
                                <h6>Confirm if you like</h6>
                                <p>After the demo class, evaluate the teacher and if you are satisfied with the tutor's teaching ability, then confirm him.</p>
                            </div>
                        </li>
                        <li>
                            <h6 className="counts">4</h6>
                            <div className="content">
                                <h6>Need help?</h6>
                                <p>Avail the best in class service from our customer service executives.</p>
                            </div>
                        </li>
                    </ul>
                </div>    
            </div> 
            <div className="need-a-tutor">
                <h2>Get a free demo class from best home tutor near you.</h2>
                <Link to="/home-tution">I need a tutor</Link>
            </div>
        </div>
    )
}
