import React from 'react'
import './HomeTutionAbout.scss'
export default function HomeTutionAbout() {
    return (
        <div className="why-home-tution">
            <h2>Why Private tution?</h2>
            <p>
                Private tuition on a one-to-one basis is clearly the most productive way to learn. Private tuition can be used to help your child catch up after
                temporarily falling behind, to learn a subject outside the school environment, to stretch your child further or give closer attention to the needs of
                your child than is possible in a classroom environment.
                <br />
                <br/>
                We feel home tutors and private tutors can offer a number of benefits for your child, including raising levels of school performance, but also helping
                a child to increase their confidence, enjoyment and motivation in a subject.
                <br />
                <br/>
                The extra focus and tailored attention of specialised one-to-one private tuition can be the decisive factor that will make the difference to successful
                learning and good exam grades, ensuring that you or your child is able to gain the most benefit from their mainstream educational activities and
                fulfil their academic potential.
            </p>
        </div>
    )
}
