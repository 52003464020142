import React from 'react'
import './Subject.scss'
export default function Subjects({subjectImage,subjectName,examImage}) {
    return (
     <div className="subject_container"> 
     <div className="subject_content">
     <img className={examImage} src={subjectImage} alt=""/>
       
     </div>
     <p>{subjectName}</p>
     </div>
    )
}
