import React from 'react'
import './RevampAtWork.scss'

function RevampAtWork() {
    return (
        <div className="revamp_at_work">
            <h4>Revamp24 at work</h4>
            <div className="gallery">
                <div className="image_1">
                    <img src="/images/about page/image1.jpg" className="gallery_images" alt=""/>
                </div>
                <div className="image_2">
                    <img src="/images/about page/image2.jpg" className="gallery_images" alt=""/>
                </div>
                <div className="image_3">
                    <img src="/images/about page/image3.jpg" className="gallery_images" alt=""/>
                </div>
            </div>
        </div>
    )
}

export default RevampAtWork
