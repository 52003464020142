import React, { useEffect, useState } from 'react'
import { GrBlog } from 'react-icons/gr'
import { useDispatch } from 'react-redux'
import { instance_url } from '../../../Assets/config'
import actions from '../../../Assets/dataStore/dataStoreAction'
import BlogCategory from './Blog category/BlogCategory'
import './BlogHome.scss'

export default function BlogHome() {
    const [categories, setCategories] = useState(null);
    const dispatch = useDispatch()

    useEffect(() =>{
        getCategorizedBlogs();
        dispatch(actions.switchOnLoading());
    },[])

    //Function for fetch the categorized blogs
    const getCategorizedBlogs = () =>{
        fetch(`${instance_url}/blog/categorized`)
        .then(res => res.json())
        .then(res => {
            setCategories(res.data)
            dispatch(actions.switchOffLoading())
        })
    }
    return (
        <div className="blog-home-wrapper">
            <section className="blog-home">
                <h1 className="blog-header-main"> <GrBlog className="blog-icon"/> Revamp24 Blog</h1>
                <div className="blog-category-wrapper">
                    {
                        categories &&
                        categories.map((category,index) =>(
                            <BlogCategory key={index} item={category}/>
                        ))
                    }
                </div>
            </section>
        </div>
    )
}
