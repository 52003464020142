import React from 'react'
import './MobileSpecial.scss'

const MobileSpecial = (props) => {
    return (
        <div className="mobile-special">
            <img src="/images/campaign/icons/checked.png" alt=""/>
            <p>{props.text}</p>
        </div>
    )
}

export default MobileSpecial;
