import React, { useState } from "react";
import "./Menu.scss";
import { IoClose } from "react-icons/io5";
import { useHistory } from "react-router-dom";

const Menu = ({ close, display }) => {
  
    const [classes, SetClasses] = useState(false),
          [exams,setExams] = useState(false);
    const classList = ["8", "9", "10", "11", "12"];
    const examList = ['KCET','COMEDK','PESSAT','NEET','JEE',"TS EAMCET","AP EAMCET","MHTCET"]
    const history = useHistory();

  const openClasses = ()=>{
    if(exams){
      setExams(false)
      SetClasses(!classes)
    }else{
      SetClasses(!classes)
    }
  }

  const openExams = ()=>{
    if(classes){
      SetClasses(false);
      setExams(!exams)
    }else{
      setExams(!exams)
    }
  }

  return (
    <div className={display?'menu_container':"menu_hide"}>
      <div className="menu_header">
        <img className="main_icon" src="/icons/logo.png" alt="" 
          onClick={()=>history.push('/')}
        />
        <IoClose className="close_icon" onClick={close} />
      </div>
      <ul>
        <li onClick={() =>openClasses()}>Classes</li>

        <li className={classes ? "classList" : "classListHide"}>
          <ul>
            {classList.map((d, i) => (
              <li key={i} onClick={()=>{history.push(`/course/Class ${d}`);close()}}>Class {d}</li>
            ))}
          </ul>
        </li>

        <li onClick={()=>openExams()}>Competitive Exam</li>
        
        <li className={exams ? "classList" : "classListHide"}>
          <ul>
            {examList.map((d, i) => (
              <li key={i} onClick={()=>{history.push(`/competitive-exam/${d.replace(/\s+/g,"-").toLowerCase()}-online-coaching`);close()}}>{d}</li>
            ))}
          </ul>
        </li>        

        <li onClick={()=>window.open("https://revamp24.stores.instamojo.com")} >Micro-courses</li>
        <li onClick={()=>history.push('/become-tutor')}>Become a Teacher</li>
        <li onClick={()=>history.push('/About-Us')} >About Us</li>
      </ul>
    </div>
  );
};

export default Menu;
