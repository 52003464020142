import React, { useRef, useState } from "react";
import "./LearnAndPractice.scss";
import LearnDownloadCard from "./LearnDownloadCard/LearnDownloadCard";
import { AiOutlineClose } from "react-icons/ai";
import { GoogleSpreadsheet } from "google-spreadsheet";
import { useDispatch } from 'react-redux'
import actions from '../../../Assets/dataStore/dataStoreAction'

export default function LearnAndPractice() {
  const [formData, setFormData] = useState({
    name: "",
    class: "",
    mobile: "",
    email: "",
    city: "",
    laptop: "",
  });

  const [error, setError] = useState(false);
  let popRef = useRef();
  const dispatch = useDispatch()
  const [link, setLink] = useState();
  let status = localStorage.getItem("form");
  const handlePopup = (type, data) => {
    if (type === 1 && !status) {
      popRef.current.style.display = "block";
      setLink(data);
    } else {
      popRef.current.style.display = "none";
    }
  };

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const Attributes = [
    "FirstName",
    "Notes",
    "Phone",
    "EmailAddress",
    "mx_City",
    "mx_Have_Laptop",
  ];

  const extra = {
    Attribute: "Source",
    Value: "CPC",
  };

  const submitToLeadsquare = (data) => {
    console.log(data);
    let Attribute = Attributes;
    let keys = Object.keys(data);
    let newData = [];
    for (let i = 0; i < keys.length; i++) {
      let key = keys[i];
      let att = Attribute[i];
      let obj = {
        Attribute: att,
        Value: formData[key],
      };

      newData.push(obj);
    }
    newData.push(extra);

    console.log(newData);
    fetch(
      `https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$rd69b0fe442bd5b19652e003f57e67e88&secretKey=374b41196f0daadc114e42b74925541df1e03776`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newData),
      }
    ).then((res) => {
      console.log(res);
    });
  };

  const {
    REACT_APP_PRIVATE_KEY,
    REACT_APP_CLIENT_EMAIL,
    REACT_APP_KCET_FORM_SPREADSHEET_ID,
  } = process.env;

  const doc = new GoogleSpreadsheet(REACT_APP_KCET_FORM_SPREADSHEET_ID);

  const appendSpreadsheet = async (row) => {
    try {
      await doc.useServiceAccountAuth({
        client_email: REACT_APP_CLIENT_EMAIL,
        private_key: REACT_APP_PRIVATE_KEY,
      });
      await doc.loadInfo();
      console.log(doc.loadInfo());
      const sheet = doc.sheetsById["0"];
      const result = await sheet.addRow(row);
      return result;
    } catch (e) {
      setError(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      formData.name.length > 0 &&
      formData.class.length > 0 &&
      formData.mobile.length > 0 &&
      formData.email.length > 0 &&
      formData.city.length > 0 &&
      formData.laptop.length > 0
    ) {
      submitToLeadsquare(formData);
      appendSpreadsheet(formData);
        localStorage.setItem("form", true);
      handlePopup(2, "");
      dispatch(actions.switchOnLoading());
        setTimeout(()=>{
          window.location.href = link;
          dispatch(actions.switchOffLoading())
        },4000)
    } else {
      setError(true);
    }
    console.log(formData);
  };

  return (
    <div className="learn-and-practice" id="study-materials">
      <h1 className="learn-head">
        LEARN AND PRACTICE THROUGH OUR REGULAR CLASSES, DPPs AND MORE
      </h1>
      <div className="popup_ref" ref={popRef}>
        <div className="pop_close_button">
          <AiOutlineClose onClick={() => handlePopup(2, "")} />
        </div>
        <div className="pop---Content">
          <div className="popup---header">
            <h1>Register Now To Download Practice Papers</h1>
            <p>KCET - Karanataka Common Entrance Test</p>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="pop_input__row">
              <input
                name="name"
                type="text"
                onChange={handleInputChange}
                className="input__popup"
                placeholder="Student name"
              />
              <input
                name="email"
                type="email"
                onChange={handleInputChange}
                className="input__popup"
                placeholder="Email id"
              />
            </div>
            <div className="pop_input__row">
              <input
                name="mobile"
                type="text"
                onChange={handleInputChange}
                className="input__popup"
                placeholder="Mobile Number"
              />
              <input
                type="text"
                name="city"
                onChange={handleInputChange}
                className="input__popup"
                placeholder="city"
              />
            </div>
            <div className="pop_input__row">
              <input
                name="class"
                onChange={handleInputChange}
                type="text"
                className="input__popup"
                placeholder="Current Class"
              />
              <div className="pop_lap_wrapper">
                <p>Do you have Laptop ?</p>
                <div className="radio__container">
                  <div className="radio-wrap-pop">
                    <input
                      name="laptop"
                      onChange={handleInputChange}
                      type="radio"
                      value="yes"
                    />
                    <p> Yes</p>
                  </div>

                  <div className="radio-wrap-pop">
                    <input
                      name="laptop"
                      onChange={handleInputChange}
                      type="radio"
                      value="No"
                    />
                    <p> No</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="terms_condition_popup">
              <p>
                By submitting this form, you accept and agree to our{" "}
                <span>Terms of Use</span>
              </p>
            </div>
            <button type="submit" id="KCET2022_Submit_button">
              Submit
            </button>
            {error && (
              <small style={{ color: "red" }}>
                Please Fill all the Fields to Download{" "}
              </small>
            )}
          </form>
        </div>
      </div>

      <div className="learn-cards-wrapper">
        <LearnDownloadCard
          cardHead="View sample questions"
          downloadOne="/Documents/Model paper 1.pdf"
          downloadTwo="/Documents/Model paper 2.pdf"
          downloadThree="/Documents/Model paper 3.pdf"
          action={handlePopup}
          status={status}
        />
        <LearnDownloadCard
          cardHead="View sample Worksheets"
          downloadOne="/Documents/Motion in a Stright Line.pdf"
          downloadTwo="/Documents/structure of atom.pdf"
          downloadThree="/Documents/Complex Numbers & Quadratic Equations.pdf"
          action={handlePopup}
          status={status}
        />
      </div>
    </div>
  );
}
