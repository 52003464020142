import React, { useState } from 'react'
import Innovative from './Innovative/Innovative'
import InnovativeMobile from './Innovative/InnovativeMobile/InnovativeMobile'
import Mentorship from './Mentorship/Mentorship'
import Rigrous from './Rigrous/Rigrous'
import TeachMethod from './teach_method/TeachMethod'
import './WhyRevamp.scss'


export default function WhyRevamp() {
  const [slide, setSlide] = useState(null);
  const [font, setFont] = useState(null);
  const [font1, setFontOne] = useState(null);
  const [font2, setFontTwo] = useState(null);
  const [font3, setFontThree] = useState(null);
  const [DefaultWeight, setDefaultWeight] = useState(" default_weight ");
  const Disable = () => {
    setDefaultWeight(null);
    setFontOne(false);
    setFontTwo(false);
    setFontThree(false);
    setFont(false);    
  };
  
  return (
    <div className="whyrevamp_container">
      <h3 className="why_choose_header">Why choose Revamp24 ?</h3>
      <div className="main_content">
        <div className="content_text">
          <div
            className="innovative"
            onClick={() => {
              Disable();
              setSlide("t-00");
              setFontOne("bold-00");
            }}
          >
            <h4 className={DefaultWeight + " " + font1}> Innovative Methodology</h4>
          </div>
          <div className="line"></div>
          <div
            className="Rigorous"
            onClick={() => {
              Disable();
              setSlide("t-100");
              setFontTwo("bold-50");
            }}
          >
            <h4 className={font2}>Rigorous Approach</h4>
          </div>
          <div className="line"></div>
        
          <div
            className="Mentorship"
            onClick={() => {
              Disable();
              setSlide("t-200");
              setFontThree("bold-100");
            }}
          >
            <h4 className={font3}>Mentorship Program</h4>
          </div>
          <div className="line"></div>
        
        
          <div
            className="personal"
            onClick={() => {
              Disable();
              setSlide("t-300");
              setFont("bold-200");
            }}
          >
            <h4 className={font}> Personal Attention</h4>
          </div>
          
        </div>
      </div>
      <div className="home-slider">
        <div className={"slide " + slide}>
          <Innovative /> 
          <InnovativeMobile />
          <Rigrous />
          <Mentorship />
          <TeachMethod />
        </div>
      </div>
    </div>
  );
}
