import React from 'react'
import CourseDetails from '../../Courses/CourseLanding/CourseDetails/CourseDetails'
import LandingCard from '../LandingCard/LandingCard'
import './CompititiveLanding.scss'

export default function CompititiveLanding({id}) {
    
    return (
        <div className="compititive_landing">
            <div className="main_content">
                <div className="left_section">
                    {
                        id
                        &&
                        <CourseDetails compititive={true} heading={id}/>
                    }
                </div>
                <div className="right_section">
                    <img src="/images/coursePage/course_landing_girl.png" alt=""/>
                </div>
            </div>
            <div className="landing_cards">
                <div className="for-mobile">
                    <LandingCard cardIcon="/images/compititive exam/interactive.png" cardContent="1:1 Live Interactive Classes" />
                    <LandingCard cardIcon="/images/compititive exam/all india.png" cardContent="All india test Series" />
                </div>
                <div className="for-mobile">
                    <LandingCard cardIcon="/images/compititive exam/doubt.png" cardContent="Instant doubt solving" />
                    <LandingCard cardIcon="/images/compititive exam/support.png" cardContent="Continued Support" />
                </div>
            </div>
        </div>
    )
}
