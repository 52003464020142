import React from 'react'
// import { TiTick } from 'react-icons/ti';
import { useDispatch } from 'react-redux';
import coursePriceActions from '../../../../../Assets/CoursePricing/CoursePriceAction';
import './SubjectCard.scss'
// import { IoIosArrowDown } from 'react-icons/io'
import CardDetails from '../cardDetails/CardDetails';
const SubjectCard = ({ subject, price, type, isActive, speciality, off ,offRate }) => {

    const dispatch = useDispatch();

    const handleSubjectCard = () =>{
        console.log(subject);
        dispatch(coursePriceActions.btnClick(subject))   
    }

    return (
        <div className="subject-card-container">
            <div className={"subject_card " + type}>
                <div className="subject_card_overlay" onClick={handleSubjectCard}></div>
                <div className={isActive ? "tick_container sub_active" : "tick_container"}>
                </div>
                <div className="sub_and_benefit">
                    <h3>{subject}</h3>
                    <p>{speciality}</p>
                </div>
                <div className="price">
                    <h3>₹ {price}</h3>
                    <p><span>₹{offRate}</span> {off} off</p>
                </div>
            </div>
            <CardDetails isActive={isActive} />
        </div>
    )
}

export default SubjectCard
