import React, {useState} from 'react'

function Pagination({totalPages,getClickedNumber}) {
    const[page,setPage]=useState(1)
    let pages = []
    let totalPage = totalPages ; /*the total pages coming from the blogCatogories.jsx*/
    let pageLength=0;
    let beforePage = page-1;
    let afterPage = page+1;

    for(pageLength=beforePage;pageLength<=afterPage;pageLength++){
        //pagelength =0 ; pagelength <= 2 ; pagelength = 2

        if( afterPage>=totalPage && totalPage>3 ){
            pages=[totalPage-2,totalPage-1,totalPage]
        }
        else if( beforePage<=0 && totalPage>3 ){
            pages=[1,2,3]
        }else if( totalPage === 3 ){
            pages=[1,2,3] 
        }else if(totalPage===2){
            pages=[1,2]
        }else if(totalPage===1){
            pages=[1]
        }else{
            pages.push(pageLength)
        }
        

        // console.log(pageLength);
    }
    const pageClicked = number =>{
        setPage(number)
        getClickedNumber(number)
    }
    let paginationItems = pages.map(pages=>{
    
        return(
            <li onClick={()=>pageClicked(pages)} className={page===pages? "active pagination-items" : "pagination-items" }>{pages}</li>
        )
    })

    const prevBtnClicked = () =>{
        if(page!==1){
            setPage(page-1)
            getClickedNumber(page-1)
        }
    }
    const nextBtnClicked = () =>{
        if(page!==totalPage){
            setPage(page+1)
            getClickedNumber(page+1)
        }
    }

    return (
        
    <ul className="pagination">
         <li  onClick={prevBtnClicked} className={page<=1?"disabled":"prev next-prev-btn"}>Prev</li>
          {paginationItems}
         <li onClick={nextBtnClicked} className={page>=totalPage?"disabled":"next next-prev-btn"}>Next</li>
     </ul>
    )

}

export default Pagination
