import React from 'react'
import "./WorkingCard.scss"
export default function WorkingCard({image,para,workClass}) {
    return (
        <div className="work_card_container">

                    <div className="work_image">
                       
                    <img className={workClass} src={image} alt=""/>
                    </div>
                    <p className="Work_para_revamp ">{para}</p>
                        
                    </div>
       
    )
}
