import React from 'react'
import './BurgerIcon.scss'

const BurgerIcon = () => {
    return (
        <div className={"burger_icon "}>
            <div className="long_bar"></div>
            <div className="short_bar"></div>
            <div className="long_bar"></div>
            <div className="short_bar"></div>
        </div>
    )
}

export default BurgerIcon
