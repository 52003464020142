import React from "react";
import "./LearnCardBtn.scss";

export default function LearnCardBtn({ link, handlePopup, status }) {
  return (
    <>
      {!status ? (
        <div onClick={()=>handlePopup(1,link)} className="learn-card-btn">
          <img src="/images/KCET/pdfPart/pdf.png" alt="" />
          Download Here
        </div>
      ) : (
        <a download href={link} className="learn-card-btn">
          <img src="/images/KCET/pdfPart/pdf.png" alt="" />
          Download Here
        </a>
      )}
    </>
  );
}
