import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TrailBtn from "../TrailButton/TrailBtn";
import BurgerIcon from "./BurgerIcon/BurgerIcon";
import { IoClose } from "react-icons/io5";
import "./Navbar.scss";
import Menu from "./MobileMenu/Menu";


const Navbar = ({
  loginRemove,
  trialRemove,
  navImageVisible,
  trialBtnColor,
  blogPage
}) => {
  const [bg, setBg] = useState(false);
  const [navActive, setNavActive] = useState(false);
  const [classes, setClasses] = useState(false);
  const [hidden, setHidden] = useState(false);

  window.addEventListener("scroll", () => {
    if (window.scrollY > 20) {
      setBg(true);
    } else {
      if (!blogPage) {
        setBg(false);
      }
    }
  });

  useEffect(() => {
    if (window.innerWidth < 950) {
      setHidden(true);
    }
    if (blogPage) {
      setBg(true)
    }
  }, []);

  window.addEventListener('resize', () => {
    if (window.innerWidth < 950) {
      setHidden(true)
    } else {
      setHidden(false)
    }
  })

  const handleClasses = () => {
    setClasses(!classes);
  };

  const openNav = () => {
    setHidden(false);
    setNavActive(true);
  };

  return (
    <>
      <Menu close={() => setNavActive(false)} display={navActive} />
      <div className={bg ? "navbar nav_bg" : "navbar"}>
        <div onClick={openNav}>
          <BurgerIcon />
        </div>
        <Link to="/">
          <img className="main_icon" src="/icons/logo.png" alt="" />
        </Link>
        <ul className={"nav-links"} style={{ display: hidden ? "none" : null }}>
          {navActive && (
            <li className="mobile_nav_head">
              <img src="/icons/logo.png" alt="" />
              <IoClose
                className="close_icon"
                onClick={() => setNavActive(false)}
              />
            </li>
          )}
          <li
            className="nav-items classes_li"
            onClick={() => {
              handleClasses();
            }}
          >
            <p className={bg && "link_active"}>
              Classes
            </p>
            <ul className="class_dropdown">
              <li>
                <Link to="/course/Class 5">Class 5</Link>
              </li>
              <li>
                <Link to="/course/Class 6">Class 6</Link>
              </li>
              <li>
                <Link to="/course/Class 7">Class 7</Link>
              </li>

              <li>
                <Link to="/course/Class 8">Class 8</Link>
              </li>
              <li>
                <Link to="/course/Class 9">Class 9</Link>
              </li>
              <li>
                <Link to="/course/Class 10">Class 10</Link>
              </li>
              <li>
                <Link to="/course/Class 11">Class 11</Link>
              </li>
              <li>
                <Link to="/course/Class 12">Class 12</Link>
              </li>
            </ul>
          </li>
          <li
            className={
              classes
                ? "nav-items classes_mobile  classes_active"
                : "nav-items classes_mobile"
            }
          >
            <Link to="/course/Class 8">Class 8</Link>
          </li>
          <li
            className={
              classes
                ? "nav-items classes_mobile  classes_active"
                : "nav-items classes_mobile"
            }
          >
            <Link to="/course/Class 9">Class 9</Link>
          </li>
          <li
            className={
              classes
                ? "nav-items classes_mobile  classes_active"
                : "nav-items classes_mobile"
            }
          >
            <Link to="/course/Class 10">Class 10</Link>
          </li>
          <li
            className={
              classes
                ? "nav-items classes_mobile  classes_active"
                : "nav-items classes_mobile"
            }
          >
            <Link to="/course/Class 11">Class 11</Link>
          </li>
          <li
            className={
              classes
                ? "nav-items classes_mobile  classes_active"
                : "nav-items classes_mobile"
            }
          >
            <Link to="/course/Class 12">Class 12</Link>
          </li>
          <li className="nav-items compititive_li" onClick={() => setNavActive(false)}>
            <p className={bg && "link_active"}>
              Competitive Exams
            </p>
            <ul className="compititive_dropdown">
              <li>
                <Link to="/competitive-exam/kcet-online-coaching">KCET</Link>
              </li>
              <li>
                <Link to="/competitive-exam/comedk-online-coaching">COMEDK</Link>
              </li>
              <li>
                <Link to="/competitive-exam/pessat-online-coaching">PESSAT</Link>
              </li>
              <li>
                <Link to="/competitive-exam/neet-online-coaching">NEET</Link>
              </li>
              <li>
                <Link to="/competitive-exam/jee-online-coaching">JEE</Link>
              </li>
              <li>
                <Link to="/competitive-exam/ts-eamcet-online-coaching">TS EAMCET</Link>
              </li>
              <li>
                <Link to="/competitive-exam/ap-eamcet-online-coaching">AP EAMCET</Link>
              </li>
              <li>
                <Link to="/competitive-exam/mhtcet-online-coaching">MHT CET</Link>
              </li>
            </ul>
          </li>
          <li className="nav-items" onClick={() => setNavActive(false)}>
            <p className={bg && "link_active"} onClick={() => window.open("https://revamp24.stores.instamojo.com")}>
              Micro-Courses
            </p>
          </li>
          <li className="nav-items" onClick={() => setNavActive(false)}>
            <Link className={bg && "link_active"} to="/become-tutor">
              Become a Teacher
            </Link>
          </li>
          <li className="nav-items compititive_li" onClick={() => setNavActive(false)}>
            <p className={bg && "link_active"}>
              More
            </p>
            <ul className="compititive_dropdown">
              <li>
                <Link to="/blog">Our Blog</Link>
              </li>
              <li>
                <Link to="/About-Us">About Us</Link>
              </li>
            </ul>
          </li>
        </ul>
        {navImageVisible && (
          <img src="/icons/logo.png" className="nav-center-image" alt="" />
        )}
        <div className="login-and-trail">
          <TrailBtn
            children="Book a free trial"
            link="book-a-free-trial"
            color={trialBtnColor}
            btnID="button_book_a_free_trial"
            type={
              trialRemove ? "btn_trail_nav btn_trail_nav_none" : "btn_trail_nav"
            }
          />
          <TrailBtn
            children="Login"
            windowOpen="https://revamp24.speedlabs.in/loginREVAMP.aspx"
            type={
              loginRemove ? "btn_login_nav btn_login_nav_none" : "btn_login_nav"
            }
          />
        </div>
      </div>
    </>
  );
};

export default Navbar;
