import React from 'react'
import HomeTutionLandingCard from '../HomeTutionLandingCard/HomeTutionLandingCard'
import './HomeTutionLanding.scss'
// import {LazyLoadImage} from 'react-lazy-load-image-component'
// import "react-lazy-load-image-component/src/effects/blur.css";

export default function HomeTutionLanding() {
    return (
        <div className="home-tution-landing">
            <div className="landing-text">
                <h1>Teachers to your home</h1>
                <p>High quality Home tution</p>
                <img className="home-landing-image" src="/images/home tution/homeLanding.svg" alt="" />
                {/* <LazyLoadImage effect='blur' className="home-landing-image" src={'/images/home tution/homeLanding.svg'}/> */}
            </div>
            <div className="home-landing-cards">
                <HomeTutionLandingCard 
                    icon="/images/home tution/arrow.svg" 
                    content="Home tution is #1 secret of success.It is a well known fact that home tution helps students to succeed. Home tutors provide personalized attention."
                />
                <HomeTutionLandingCard 
                    icon="/images/home tution/arrow.svg" 
                    content="Expert one-to-one guidance from home tutors helps students.Here students gets 100% and can ask doubts without any hesitation."
                />
                <HomeTutionLandingCard 
                    icon="/images/home tution/arrow.svg" 
                    content="Did students learn and achieve better grades! Numerous students have benefited from expert guidance from home tutors."
                />
            </div>
        </div>
    )
}
