import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Header from '../../CampaignPage/Header/Header'
import Footer from '../Footer/Footer'
import BestTuition from './BestTuition/BestTuition'
import './CentralTution.scss'
import CentreLanding from './Centre landing/CentreLanding'
export default function CentralTution() {
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])
    return (
        <div className="centre-tution">
            <Helmet>
                <title>Centre Tution</title>
            </Helmet>
            <Header type="fixed"/> 
             <CentreLanding/>
            <BestTuition/>
            <Footer/>
        </div>
    )
}
