import React, { useState } from 'react';

const EnquireForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    batch: '',
    feedback: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform form validation here
    const errors = validateForm();
    setErrors(errors);

    // If there are no errors, handle form submission
    if (Object.keys(errors).length === 0) {
      console.log(formData); // Handle form data submission here (e.g., send data to the server)
    }
  };

  const validateForm = () => {
    let errors = {};
    if (!formData.name.trim()) {
      errors.name = 'Name is required';
    }
    if (!formData.phone.trim()) {
      errors.phone = 'Phone number is required';
    }
    if (!formData.batch.trim()) {
      errors.batch = 'Batch is required';
    }
    if (!formData.feedback.trim()) {
      errors.feedback = 'Feedback/Complaint is required';
    }
    return errors;
  };

  return (
    <div style={{ display:'flex',flexDirection:'column',justifyContent:'center',maxWidth: '400px', margin: '0 auto',height:'100vh' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Raise Complaints</h2>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            style={{ width: '100%', padding: '8px', borderRadius: '5px' }}
          />
          {errors.name && <span style={{ color: 'red' }}>{errors.name}</span>}
        </div>

        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="phone">Phone Number:</label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            style={{ width: '100%', padding: '8px', borderRadius: '5px' }}
          />
          {errors.phone && <span style={{ color: 'red' }}>{errors.phone}</span>}
        </div>

        <div style={{ marginBottom: '10px' }}>
          <label htmlFor="batch">Batch:</label>
          <input
            type="text"
            id="batch"
            name="batch"
            value={formData.batch}
            onChange={handleChange}
            style={{ width: '100%', padding: '8px', borderRadius: '5px' }}
          />
          {errors.batch && <span style={{ color: 'red' }}>{errors.batch}</span>}
        </div>

        <div style={{ marginBottom: '20px' }}>
          <label htmlFor="feedback">Feedback/Complaint:</label>
          <textarea
            id="feedback"
            name="feedback"
            value={formData.feedback}
            onChange={handleChange}
            rows="4"
            style={{ width: '100%', padding: '8px', borderRadius: '5px' }}
          />
          {errors.feedback && <span style={{ color: 'red' }}>{errors.feedback}</span>}
        </div>

        <button type="submit" style={{ padding: '8px 16px', borderRadius: '5px', background: 'blue', color: 'white' }}>Submit</button>
      </form>
    </div>
  );
};

export default EnquireForm;
