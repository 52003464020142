
const coursePriceActions = {
    changeSubject : (price) => ({
        type : "ADD_SUBJECT",
        payload : price
    }),
    subtractSubject : (price) =>({
        type : "SUBTRACT_SUBJECT",
        payload :  price
    }),
    btnClick : (plan) => ({
        type : "PLAN_SELECT",
        payload : plan
    
    })
}

export default coursePriceActions;