import React from "react";
import "./RigrousMain.scss";
export default function RigrousMain(props) {
  return (
    <div className="approach_container">
      <div className="approach_main">
        <img className={props.classimg} src={props.img1} alt="" />
        <p className={props.class}>{props.para1}</p>
      </div>
    </div>
  );
}
