import { useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import Courses from "./Components/USER/Courses/Courses";
import Home from "./Components/USER/Home/Home";
import BecomeTutor from "./Components/USER/BecomeTutor/BecomeTutor";
import AboutUs from "./Components/USER/AboutUs/AboutUs";
import PrivacyPolicy from "./Components/USER/PrivacyPolicy/PrivacyPolicy";
import TermAndCondition from "./Components/USER/TermAndCondition/TermAndCondition";
import Disclaimer from "./Components/USER/Disclaimer/Disclaimer";
import ReturnAndCancellation from "./Components/USER/Return and cancellation/ReturnAndCancellation";
import Maintanance from "./Components/USER/Maintance page/Maintanance";
import SelectClass from "./Components/USER/SelectClass/SelectClass";
import CompititiveExam from "./Components/USER/compititive exam/CompititiveExam";
import ClassSchedulePdf from "./Components/USER/ClassSchedulePdf/ClassSchedulePdf";
import HomeTuition from "./Components/USER/HomeTution/HomeTution";
import AdmissionForm from "./Components/USER/AdmissionForm/AdmissionForm";
import OnlineTuition from "./Components/USER/OnlineTuition/OnlineTuition";
import NotFound from './Components/USER/NotFound/NotFound';
import CampaignHome from "./Components/CampaignPage/Home/Home"
import SchedulePage from './Components/CampaignPage/SchedulePage/SchedulePage'
import ThanksPage from './Components/CampaignPage/ThanksPage/ThanksPage'
import CentralTuition from './Components/USER/Central tution/CentralTution';
import KcetPage from './Components/KCET Page/KcetPage';
import Blog from './Components/Blog/BlogRouter';
import BlogContent from './Components/Blog/BlogContent/BlogContent';
import BannerAdd from './Components/BannerAdd/BannerAdd';
import Timer from './Components/Timer/Timer';
import { useSelector } from 'react-redux';
import Loader from './Components/Loader/Loader';
import EnquireForm from './Components/enquireForm/EnquireForm';
function App() {
  const essential = useSelector(state => state.essential.essential.bannerVisibility)
  const isLoading = useSelector(state => state.essential.essential.isLoading)

  useEffect(() => {
    const chatBot = document.createElement('script');
    chatBot.innerHTML = "(function(I, L, T, i, c, k, s) {if(I.iticks) return;I.iticks = {host:c, settings:s, clientId:k, cdn:L, queue:[]};var h = T.head || T.documentElement;var e = T.createElement(i);var l = I.location;e.async = true;e.src = (L||c)+'/client/inject-v2.min.js';h.insertBefore(e, h.firstChild);I.iticks.call = function(a, b) {I.iticks.queue.push([a, b]);};})(window, 'https://cdn.intelliticks.com/prod/common', document, 'script', 'https://app.intelliticks.com', '36fHDtKhjxHQ82J5X_c', {});"
    document.body.appendChild(chatBot)
  }, [essential])


  return (
    <Router>
      {/* {
        essential && 
        <BannerAdd/>
      } */}
      {
        isLoading &&
        <Loader/>
      }
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>

        <Route path="/select/:id">
          <SelectClass />
        </Route>

        <Route path="/course/:id" render={(props) => <Courses {...props} />} />

        <Route path="/become-tutor">
          <BecomeTutor />
        </Route>

        <Route path="/About-Us">
          <AboutUs />
        </Route>

        <Route path="/privacy-policy">
          <PrivacyPolicy />
        </Route>

        <Route path="/terms-and-conditions">
          <TermAndCondition />
        </Route>

        <Route path="/disclaimer">
          <Disclaimer />
        </Route>

        <Route path="/return-and-cancellation">
          <ReturnAndCancellation />
        </Route>
        <Route path="/home-tution">
          <HomeTuition />
        </Route>

        <Route path="/book-a-free-trial">
          <CampaignHome />
        </Route>

        <Route path="/schedule-class-now">
          <SchedulePage />
        </Route>

        <Route path="/thanks">
          <ThanksPage />
        </Route>

        <Route path="/online-tution">
          <OnlineTuition />
        </Route>

        <Route path="/centre-tuition">
          <CentralTuition />
        </Route>

        <Route path="/competitive-exam/:id" render={(props) => <CompititiveExam {...props} />} />

        <Route path="/maintenance">
          <Maintanance />
        </Route>

        <Route path="/schedule/:id">
          <ClassSchedulePdf />
        </Route>

        <Route path="/login">
          <Maintanance />
        </Route>
        <Route path="/admission">
          <AdmissionForm />
        </Route>
        <Route path="/admission/kcet">
          <AdmissionForm />
        </Route>
        <Route path="/admission/mhtcet">
          <AdmissionForm />
        </Route>
        <Route path="/admission/eamcet">
          <AdmissionForm />
        </Route>
        <Route path="/admission/onetoone">
          <AdmissionForm />
        </Route>
        <Route path="/admission/onetosix">
          <AdmissionForm />
        </Route>
        <Router path="/revamp24-classes-for-kcet-2023">
          <KcetPage/>
        </Router>

        <Route path="/blog">
          <Blog/>
        </Route>

        <Route path="/enquire">
          <EnquireForm/>
        </Route>

        <Route path="/blog-content">
          <BlogContent/>
        </Route>
        
        <Route path="" component={NotFound} />
      </Switch>
    </Router>
  );
}

export default App;
