import React, { useState } from 'react'
import { IoIosArrowDown } from 'react-icons/io'
import './QuestionCard.scss'

const QuestionCard = ({ question, answer, widthParameter, isList }) => {
    const [isActive, setIsActive] = useState(false)

    const questionExpand = () => {
        setIsActive(!isActive)
    }

    return (
        <div className={"question_card " + widthParameter}>
            <div className="question">
                <h6>{question}</h6>
                <IoIosArrowDown className={isActive ? "down_arrow down_arrow_rotate" : "down_arrow"} onClick={questionExpand} />
            </div>
            <div className={isActive ? "answer answerActive" : "answer"}>
                {
                    isList ?
                        <ul>
                            {
                                answer.map((i,k)=>(
                                  <li key={k}>{i}</li>  
                                ))
                            }
                        </ul>
                    :
                        <p>
                            {answer}
                        </p>
                }

            </div>
        </div>
    )
}

export default QuestionCard
