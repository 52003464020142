import React from 'react'
import './SpecialityCard.scss'

const SpecialityCards = (props) => {
    return (
        <div className="speciality-card">
            <img src={props.icon} alt=""/>
            <p>{props.speciality}</p>
        </div>
    )
}

export default SpecialityCards;
