import React from 'react'
import { Link } from 'react-router-dom'
import './CourseCard.scss'

const CourseCard = ({ cardTitle, courses, type}) => {
    
    return (
        <div className={"course_card " + type}>
            <h3 className="card-title">{cardTitle}</h3>
                <div className="card-btns">
                {courses.map((course,i )=> (
                    <Link to={"/course/" + course} key={i} className="course_btn">{course}</Link>
                ))}
            </div>
        </div>
    )
}

export default CourseCard
