import React from 'react'
import './Mentorship.scss'
import TrailBtn from '../../TrailButton/TrailBtn'
export default function Mentorship() {
    return (
      <div className="mentorship_container">

         <div className="mentorship_main">

          <div className="mentorship_program">
              
              <img className="mentor_img" src="/images/mentor.png" alt=""/>
              
              <h3 className="mentorship_head">Our eminent teachers act as mentors for the overall growth of students through periodic training and
with their resourcefulness.</h3>

              <p className="mentor_para">
              We offer a 2:1 mentorship program for our students in which each student has one teacher and a
 teaching assistant assigned for them. The teaching assistant will act as a quick personal support system
for the student grievances and confusions. This mentorship program will secure teaching efficiency
without compromising learner’s adaptability.
              </p>
              </div> 
         </div>
         <div className="button_container_mentor">
                <TrailBtn type="btn_trail_why_revamp" children="Book a free trail" btnID="Button_book_a_free_trail"/>
            </div>
      </div>
    )
}
