import React from 'react'
import CentreLandingCard from '../Centre landing card/CentreLandingCard'
import './CentreLanding.scss'
export default function CentreLanding() {
    return (
        <div className="centre-landing">
            <div className="image-container" >
                <img className="main-bg" src="/images/centre tution/center-landing.png" alt="" />
                <h1>Centre <br/> Tuition</h1>
            </div>
            <div className="card-container">
                <h2>How to Join?</h2>
                <div className="cards">
                    <CentreLandingCard
                        image="/images/centre tution/contact.png"
                        heading="1.Contact"
                        paragraph="Contact as via call or online and provide details of the requirements and location."
                    />
                    <CentreLandingCard
                        image="/images/centre tution/asses.png"
                        heading="2.Assess"
                        paragraph="Contact as via call or online and provide details of the requirements and location."
                    />
                    <CentreLandingCard
                        image="/images/centre tution/assign.png"
                        heading="3.Assign"
                        paragraph="Contact as via call or online and provide details of the requirements and location."
                    />
                </div>
            </div>
        </div>
    )
}
