import React from 'react'
import './LandingCard.scss'

export default function LandingCard({cardContent,cardIcon}) {
    return (
        <div className="landing-card">
            <img src={cardIcon} alt=""/>
            <p>{cardContent}</p>
        </div>
    )
}
