import {React, useEffect} from 'react'
import { Link } from 'react-router-dom'
import './CourseCard.scss'

function CompititiveCard({ cardTitle, courses, type}) {
    return (
        <div className={"course_card " + type}>
            <h3 className="card-title">{cardTitle}</h3>
                <div className="card-btns">
                {courses.map((course,i )=> (
                    <Link to={"/competitive-exam/"+course.replace(/\s+/g,"-").toLowerCase()+"-online-coaching"} key={i} className="course_btn">{course}</Link>
                ))}
                </div>
        </div>
    )
}

export default CompititiveCard
