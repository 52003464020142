import React from "react";
import ClassRoomIcons from "./ClassRoom Icons/ClassRoomIcons";
import "./Classroom.scss";
export default function Classroom() {
  return (
    <div className="classroom_container">
      <div className="circle2"></div>
      <h1 className="class_title">Our Classrooms</h1>
      <ClassRoomIcons/>
      <div className="class_deatails">
        <div className="class_deatails_box">
          <h2 className="eight">30k+</h2>
          <p className="impacted">Students Impacted</p>
        </div>
        <div className="class_deatails_box">
          <h2 className="eight">50,000+</h2>
          <p className="impacted">Tutoring Hours</p>
        </div>
        <div className="class_deatails_box">
          <h2 className="eight">600+</h2>
          <p className="impacted">Tutors</p>
        </div>
        <div className="class_deatails_box">
          <h2 className="eight">400 Hrs+</h2>
          <p className="impacted">Minimum Experience Of Our Tutor</p>
        </div>
        <div className="class_deatails_box">
          <h2 className="eight">96%</h2>
          <p className="impacted">Academic Success Rate of Students</p>
        </div>
      </div>
    </div>
  );
}
