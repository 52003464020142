import React from 'react'
import TrailBtn from '../../TrailButton/TrailBtn'
import './BecomeTutorLanding.scss'
import BecomeLandingCard from '../LandingCard/BecomeLandingCard'

export default function BecomeTutorLanding() {
    return (
        <div className="become-tutor-landing">
            <img className="girl_image" src="/images/ameen.png" alt="" />
            <div className="headings">
                <h2 className="become">BECOME A REVAMP24 TEACHER</h2>
                <h1 className="main_tutor_head">
                BE A PART-TIME TEACHER WITH A 
                    <br />
                    FULL-TIME INCOME
                </h1>
                <div className="button_container_tutor">
                    <TrailBtn
                        color="green"
                        type="btn_trail_why_revamp"
                        children="Apply Now"
                        url="https://forms.gle/Yto5Uko8X6Bkrb1H8"
                        style={{ zIndex: 5 }}
                    />
                </div>
            </div>
            <div className="semi-circle-bottom">
                <BecomeLandingCard/>
            
            </div>
        </div>
    )
}
