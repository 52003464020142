import React from 'react'
import CoursePriceCard from './compititive price card/CompititivePriceCard'
import './CompititivePrice.scss'

export default function CombititivePrice({id}) {
    return (
        <div className="compititive-price">
            <h2>Choose your plan</h2>
            <CoursePriceCard compititive={true} id={id}/>
        </div>
    )
}
