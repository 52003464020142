import React, { useEffect } from 'react'
import HomeTutionLanding from './HomeTutionLanding/HomeTutionLanding'
import HomeTutionWorking from './HomeTutionWorking/HomeTutionWorking'
import HomeTutionAbout from './Home tution about/HomeTutionAbout'
import HomeTutionChoose from './Home Tution choose/HomeTutionChoose'
import Header from '../../CampaignPage/Header/Header'
import Footer from '../Footer/Footer'
import { Helmet } from 'react-helmet'

export default function HomeTution() {
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])
    return (
        <>
            <Helmet>
                <title>Revamp24 | Home Tution</title>
            </Helmet>
            <Header type="fixed"/>
            <HomeTutionLanding/>
            <HomeTutionWorking/>
            <HomeTutionAbout/>
            <HomeTutionChoose/>
            <Footer/>
        </>
    )
}
