import { React, useState, useEffect } from 'react'
import './Recommendation.scss'
import {instance_url} from '../../../../Assets/config'

export default function RecommendationCard({ Category, }) {
    const [recomended, setRecomended] = useState('')
    console.log(recomended);
    const recommedation = async () => {
        const rc = await fetch(`${instance_url}/blog?category=${Category}&limit=3&page=1`)
        const data = await rc.json()
        setRecomended(data.blogs)
    }
    useEffect(() => { recommedation() },[])
    return (
        <div className="card_container">

            <div className="card_main">
                <div className="card-head">
                <h5>
                     <img src="/images/Blog/Blog-content/recmd.svg" alt="" />
                     RECOMMENDED CONTENT  
                </h5>
                </div>
                {recomended && recomended.map((item) => (
                    <div key={item._id} className="thumbnail">
                        <img src={item.image.image_url} alt="" />
                    <a href={`/blog/single/${item._id}`}>{item.title}</a>
                    </div>
                ))}
            </div>

        </div>
    )
}
