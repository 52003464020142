import React from 'react'
import './HomeTutionLandingCard.scss'

export default function HomeTutionLandingCard({icon,content}) {
    return (
        <div className="home-tution-landing-card">
            <img src={icon} alt=""/>
            <p>{content}</p>
        </div>
    )
}
