import React, { useEffect } from 'react'
import CompititiveLanding from './Compititive Landing/CompititiveLanding'
import Navbar from '../Navbar/Navbar'
import TutorProfile from '../Courses/Tutor Profile/TutorProfile'
import Footer from '../Footer/Footer'
import CompititivePrice from './compititivePrice/CombititivePrice'
import { useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useState } from 'react'
import remarkGfm from 'remark-gfm'
import ReactMarkdown from 'react-markdown'
import { competitiveData } from '../../../Assets/CompetitiveData/Data'
import './competitivestyle.scss'

export default function CompititiveExam(props) {
    const id = props.match.params.id
    const history = useHistory()
    const [head, setHead] = useState("")
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [keywords, setKeywords] = useState('');
    const [meta, setMeta] = useState({});

    useEffect(() => {
        checkParams(id)
        window.scrollTo(0, 0);
    }, [id])

    const checkParams = (id) => {
        let newParam = id.replace("-online-coaching", "").toUpperCase();
        newParam = newParam.replace(/-/g, " ")
        setHead(newParam)
        switch (newParam) {
            case "JEE":
            case "NEET":
            case "COMEDK":
            case "PESSAT":
            case "KCET":
            case "TS EAMCET":
            case "AP EAMCET":
            case "MHTCET":
                history.push("/competitive-exam/" + id)
                break;
            default:
                history.push("/select/compititive")
        }
        switch (newParam) {
            case "JEE":
                setTitle('JEE  Online Coaching | JEE Coaching in Bangalore - Revamp24');
                setDescription('Revamp24 is a e-learning platform that provides best JEE online coaching and LIVE face-to-face JEE Coaching In Bangalore. Enroll for online JEE coaching with the best tutors');
                setKeywords('JEE  Online Coaching,JEE Coaching in Bangalore,Best JEE online coaching,JEE Coaching In Bangalore')
                break;
            case "NEET":
                setTitle('NEET  Online Coaching | NEET Coaching in Bangalore - Revamp24');
                setDescription('Revamp24 is a e-learning platform that provides best NEET online coaching and LIVE face-to-face NEET Coaching In Bangalore. Enroll for online NEET coaching with the best tutors');
                setKeywords('NEET  Online Coaching,NEET Coaching in Bangalore,Best NEET online coaching,NEET Coaching In Bangalore')
                break;
            case "COMEDK":
                setTitle('COMEDK  Online Coaching | COMEDK Coaching in Bangalore - Revamp24');
                setDescription('Revamp24 is a e-learning platform that provides best COMEDK online coaching and LIVE face-to-face COMEDK Coaching In Bangalore. Enroll for online COMEDK coaching with the best tutors');
                setKeywords('COMEDK  Online Coaching,COMEDK Coaching in Bangalore,Best COMEDK online coaching,COMEDK Coaching In Bangalore')

                break;
            case "PESSAT":
                setTitle('PESSAT  Online Coaching | PESSAT Coaching in Bangalore - Revamp24');
                setDescription('Revamp24 is a e-learning platform that provides best PESSAT online coaching and LIVE face-to-face PESSAT Coaching In Bangalore. Enroll for online PESSAT coaching with the best tutors');
                setKeywords('PESSAT  Online Coaching,PESSAT Coaching in Bangalore,Best pessat online coaching,PESSAT Coaching In Bangalore')
                break;
            case "KCET":
                setTitle('KCET  Online Coaching | CET Coaching in Bangalore - Revamp24');
                setDescription('Revamp24 is a e-learning platform that provides best KCET online coaching and LIVE face-to-face CET Coaching In Bangalore. Enroll for online KCET coaching with the best tutors');
                setKeywords('KCET  Online Coaching,CET Coaching in Bangalore,Best kcet online coaching,CET Coaching In Bangalore')
                break;
            case "TS EAMCET":
                setTitle('TS EAMCET Online Coaching Classes- Revamp 24');
                setDescription('Crack TS EAMCET exam with revamp24 tutors and get TS EAMCET preparation tips. Attend Live ts eamcet online coaching classes with personal attention.');
                setKeywords('ts eamcet online coaching, ts eamcet online coaching in bangalore');
                break;
            case "AP EAMCET":
                setTitle('AP EAMCET online coaching- Revamp 24');
                setDescription("Crack AP EAMCET exam with revamp24 tutors and get AP EAMCET preparation tips. Attend Live AP eamcet online coaching classes with personal attention.");
                setKeywords('AP eamcet online coaching');
                break;
            case "MHTCET":
                setTitle('MHT CET Online Coaching Classes- Revamp24');
                setDescription('Revamp24 provides Live Online Classes for MHT CET. Attend live interactive MHT CET online coaching with best tutors in Bangalore');
                setKeywords('MHT CET online coaching');
                break;
            default:
            // history.push("/select/compititive")
        }
    }
    return (
        <>
            <Helmet>
                <title>{title}</title>
                <meta name="keywords" content={keywords} />
                <meta name="description" content={description} />
            </Helmet>
            {
                id
                &&
                <>
                    <Navbar />
                    <CompititiveLanding id={head} />
                    <div className="data" >
                        <ReactMarkdown remarkPlugins={[remarkGfm]} >{competitiveData[head]}</ReactMarkdown>
                    </div>
                    <CompititivePrice id={head} />
                    <TutorProfile />
                    <Footer />
                </>
            }
        </>
    )
}
