import React from 'react'
import Features from './Features/Features'
import AboutLanding from './About Landing/AboutLanding'
import Map from './Map/Map'
import RevampAtWork from './Revamp at work/RevampAtWork'
import JoinPart from './Join part/JoinPart'
import HowRevamp from '../WhyRevamp/HowRevamp/HowRevamp'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import AttachedBookingButton from '../AttachedBookingButton/AttachedBookingButton'
import { Helmet } from 'react-helmet'

export default function AboutUs() {

    return (
       <div className="aboutus_container">
            <Helmet>
                <title>Revamp24 | About Us</title>
            </Helmet>
            <Navbar loginRemove={true} trialRemove={true}/>
            <AboutLanding/>
            <Features/>
            <Map/>
            <HowRevamp headingInvisible={true}/>
            <RevampAtWork/>
            <JoinPart/>
            <AttachedBookingButton/>
            <Footer/>
        </div>
    )
}
