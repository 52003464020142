import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Header from '../../CampaignPage/Header/Header'
import Footer from '../Footer/Footer'
import Exams from './Exams/Exams'
import NewWay from './NewWay/NewWay'
import OnlineLanding from './OnlineLanding/OnlineLanding'
import OnlineSubject from './OnlineSubject/OnlineSubject'
import RevampWorking from './RevampWorking/RevampWorking'

export default function OnlineTuition() {
    useEffect(() => {
        window.scrollTo(0,0)
    }, [])
    return (
        <>
        <Helmet>
            <title>Revamp24 | Online Tution</title>
        </Helmet>
        <Header type="fixed"/>
        <OnlineLanding/>
        <RevampWorking/>
        <NewWay/>
        <OnlineSubject/>
        <Exams/>
        <Footer/>
        </>
    )
}
