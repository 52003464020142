import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom';
import './BookingForm.scss';
import { GoChevronDown } from 'react-icons/go'
import { useDispatch } from 'react-redux';
import actions from '../../../../../Assets/dataStore/dataStoreAction';
import { GoogleSpreadsheet } from 'google-spreadsheet'


const BookingForm = () => {
    const [drop, setDrop] = useState(false)
    const [formData, setFormData] = useState(null)
    const [boardDrop, setBoardDrop] = useState(false)
    const [mobileVerified, setMobileVerified] = useState(true)
    const [classAndBoard, setClassAndBoard] = useState(true)
    const [countryActive,setCountryActive] = useState(false)
    const history = useHistory()

    const {
        REACT_APP_LANDING_SPREADSHEET_ID,
        REACT_APP_PRIVATE_KEY,
        REACT_APP_CLIENT_EMAIL
    } = process.env

    const dispatch = useDispatch();

    const doc = new GoogleSpreadsheet(REACT_APP_LANDING_SPREADSHEET_ID);

    const appendSpreadsheet = async (row) => {
        try {
            await doc.useServiceAccountAuth({
                client_email: REACT_APP_CLIENT_EMAIL,
                private_key: REACT_APP_PRIVATE_KEY,
            });
            await doc.loadInfo();
            const sheet = doc.sheetsById["0"];
            const result = await sheet.addRow(row);
            return (result)
        } catch (e) {
            console.error('Error: ', e);
        }
    };
    const handleDropDown = () => {
        setDrop(!drop)
    }
    const handleBoardDropDown = () => {
        setDrop(false)
        setBoardDrop(!boardDrop);
    }
    const handleBoardSelect = (event) => {
        document.getElementById('board').innerHTML = event.target.id
        setFormData({ ...formData, board: event.target.id });
        setBoardDrop(!boardDrop);
    }
    const handleGradeSelect = (event) => {
        setDrop(!drop)
        setFormData({ ...formData, grade: event.target.id })
        document.getElementById('grade').innerHTML = event.target.id + " Grade";
    }
    const handleInput = (event) => {
        const input = event.target.value
        if (input !== "") {
            setFormData({ ...formData, [event.target.name]: input[0].toUpperCase() + input.slice(1) })
        }
        if (event.target.id === "parent-phone") {
            var phoneno = /^\d*$/;
            if (event.target.value.match(phoneno)) {
                setMobileVerified(true)
            } else {
                setMobileVerified(false)
            }
        }
    }
    const handleMobile = (event) =>{
        const countryCode = document.querySelector(".cc").innerHTML
        const mobile = countryCode+'-'+event.target.value

            var phoneno = /^\d*$/;
            if (event.target.value.match(phoneno)) {
               
                setMobileVerified(true)
            } else {
                setMobileVerified(false)
                
            }
    
        setFormData({...formData,parentPhone:mobile})
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        var grade = document.getElementById('grade').innerHTML;
        var board = document.getElementById('board').innerHTML;
        if (grade === "Class") {
            setClassAndBoard(false)
        } else if (board === "Board") {
            setClassAndBoard(false)
        }
        else {
            setClassAndBoard(true)
            if (!formData.hasOwnProperty('haveLaptop')) {
                formData.haveLaptop = "yes"
            }
            submitToLeadsquare(formData);
            dispatch(actions.storeData(formData))
            appendSpreadsheet(formData)
            history.push("/schedule-class-now")
        }
    }

    const Attributes = [
        "mx_Parent_Name",
        "EmailAddress",
        "Phone",
        "mx_Child_Name",
        "mx_Board",
        "mx_Class",
        "mx_Have_Laptop",
        "Source",
        "mx_Subjects",
        "SearchBy"
    ]

    const extra = {
        "Attribute": "Source",
        "Value":"Direct Traffic",
    }
    
    const submitToLeadsquare = (data) => {
        console.log(data);
        let Attribute = Attributes
        let keys = Object.keys(data)
        let newData = []
        for(let i=0;i<keys.length;i++){
            let key = keys[i]
            let att = Attribute[i]
            let obj = {
                "Attribute": att,
                "Value": formData[key]
            }
    
            newData.push(obj);
        }
        newData.push(extra)

        console.log(newData);
        fetch(`https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$rd69b0fe442bd5b19652e003f57e67e88&secretKey=374b41196f0daadc114e42b74925541df1e03776`,{
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
            body: JSON.stringify(newData)
        }).then(res => {
            console.log(res);
        })
    }

    const countrySelect = (event) =>{
        document.querySelector('.cc').innerHTML = event.target.innerHTML
        setFormData({...formData,parentPhone:event.target.innerHTML+'-'+document.querySelector('#parent-phone').value})
    }
    return (
        <form id="Organic_Trialclass_Form" className="booking-form" onSubmit={handleSubmit}>
            <h2>Book A FREE Trial Class Today</h2>
            <input type="text" name="parentName" id="parent-name" required placeholder="Parent Name" onChange={handleInput} />
            <input type="email" name="parentEmail" id="parent-email" required placeholder="Parent Email" onChange={handleInput} />
            <div className="mobile-field">
                <div className="country-code" onClick={()=>setCountryActive(!countryActive)}>
                    <p className="cc">+971 </p>
                    <GoChevronDown />
                    <ul className={countryActive?"countries countries-drop":"countries"}>
                        <li onClick={countrySelect}>+971</li>
                        <li onClick={countrySelect}>+91</li>
                        <li onClick={countrySelect}>+965</li>
                        <li onClick={countrySelect}>+974</li>
                        <li onClick={countrySelect}>+966</li>
                        <li onClick={countrySelect}>+968</li>
                        <li onClick={countrySelect}>+973</li>
                    </ul>
                </div>
                <input type="tel" name="parentPhone" id="parent-phone" required placeholder="Parent Phone" onChange={handleMobile} />
            </div>
            <p className={mobileVerified ? "wrong-mobile inactive" : "wrong-mobile"}>Enter valid Mobile number *</p>
            <input type="text" name="childName" id="child-name" required placeholder="Child Name" onChange={handleInput} />
            <div className="drops">
                <div className="select">
                    <div className="select-input" onClick={handleBoardDropDown}>
                        <label htmlFor="" id="board">Board</label>
                        <GoChevronDown className={boardDrop ? "down-icon tilt" : "down-icon"} />
                    </div>
                    <ul className={boardDrop ? "select-list" : "select-list shrink"}>
                        {boardDrop &&
                            <>
                                <li onClick={handleBoardSelect} id="CBSE">CBSE</li>
                                <li onClick={handleBoardSelect} id="ICSE">ICSE</li>
                                <li onClick={handleBoardSelect} id="NEET">NEET</li>
                                <li onClick={handleBoardSelect} id="JEE">JEE</li>
                                <li onClick={handleBoardSelect} id="PUC">PUC</li>
                                <li onClick={handleBoardSelect} id="ISC">ISC</li>
                                <li onClick={handleBoardSelect} id="IGCSE">IGCSE</li>
                                <li onClick={handleBoardSelect} id="GCSE">GCSE</li>
                                <li onClick={handleBoardSelect} id="Other Board">Other Board</li>
                            </>
                        }
                    </ul>
                </div>
                <div className="select">
                    <div className="select-input" onClick={handleDropDown}>
                        <label htmlFor="" id="grade">Class</label>
                        <GoChevronDown className={drop ? "down-icon tilt" : "down-icon"} />
                    </div>
                    <ul className={drop ? "select-list" : "select-list shrink"}>
                        {drop &&
                            <>  
                               <li onClick={handleGradeSelect} id="lkg">lkg</li>
                               <li onClick={handleGradeSelect} id="ukg">ukg</li>
                               <li onClick={handleGradeSelect} id="1st">1st</li>
                              <li onClick={handleGradeSelect} id="2nd">2nd</li>
                             <li onClick={handleGradeSelect} id="3rd">3rd</li>
                                <li onClick={handleGradeSelect} id="4th">4th</li>
                                 <li onClick={handleGradeSelect} id="5th">5th</li>
                                <li onClick={handleGradeSelect} id="6th">6th</li>
                                <li onClick={handleGradeSelect} id="7th">7th</li>
                                <li onClick={handleGradeSelect} id="8th">8th</li>
                                <li onClick={handleGradeSelect} id="9th">9th</li>
                                <li onClick={handleGradeSelect} id="10th">10th</li>
                                <li onClick={handleGradeSelect} id="11th">11th</li>
                                <li onClick={handleGradeSelect} id="12th">12th</li>
                            </>
                        }
                    </ul>
                </div>
            </div>
            <p className={classAndBoard ? "cb-error inactive" : "cb-error"}>Board and class is required!</p>
            <label htmlFor="have-laptop" className="radio-label">Do you have laptop?</label>
            <div className="radios">
                <input type="radio" name="haveLaptop" required value="yes" id="yes" checked onChange={handleInput} />
                <label htmlFor="Yes">Yes</label>
                <input type="radio" name="haveLaptop" required value="no" id="no" onChange={handleInput} />
                <label htmlFor="no">No</label>
            </div>
            <p className="hurry-text">HURRY ! Only 16 slots left</p>
            <button id="Organic_Trialclass_FormbButton" type="submit">BOOK A FREE CLASS</button>
            <Link to="/privacy-policy" className="agreement"><span>By registering,I agree to</span> Revamp24's Privacy Policy</Link>
        </form>
    )
}

export default BookingForm;
