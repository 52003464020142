import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import CourseLanding from './CourseLanding/CourseLanding'
import CoursePricing from './CoursePricing/CoursePricing'
import './Courses.scss'
import TutorProfile from './Tutor Profile/TutorProfile'

const Courses = (props) => {
    const  id  = props.match.params.id
    const history = useHistory()

    useEffect(() => {
        checkParams(id)
        window.scrollTo(0,0)
    }, [])

    const checkParams = (id) => {
        switch (id) {
            case "Class 5":
            case "Class 6":
            case "Class 7":
            case "Class 8":
            case "Class 9":
            case "Class 10":
            case "Class 11":
            case "Class 12":
                history.push("/course/" + id)
                break;
            default:
                history.push("/select/class")
        }
    }

    return (
        <>
            <Helmet>
                <title>Revamp24 | {id}</title>
            </Helmet>
            {
                id
                &&
                <div className="course">
                    <Navbar navImageVisible={true} loginRemove={true} trialBtnColor="blue-gradient" />
                    <CourseLanding id={id}/>
                    <CoursePricing id={id}/>
                    {/* <VedioSection/> */}
                    <TutorProfile />
                    <Footer />
                </div>
            }
        </>
    )
}

export default Courses
