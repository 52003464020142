import React from 'react'
import './InfoLink.scss'
import { IoMdArrowDropright } from 'react-icons/io'
import { Link } from 'react-router-dom'

const InfoLink = ({link,children}) => {
    return (
        <>
           <Link className="info_link" to={link}><IoMdArrowDropright className="footer_info_arrow"/>{children}</Link> 
        </>
    )
}

export default InfoLink
