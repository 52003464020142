import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../../../Assets/dataStore/dataStoreAction';
import './SelectDateButton.scss'


const SelectDateButton = (props) => {
    const dispatch = useDispatch();
    const essentials = useSelector(state => state.essential.essential)
    const handleDateClick = (day, week, month, id, con) => {
        var isOne = 0, isTwelve = 0, isTwo = 0, isThree = 0, isFour = 0, isFive = 0, isSix = 0, isSeven = 0, isEight = 0
        if (essentials.logs) {
            switch (con) {
                case "dayOne":
                    for (var i = 0; i < essentials.logs.dayOne.length; i++) {
                        switch (essentials.logs.dayOne[i]) {
                            case "12 :00 PM":
                                isTwelve++
                                break;
                            case "01 :00 PM":
                                isOne++;
                                break;
                            case "02 :00 PM":
                                isTwo++
                                break
                            case "03 :00 PM":
                                isThree++
                                break
                            case "04 :00 PM":
                                isFour++
                                break;
                            case "05 :00 PM":
                                isFive++
                                break;
                            case "06 :00 PM":
                                isSix++;
                                break;
                            case "07 :00 PM":
                                isSeven++;
                                break;
                            case "08 :00 PM":
                                isEight++;
                                break;
                            default:
                                return null
                        }
                    }
                    break;
                case "dayTwo":
                    for (var k = 0; k < essentials.logs.dayTwo.length; k++) {
                        switch (essentials.logs.dayTwo[k]) {
                            case "12 :00 PM":
                                isTwelve++
                                break;
                            case "01 :00 PM":
                                isOne++;
                                break;
                            case "02 :00 PM":
                                isTwo++
                                break
                            case "03 :00 PM":
                                isThree++
                                break
                            case "04 :00 PM":
                                isFour++
                                break;
                            case "05 :00 PM":
                                isFive++
                                break;
                            case "06 :00 PM":
                                isSix++;
                                break;
                            case "07 :00 PM":
                                isSeven++;
                                break;
                            case "08 :00 PM":
                                isEight++;
                                break;
                            default:
                                return null
                        }
                    }
                    break;
                case 'dayThree':
                    for (var j = 0; j < essentials.logs.dayThree.length; j++) {
                        switch (essentials.logs.dayThree[j]) {
                            case "12 :00 PM":
                                isTwelve++
                                break;
                            case "01 :00 PM":
                                isOne++;
                                break;
                            case "02 :00 PM":
                                isTwo++
                                break
                            case "03 :00 PM":
                                isThree++
                                break
                            case "04 :00 PM":
                                isFour++
                                break;
                            case "05 :00 PM":
                                isFive++
                                break;
                            case "06 :00 PM":
                                isSix++;
                                break;
                            case "07 :00 PM":
                                isSeven++;
                                break;
                            case "08 :00 PM":
                                isEight++;
                                break;
                            default:
                                return null
                        }

                    }
                    break;
                default:
                    return null;
            }
            var timeAvailibility = [isTwelve, isOne, isTwo, isThree, isFour, isFive, isSix, isSeven, isEight]
            dispatch(actions.timesAvailibility(timeAvailibility))
        }

        if (essentials.hasOwnProperty('date')) {
            if (essentials.date === day + "-" + week + "-" + month) {
                dispatch(actions.deleteDate())
            } else {
                dispatch(actions.storeDate(day + "-" + week + "-" + month))
            }
        } else {
            dispatch(actions.storeDate(day + "-" + week + "-" + month))
        }

        switch (id) {
            case 1:
                document.getElementById('1').classList.toggle('active')
                document.getElementById('2').classList.remove("active")
                document.getElementById('3').classList.remove("active")
                break;
            case 2:
                document.getElementById('2').classList.toggle('active')
                document.getElementById('1').classList.remove("active")
                document.getElementById('3').classList.remove("active")
                break;
            case 3:
                document.getElementById('3').classList.toggle('active')
                document.getElementById('2').classList.remove("active")
                document.getElementById('1').classList.remove("active")
                break;
            default:
                break;
        }

    }
    return (
        <div className="date-button" id={props._id} >
            <div className="overlay" onClick={() => handleDateClick(props.day, props.week, props.month, props._id, props.dayNum)}></div>
            <p className="month">{props.month}</p>
            <p className="day">{props.day}</p>
            <p className={"week " + props.disable}>{props.week}</p>
        </div>
    )
}

export default SelectDateButton;
