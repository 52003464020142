import React from 'react'
import FrequentQuestions from '../../FrequentQuestions/FrequentQuestions'
import './JoinPart.scss'

function JoinPart() {
    return (
        <div className="join_part">
            <div className="left_section">
                <div className="join_card">
                    <h6>Join us by writing to <br/> <a href="mailto:careers@revamp24.com">careers@revamp24.com</a></h6>
                    <p> Remember to tell us if you’d like to join us as a teacher or if you’d prefer to work behind the scenes.</p>
                </div>
            </div>
            <div className="right_section">
                <FrequentQuestions bgBlur={true} widthParameter="w-100" marginParameter="m-0"/>
            </div>
        </div>
    )
}

export default JoinPart
