
const coursePrice = (state = {price:0,plan: "Lite"},action) =>{

    switch(action.type){
        case "ADD_SUBJECT":

            var price
            state.hasOwnProperty('price')? 
                price = parseInt(state.price) + parseInt(action.payload)
                    :price = parseInt(action.payload)
            
            state = {...state,price:price}
            return state
     
            
        case "SUBTRACT_SUBJECT":
            var subtractPrice = state.price - action.payload
            
            if(subtractPrice===0){
                subtractPrice = "000"
            }
            state = {...state,price :subtractPrice }

            return state

        case "PLAN_SELECT":
            state = {...state,plan: action.payload}
            return state
        default:
            return state
    }
} 

export default coursePrice;