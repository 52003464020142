import React from 'react'
import { Link } from 'react-router-dom'
import './NotFound.scss'
export default function NotFound() {
    return (
        <div className="not-found">
            <img src="/images/page not found/page-not-found.jpg" alt=""/>
            <h2>Page Not Found!</h2>
            <Link to="/">Go back to home</Link>
        </div>
    )
}

