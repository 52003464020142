import React, { useContext, useEffect, useState } from 'react'
import { EssentialContext } from '../../../Assets/EssentialContext'
import VideoCard from './VideoCard/VideoCard'
import './VideoSection.scss'

export default function VideoSection() {
    const [dotActiveness, setDotActiveness] = useState('first-dot-active')
    const [transition, setTransition] = useState('')
    const [swipeStart, setSwipeStart] = useState(0)
    const [essentials, setEssentials] = useContext(EssentialContext)
    const [trigger, setTrigger] = useState(false)

    
    useEffect(() => {
        setTimeout(()=>{
            setTrigger(!trigger)
        },3000)

        trigger && slideVideoCard()

    }, [trigger]);
    const slideVideoCard = () =>{
        if (!essentials.videoPlaying) {
                if (transition === "") {
                    setTransition("t-100")
                    setDotActiveness('second-dot-active')
                } else if (transition === "t-100") {
                    setTransition("t-200")
                    setDotActiveness("third-dot-active")
                } else {
                    setDotActiveness("first-dot-active")
                    setTransition("")
                }
        }
    }
    const sliderChange = event => {
        switch (event.target.id) {
            case "dot-1":
                setDotActiveness('first-dot-active')
                setTransition("");
                break;
            case "dot-2":
                setDotActiveness('second-dot-active')
                setTransition("t-100");
                break;
            case "dot-3":
                setDotActiveness('third-dot-active')
                setTransition("t-200");
                break;
            default:
                setTransition("")
                break;
        }
    }
    const onSwipe = (event) => {
        let swipeEndPosition = event.clientX || event.changedTouches[0].clientX;
        if (window.innerWidth <= 700) {
            if (swipeStart > swipeEndPosition) {
                switch (transition) {
                    case "":
                        setTransition("t-100")
                        setDotActiveness('second-dot-active')
                        break;
                    case "t-100":
                        setTransition("t-200");
                        setDotActiveness('third-dot-active')
                        break;
                    default:
                        setTransition(transition)
                }
            } else if (swipeStart < swipeEndPosition) {
                switch (transition) {
                    case "t-200":
                        setTransition("t-100");
                        setDotActiveness('second-dot-active')
                        break;
                    case "t-100":
                        setTransition('');
                        setDotActiveness('first-dot-active')
                        break;
                    default:
                        setTransition(transition)
                }
            }
        }
    }
    return (
        <div className="video-section">
            <h2 className="video-heading">WATCH VIDEO LESSONS</h2>
            <div className={"video-slider " + transition} onTouchStart={event => { setSwipeStart(event.touches[0].clientX) }}
                onTouchEnd={onSwipe}
            >
                <div className="video-row-wrapper">
                    <VideoCard
                        videoId="videoOne"
                        thumbnail="/images/KCET/videoSection/physics-1.jpeg"
                        video="https://firebasestorage.googleapis.com/v0/b/revamp24kcet.appspot.com/o/physics1.mp4?alt=media&token=c05f60cc-97d8-4be9-97fa-f733ac8fb82c"
                    />
                    <VideoCard
                        thumbnail="/images/KCET/videoSection/maths-1.jpeg"
                        video="https://firebasestorage.googleapis.com/v0/b/revamp24kcet.appspot.com/o/Maths1.mp4?alt=media&token=d13c7b11-f009-45b3-9112-950212a618b3"
                        videoId="videoTwo"
                    />
                    <VideoCard
                        thumbnail="/images/KCET/videoSection/chemistry-1.jpeg"
                        videoId="videoThree"
                        video="https://firebasestorage.googleapis.com/v0/b/revamp24kcet.appspot.com/o/Chemistry1.mp4?alt=media&token=0f9d4e28-78ba-4455-9804-8065f6c63d48"
                    />
                </div>
                <div className="video-row-wrapper">
                    <VideoCard
                        videoId="videoFour"
                        thumbnail="/images/KCET/videoSection/physics-2.jpeg"
                        video="https://firebasestorage.googleapis.com/v0/b/revamp24kcet.appspot.com/o/physics2.mp4?alt=media&token=b52b4d94-7dd7-4800-8cdb-d8e8ec2dadf4"
                    />
                    <VideoCard
                        videoId="videoFive"
                        thumbnail="/images/KCET/videoSection/maths-2.jpeg"
                        video="https://firebasestorage.googleapis.com/v0/b/revamp24kcet.appspot.com/o/Maths2.mp4?alt=media&token=57b7b63b-d663-48e1-8bee-dd8659d4eecb"
                    />
                    <VideoCard
                        videoId="videoSix"
                        thumbnail="/images/KCET/videoSection/chemistry-2.jpeg"
                        video="https://firebasestorage.googleapis.com/v0/b/revamp24kcet.appspot.com/o/Chemistry2.mp4?alt=media&token=2592f06e-12ab-43d2-b825-62323391989e"
                    />
                </div>
            </div>
            <div className="slider-dot">
                <div className={"dot " + dotActiveness} id="dot-1" onClick={sliderChange}></div>
                <div className={"dot " + dotActiveness} id="dot-2" onClick={sliderChange}></div>
                <div className={"dot " + dotActiveness} id="dot-3" onClick={sliderChange}></div>
            </div>
        </div>
    )
}
