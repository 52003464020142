import React from 'react'
import './FiveStepCard.scss'
export default function FiveStepCard({left, cardContent, cardHeading, leftImage, rightImage,color, cardNumber}) {
    return (
        <div className="five-step-card">
            {
                left &&
                <div className="icon-tag icon-tag-left">
                    <div className={"rectangle rectangle-left bg-"+color}>
                        <img src={leftImage} className="icon-left" alt=""/>
                        <img src="/images/triangle.png" className="triangle triangle-left" alt="" />
                    </div>
                </div>
            }
            <div className={"card-content card-content-"+left+" text-"+color}>
                <div className="paragraph">
                <h6>{cardHeading}</h6>
                <p>
                    {cardContent}
                </p>
                </div>
                <h1 className={"selection-number selection-number-"+left}>{cardNumber}</h1>
            </div>
            {
                !left &&
                <div className="icon-tag">
                    <div className={"rectangle bg-"+color}>
                        <img src="/images/triangle.png" className="triangle" alt="" />
                        <img src={rightImage} className="icon-right" alt=""/>
                    </div>
                </div>
            }
        </div>
    )
}
