import React from 'react'
import './ResultCard.scss'
export default function ResultCard({quotes, rankIn , name, rankHolderImage}) {
    return (
        <div className="result-card">
            <div className="rank-holder-img" style={{backgroundImage:`url(${rankHolderImage})`}}></div>
            <h6 className="rank-in">{rankIn}</h6>
            <h6 className="rank-holder-name">{name}</h6>
            <p>
               {quotes}
            </p>
        </div>
    )
}
