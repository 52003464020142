import React from 'react'
import { useHistory } from 'react-router-dom'
import './TrailBtn.scss'

const TrailBtn = ({children,type,color,link,url,windowOpen,onClick,btnID}) => {
    const history = useHistory()
    const handleClick = () => {
        if(link){
            history.push("/"+link)
        }else if(url){
            window.location.href = url
        }else if(windowOpen){
            window.open(windowOpen)
        }
    }
 
    return (
        <button className={type+" "+color} id={btnID} onClick={handleClick}> 
            {children}
        </button>
        
    )
}

export default TrailBtn
