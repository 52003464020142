import React from "react";
import "./Innovative.scss";
import TrailBtn from "../../TrailButton/TrailBtn";
import Methodology from "./methodology/Methodology";
export default function Innovative() {
  return (
    <div className="innovative_container">
      <div className="innovative_main">
        <p>
          Revamp24 Method of teaching is unique and experimentally proven with
          the best results. We follow A four-step Revamp24 Method for every
          class.
        </p>
      </div>
      <div className="methodology">
        <img className="meth" src="/images/meth.png" alt="" />
        <p className="center_head">Innovative Methodology</p>
       
       <Methodology
        head="Periodic Assessments And Feedbacks"
        para=" We ensure interactive periodic assessments of the learning profile of
        students through well curated tests and otherwise. Students will be
        able to correct themselves at the root of mistakes helping them to
        improve with time"
        ClassName="methodology_content"
       />
             <Methodology
        head="Evaluation of learning aptitude"
        para="We begin with a free demo class to assess the learning aptitude of the students and assign groups
        of students with a similar pace of learning. This helps in designing effective teaching strategies
        and for the better emotional health of the students"
        ClassName="evaluation_content"
       />
              <Methodology
        head="Customized experience"
        para="Each student receives one to one attention in the classroom that will help them identify their
        specific problem areas and solutions. The teachers will assist the students in their unique learning
        systems without compromising the results."
        ClassName="experiance_content"
       />
              <Methodology
        head="Active style of learning"
        para="Revamp24 Method uses simulations and real-life examples to teach concepts aiding in a better
        understanding of the topics. Our classes are through live sessions and the students are
        encouraged to ask questions to obtain conceptual clarity."
        ClassName="learning_content"
       />
       
      </div>

      <div className="button_container_innovative">
        <TrailBtn
          color="red"
          type="btn_trail_why_revamp"
          children="Book a free trail"
          btnID="Button_book_a_free_trail"
          link="book-a-free-trial"
        />
      </div>
    </div>
  );
}
