import React from 'react'
import ResultCard from './ResultCard/ResultCard'
import './ResultSection.scss'

export default function ResultSection() {
    return (
        <div className="result-section" id="results">
            <h2 className="result-heading">INCREDIBLE RESULT IN KCET 2020</h2>
            <div className="result-card-slider">
                <div className="result-card-wrapper">
                    <ResultCard
                        quotes=" “ Good to clear my concepts on various topics. It was extremely helpful for my preparation of KCET. Great teaching 👍 “"
                        rankIn="KCET RANK 248"
                        name="Aripra | Bangalore,Karnataka"
                        rankHolderImage="/images/KCET/result/aripra.jpeg"
                    />
                </div>
            </div>
        </div>
    )
}
