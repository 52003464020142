import React from 'react'
import './CourseDetails.scss'
import { TiTick } from 'react-icons/ti';

const CourseDetails = ({compititive, heading}) => {
    const handleClick = (event) =>{
        event.target.classList.add('.btn_active')
    }
    return (
        <div className="course_details_container">
            <div className="course_btns">
                <p className="btn_active" onClick={handleClick}>{!compititive?"CBSE":heading}</p>
                {
                    !compititive
                    &&
                    <>
                        <p >ICSE</p>
                        <p >OTHERS</p>
                        <hr/>
                    </>
                }
            </div>
            <div className="course_benefits">
                <h6>Benefits Of <span>Revamp24</span> Classes</h6>
                <ul >
                    <li><TiTick className="tick"/>Personalized Classes</li>
                    <li><TiTick className="tick"/>Ease of learning</li>
                    <li><TiTick className="tick"/>Expert and experienced teachers</li>
                    <li><TiTick className="tick"/>Effective monitoring for parents</li>
                </ul>
            </div>
        </div>
    )
}

export default CourseDetails
