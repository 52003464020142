import { React, useState } from "react";
import "./FormCard.scss";
import { GoogleSpreadsheet } from "google-spreadsheet";

export default function FormCard() {
  const [formData, setFormData] = useState({});
  const [isSubmited, setIsSubmitted] = useState(false);
  const [error, setError] = useState(false);
  const handleSubmit =async (event) => {
    event.preventDefault();
    appendSpreadsheet(formData);
     submitToLeadsquare(formData);

    if (error) {
      alert("error")
      setError(false)
    }
    else {
      setIsSubmitted(true);
    }

  };


  const Attributes = [
    "FirstName",
    "EmailAddress",
    "Phone",
    "mx_City",
    "Notes",
    "mx_Have_Laptop",
]

const extra = {
    "Attribute": "Source",
    "Value":"CPC",
}

  const submitToLeadsquare = (data) => {
    console.log(data);
    let Attribute = Attributes
    let keys = Object.keys(data)
    let newData = []
    for(let i=0;i<keys.length;i++){
        let key = keys[i]
        let att = Attribute[i]
        let obj = {
            "Attribute": att,
            "Value": formData[key]
        }

        newData.push(obj);
    }
    newData.push(extra)

    console.log(newData);
    fetch(`https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$rd69b0fe442bd5b19652e003f57e67e88&secretKey=374b41196f0daadc114e42b74925541df1e03776`,{
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        body: JSON.stringify(newData)
    }).then(res => {
        console.log(res);
    })
}



  const {
    REACT_APP_PRIVATE_KEY,
    REACT_APP_CLIENT_EMAIL,
    REACT_APP_KCET_SPREADSHEET_ID,
  } = process.env;

  const doc = new GoogleSpreadsheet(REACT_APP_KCET_SPREADSHEET_ID);

  const appendSpreadsheet = async (row) => {
    try {
      await doc.useServiceAccountAuth({
        client_email: REACT_APP_CLIENT_EMAIL,
        private_key: REACT_APP_PRIVATE_KEY,
      });
      await doc.loadInfo();
      console.log(doc.loadInfo());
      const sheet = doc.sheetsById["0"];
      const result = await sheet.addRow(row);
      return result;
    } catch (e) {
      setError(true)
    }
  };

  const handleInputChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  return (
    <div className="form-main">
      {isSubmited ? (
        <div className="thankyou">
          <img src="/images/KCET/Landing/checked.png" alt="" />
          <h1>THANK YOU!</h1>
          <p>
            Your details have been submitted
            <br />
            successfully.
          </p>
        </div>
      ) : (
          <form onSubmit={handleSubmit} id="form_KCET2022_1">
            <h5>
              Revamp24 Classes
            <br />
            The only online classroom program you need to ace
            <br />
            KCET 2023 or 2024
          </h5>
            <input
              name="Name"
              className="inputs"
              placeholder="Student name"
              type="text"
              onChange={handleInputChange}
              required
            />
            <input
              name="Email"
              className="inputs"
              placeholder="Email id"
              type="email"
              onChange={handleInputChange}
              required
            />
            <input
              name="Mobile"
              className="inputs"
              type="text"
              placeholder="Mobile number"
              onChange={handleInputChange}
              required
            />
            <input
              name="City"
              className="inputs"
              type="text"
              placeholder="City"
              onChange={handleInputChange}
              required
            />
            <input
              name="Class"
              className="inputs"
              type="text"
              placeholder="Current Class"
              onChange={handleInputChange}
              required
            />
            <div className="radio-main">
              <label>Do you have Laptop ?</label>
              <div className="radio">
                <input
                  type="radio"
                  value="yes"
                  id="laptop"
                  name="Laptop"
                  onChange={handleInputChange}
                  required
                />
                <label htmlFor="laptop">yes</label>
                <input
                  value="no"
                  type="radio"
                  id="laptop"
                  name="Laptop"
                  onChange={handleInputChange}
                  required
                />
                <label htmlFor="laptop">No</label>
              </div>
            </div>
            <button type="submit" id="KCET2022_Submit_button">Submit</button>
          </form>
        )}
    </div>
  );
}
