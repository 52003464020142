import React from 'react'
import './BecomeLandingCard.scss'

export default function BecomeLandingCard() {
    return (
        <div className="become-landing-card">

            <div className="card-content">
                <img src="/images/cash 1.png" alt="" />
                <div className="card-text">
                    <h6>Earn upto ₹50k/month</h6>
                    <p>By taking remote classes <br/>for 4-5 hrs* a day</p>
                </div>
            </div>

            <div className="card-content">
                <img src="/images/online-meeting.png" alt="" />
                <div className="card-text">
                    <h6>Grades 8 -12</h6>
                    <p>Choose your grade<br/> to teach.</p>
                </div>
            </div>

            <div className="card-content">
                <img src="/images/knowledge.png" alt="" />
                <div className="card-text">
                    <h6>Math or Science</h6>
                    <p>Execute the best lesson plans <br/> with proper assistance.</p>
                </div>
            </div>

        </div>
    )
}
