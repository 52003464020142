import React,{useState,useEffect} from 'react'
import './BlogCategories.scss'
import BlogCard from '../Blog card/BlogCard'
import {Link , useParams} from 'react-router-dom'
import Pagination from './Pagination'
import {useDispatch, useSelector} from 'react-redux'
import { instance_url } from '../../../Assets/config'
import actions from '../../../Assets/dataStore/dataStoreAction'
import NotFound from '../../USER/NotFound/NotFound'

function BlogCategory() {
    const [data , setData] = useState([ ]) /*the array of data from api*/
    const [totalLength,setToatalLength] =useState()  /*howmany items have in the api data*/
    const [clickedPage,setClickedPage] =useState(1)  /*which page is clicked in pagination*/
    var totalPages =0     /*howmany haw many pages want in pagination*/
    var numberOfItems= 24 /*howmany items want in a page*/
    const {category} = useParams() /*the param will save to category */
    var url;
    const isLoading = useSelector(state => state.essential.essential.isLoading)
    const dispatch = useDispatch()
    // const [allCategory,setAllCategory] = useState([]) /*the all catagories added to the array*/
    // const [haveCategory,setHaveCategory] = useState(true) /*the url check and is not error it will true*/

             // -------------------------------------------------------------------\\


    // this array didnt have the latest category so i push it into the array
    // if the letest is the params the url is category/lates
    // if the latest not the params the url is category= prams 

    // the allCategory includes the params category then its value will return true




    // the total pages is = totalLength of the data divided by the itemsper page
    totalPages=Math.ceil(totalLength/ numberOfItems)
    // fetch the api 
    const getApiData =  async (number) =>{
        // each page click the function will involk
        if(category==="Latest"){
            url=`${instance_url}/blog/Latest?limit=${numberOfItems}&page=${number}`
        }
        else{
            url = `${instance_url}/blog?category=${category}&limit=${numberOfItems}&page=${number}`
        }
        
        const response = await fetch(url)
        const Apidata = await response.json()
        setData(Apidata.blogs)
        console.log("latest data",data);
        setToatalLength(Apidata.count)
        // the loading will stop 
        dispatch(actions.switchOffLoading())
        }

    // which number is clicked (its an lifting i will send the function to pagination as props)
    const getClickedNumber = (number) =>{
        dispatch(actions.switchOnLoading())
        setClickedPage(number)
        getApiData(number)
    }

    //the starting Index Of the Items = (clickedNUmber - 1) x numberOfItems 
    // the ending Index Of the Items = (clickedNumber x numberofItems) - 1 

    // not used code  when the api called for each page
    // var startingIndex = (clickedPage - 1) * numberOfItems
    // var endingIndex = (clickedPage * numberOfItems) - 1

    useEffect(() => {
        dispatch(actions.switchOnLoading())
        getApiData()
    }, [])
    

    
    // if data is fetched then show the category other wise show loading 
    
    if(data.length === 0 && isLoading===false){
            return(
            <NotFound/>
        )
    }
    else{
        return (
            <section className="blog-category">
                <div className="blog-category-container">
                    <div className="blog-text">
                        <Link to="/blog" activeClassName="path-blog">Blog</Link>
                        <span>&gt;{category.toLowerCase()}</span>
                        <h1>{category.toUpperCase()} BLOGS</h1>
                    </div>
                    <div className="blog-category-cards">
                    {
                        
                        data.map((data,index)=>{
                            return(
                            <BlogCard key={index} blogData={data}/>
                            )
                        })
                    }
                    </div>
                    <div className="pagination-wraper">
                        <Pagination totalPages={totalPages} getClickedNumber={getClickedNumber}/>
                    </div>
                </div>
            </section>
        )
    }
}

export default BlogCategory
