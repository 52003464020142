import React from 'react'
import './More.scss'
import MoreCard from './MoreCard/MoreCard'
export default function More() {
    return (
        <div className="more_container" id="personalised-tution">
            <h1>There Is More...</h1>
            <MoreCard
            MoreImage="/images/KCET/More/reading.png"
            Morepara="Comprehensive study material prepared by our highly experienced JEE trainers"
            />
             <MoreCard
            MoreImage="/images/KCET/More/online-test.png"
            Morepara="Adaptive and unlimited customisable mock tests and chapter wise tests"
            />
             <MoreCard
            MoreImage="/images/KCET/More/graduation-cap.png"
            Morepara="Individual guidance to help you through your rigorous JEE preparation"
            />
             <MoreCard
            MoreImage="/images/KCET/More/testing.png"
            Morepara="Advanced Level Practice Sheets to maximise your score in your stronger topics"
            />
        </div>
    )
}
