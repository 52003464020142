import React from "react";
import { Switch, Route } from "react-router-dom";
import Header from "../USER/Navbar/Navbar";
import NotFound from "../USER/NotFound/NotFound";
import BlogCategory from "./Blog Category/BlogCategories";
import BlogHome from "./Blog Home/BlogHome";
import BlogContent from "./BlogContent/BlogContent";
import { Helmet } from "react-helmet";

export default function Blog() {
    return (
        <>
            <Helmet>
                <title>Blog of Revamp24</title>
            </Helmet>
            <Header blogPage={true} />
            <Switch>
                <Route path="/blog" exact>
                    <BlogHome />
                </Route>
                <Route path="/blog/category/:category">
                    <BlogCategory />
                </Route>
                <Route path="/blog/single/:id">
                    <BlogContent />
                </Route>
                <Route path="" component={NotFound} />
            </Switch>
        </>
    );
}
