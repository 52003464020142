import React, { useEffect } from 'react'
import LandingPage from './LandingPage/LandingPage'
import Footer from '../MinFooter/Footer'
import axios from 'axios'
import { GoogleSpreadsheet } from 'google-spreadsheet'
import { useSelector } from 'react-redux'
import Header from '../Header/Header'
import { Helmet } from 'react-helmet'

const Home = () => {

    const SHEET_ID = "1189030760";
    const essentials = useSelector(state => state.essential.essential)

    const {
        REACT_APP_CLIENT_EMAIL,
        REACT_APP_PRIVATE_KEY,
        REACT_APP_LANDING_SPREADSHEET_ID,
        REACT_APP_WEBDATA_SPREADSHEET_ID
    } = process.env

    const doc = new GoogleSpreadsheet(REACT_APP_LANDING_SPREADSHEET_ID);

    const appendSpreadsheet = async (row) => {
        try {
            await doc.useServiceAccountAuth({
                client_email: REACT_APP_CLIENT_EMAIL,
                private_key: REACT_APP_PRIVATE_KEY,
            });
            await doc.loadInfo();

            const sheet = doc.sheetsById[SHEET_ID];
            const result = await sheet.addRow(row);
            return result;
        } catch (e) {
            console.error("Error: ", e);
        }
    };

    useEffect(() => {
        getPreviousData()
        window.scrollTo(0, 0)
        if (essentials.reload) {
            window.location.reload(false)
        }
    }, [])
    const getPreviousData = () => {
        try{
            axios.get(`https://spreadsheets.google.com/feeds/list/${REACT_APP_LANDING_SPREADSHEET_ID}/od6/public/values?alt=json`)
            .then((data) => {
                axios.get(`https://spreadsheets.google.com/feeds/list/${REACT_APP_WEBDATA_SPREADSHEET_ID}/od6/public/values?alt=json`)
                    .then((res) => {
                        if (data.data.feed.entry) {
                            const lengthFirstForm = data.data.feed.entry.length - 1
                            const lengthSecondForm = res.data.feed.entry.length - 1
                            const number = data.data.feed.entry[lengthFirstForm].gsx$parentphone.$t
                            const numberSecondForm = res.data.feed.entry[lengthSecondForm].gsx$parentphone.$t
                            if (number !== numberSecondForm) {
                                const arr = data.data.feed.entry[lengthFirstForm];
                                console.log(arr);
                                if (arr) {
                                    const details = {
                                        parentName: arr.gsx$parentname.$t,
                                        parentPhone: arr.gsx$parentphone.$t,
                                        parentEmail: arr.gsx$parentemail.$t,
                                        childName: arr.gsx$childname.$t,
                                        board: arr.gsx$board.$t,
                                        grade: arr.gsx$grade.$t
                                    }
                                    appendSpreadsheet(details)
                                }
                            }
                        }
                    })
            })
        }catch(err){
            
        }
      
    }

    return (

        <div>
            <Helmet>
                <title>Revamp24 | Book a Free class</title>
            </Helmet>
            <Header/>
            <LandingPage />
            <Footer />
        </div>
    )
}

export default Home;
