import React from "react";
import BestCard from "./BestCard/BestCard";
import FormCard from "./FormCard/FormCard";

import "./Landing.scss";

export default function Landing() {
  return (
    <div className="landing_container" id="my-section">
      <div className="landing_first">
        <h1 className="head">REVAMP24 Classes for KCET</h1>
        <h1 className="subhead">Get the best of</h1>

        <div className="landing_main">
          <BestCard
            cardImage="/images/KCET/Landing/online.png"
            cardTitle="CLASSROOM LEARNING FROM TOP KCET TRAINERS"
          />
          <BestCard
            cardImage="/images/KCET/Landing/mentor.png"
            cardTitle="PERSONALISED INDIVIDUAL ATTENTION"
          />
        </div>
        <div className="landing_para">
          <p>
            Get ready for KCET 2023 or 2024 with the most exhaustive and
            comprehensive online classroom program by the Leading Ed-Tech
            company in banglore.
          </p>
        </div>
      </div>
      <div className="landing_form">
      
       <FormCard/>

      </div>
    </div>
  );
}
