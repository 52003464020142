import React from "react";
import "./Footer.scss";

export default function Footer() {
  return (
    <div className="mini_footer">
      <h3 className="mini_foot_text">See What Parents Say About Us</h3>

      <div className="footer_para">
        <div className="round" style={{backgroundImage:`url(/images/campaign/icons/parent.png)`}}></div>
        <p className="foot_text">
          Get to know from Revamp24 from the Internet. The trial class went
          well, and we continued with the classes for my 11th grader. She is
          really happy because she got a connection with the teacher as well as
          the subject. The teachers have been making sincere eorts to give good
          foundation and to make her practice. thanks to Revamp24. We are happy.
          <br />
          <span>-Vrindha ,Ananya's Parent</span>
        </p>

      </div>

    </div>
  );
}
