import React from 'react'
import './TutorProfileCard.scss'

function TutorProfileCard({bg,teacher,teacherGraduation,teacherProfile,special}) {
    return (
        <div className={"tutor_profile_card "+bg+" "+special}>
            <img src={teacherProfile} alt=""/>
            <div className="tutor_card_content">
                <h6>{teacher}</h6>
                <p>{teacherGraduation}</p>
            </div>
        </div>
    )
}

export default TutorProfileCard
